import React from "react";
import { HeadProps } from "./head-props";
import { HeadSvg } from "./HeadSvg";

export const Head2: React.FunctionComponent<HeadProps> = ({
  hairColor,
  noseColor,
  skinColor,
}) => {
  return (
    <HeadSvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4234_40433"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4234_40433)">
        <path
          d="M164.969 283.572C164.969 283.572 119.186 309.388 113.942 312.01C100.63 318.867 103.857 333.187 112.732 340.246C117.572 344.078 148.834 364.045 148.834 364.045"
          fill={noseColor}
        />
        <path
          d="M159.12 286.799C155.086 303.337 146.212 326.531 141.169 333.994C136.329 341.658 112.53 338.633 112.53 338.633L149.64 364.65L162.75 286.194L159.12 286.799Z"
          fill="#221C16"
        />
        <path
          d="M164.969 283.572C164.969 283.572 119.186 309.388 113.942 312.01C100.63 318.867 103.857 333.187 112.732 340.246C117.572 344.078 148.834 364.045 148.834 364.045"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M239.997 512.487C226.887 511.479 213.777 510.067 200.667 508.05C182.516 505.227 166.179 495.546 154.683 481.024C143.388 466.502 137.942 448.149 139.556 429.997C139.959 424.551 148.027 367.474 148.027 367.474C149.035 361.625 150.044 355.171 151.254 347.91C153.271 334.8 155.691 321.892 158.111 308.782C159.523 301.118 161.137 293.252 162.952 285.588C168.599 259.772 171.019 246.057 183.121 217.821C183.524 217.014 183.927 216.208 184.331 215.199L300.901 246.175L317.401 275.504L359.799 277.675L339.401 316.175L362.901 367.175C362.901 367.175 394.086 363.238 398.927 362.23C403.767 361.02 408.406 358.801 412.44 355.574C414.053 354.162 415.869 352.952 417.684 351.742C424.339 347.708 433.012 345.691 440.273 348.515C451.769 352.952 456.408 366.667 456.811 378.97C457.416 399.139 450.357 418.904 437.248 434.232C429.382 443.308 419.297 450.569 407.398 452.788H393.401L379.901 448.175L353.401 459.175L454.401 516.175L441.886 671.417H227.895L266.216 513.899C257.543 513.496 248.871 513.092 239.997 512.487Z"
          fill={skinColor}
        />
        <path
          d="M250.081 587.314C257.341 566.741 266.216 555.85 276.905 548.993C287.595 542.337 300.301 539.11 308.772 532.858C316.839 526.807 323.697 521.765 324.504 513.698H265.812L247.862 584.49L250.081 587.314Z"
          fill="#221C16"
        />
        <path
          d="M431.197 389.256L389.649 415.072"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M227.895 671.418L266.216 513.899C257.543 513.698 248.669 513.092 239.795 512.487C226.685 511.479 213.575 510.067 200.466 508.05C182.314 505.227 165.977 495.546 154.481 481.024C143.186 466.503 137.741 448.149 139.354 429.997C139.757 424.551 147.825 367.474 147.825 367.474C148.833 361.625 149.842 355.171 151.052 347.91C153.069 334.8 155.489 321.892 157.909 308.782C159.321 301.118 160.935 293.253 162.75 285.588C168.397 259.772 170.817 246.057 182.919 217.821C183.322 217.014 183.725 216.208 184.129 215.199"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M457.215 483.041C452.979 520.555 439.264 671.418 439.264 671.418"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M252.501 513.294C271.661 514.101 321.478 513.697 321.478 513.697"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M147.624 392.685C146.414 401.357 143.993 418.702 143.993 418.702C143.993 418.702 219.021 408.215 225.072 406.803C231.324 405.391 241.005 401.761 243.627 392.08C246.652 380.987 238.383 371.306 226.08 368.885C213.576 366.465 151.254 367.474 151.254 367.474C151.254 367.474 149.641 378.97 147.624 392.685Z"
          fill="white"
        />
        <path
          d="M149.035 368.079C149.035 368.079 211.155 368.482 223.66 370.701C237.375 371.104 246.652 380.987 243.627 392.281C241.207 401.761 231.122 405.391 225.072 407.005C218.819 408.416 141.775 419.913 141.775 419.913"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M247.66 349.523C262.364 349.523 274.283 337.604 274.283 322.9C274.283 308.197 262.364 296.278 247.66 296.278C232.957 296.278 221.037 308.197 221.037 322.9C221.037 337.604 232.957 349.523 247.66 349.523Z"
          fill="#221C16"
        />
        <path
          d="M230.114 303.74C229.71 303.74 229.509 303.74 229.105 303.538C224.87 307.774 221.844 313.219 221.038 319.875C220.836 321.489 220.836 322.901 221.038 324.312C222.853 325.926 225.071 326.934 227.693 327.338C234.147 327.943 239.996 323.304 240.803 316.85C241.408 310.396 236.568 304.547 230.114 303.74Z"
          fill="white"
        />
        <path
          d="M195.02 311.404C213.777 300.11 236.366 294.664 259.762 297.085C278.922 299.101 296.469 306.16 310.991 316.85"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M453.786 517.126L381.985 473.965L351.934 459.04L374.119 449.359C374.119 449.359 380.775 446.536 383.599 448.149C390.859 452.385 399.532 454.2 407.801 452.788C419.499 450.569 429.785 443.309 437.651 434.233C450.761 418.904 457.82 399.139 457.215 378.97C456.811 366.667 452.173 352.952 440.676 348.515C433.214 345.692 423.936 347.708 418.087 351.742C412.238 355.776 399.33 362.028 399.33 362.028L363.833 367.272L339.026 315.64L362.018 277.118C362.018 277.118 317.445 276.916 315.831 276.513L300.907 246.259L184.734 215.401V139.163L327.126 48L340.639 95.195L452.576 51.8321L439.063 122.02L540.915 97.0102L515.906 197.451L588.917 184.139L555.235 290.832L615.137 292.244L544.949 383.407L583.27 408.013L520.141 438.065L555.033 478.402L453.786 517.126Z"
          fill={hairColor}
        />
        <path
          d="M555.033 478.604L553.218 476.385L431.197 503.613L453.786 517.126L555.033 478.604Z"
          fill="#221C16"
        />
        <path
          d="M583.068 408.215L582.463 406.601L503.401 424.753L517.923 440.082L583.068 408.215Z"
          fill="#221C16"
        />
        <path
          d="M588.715 184.341L585.892 184.744L519.94 292.446L561.689 294.261L588.715 184.341Z"
          fill="#221C16"
        />
        <path
          d="M615.137 292.446H612.111L520.747 370.096L542.932 386.029L615.137 292.446Z"
          fill="#221C16"
        />
        <path
          d="M540.714 97.2117L537.89 97.8167L488.88 203.905L516.915 200.678L540.714 97.2117Z"
          fill="#221C16"
        />
        <path
          d="M452.576 51.8318L449.954 52.8402L422.928 128.272L441.685 124.641L452.576 51.8318Z"
          fill="#221C16"
        />
        <path
          d="M327.126 48L325.311 49.0084L324.302 103.868L343.664 97.4136L327.126 48Z"
          fill="#221C16"
        />
        <path
          d="M453.786 517.126L381.985 473.965L351.934 459.04L374.119 449.359C374.119 449.359 380.775 446.536 383.599 448.149C390.859 452.385 399.532 454.2 407.801 452.788C419.499 450.569 429.785 443.309 437.651 434.233C450.761 418.904 457.82 399.139 457.215 378.97C456.811 366.667 452.173 352.952 440.676 348.515C433.214 345.692 423.936 347.708 418.087 351.742C412.238 355.776 399.33 362.028 399.33 362.028L363.833 367.272L339.026 315.64L362.018 277.118C362.018 277.118 317.445 276.916 315.831 276.513L300.907 246.259L184.734 215.401V139.163L327.126 48L340.639 95.195L452.576 51.8321L439.063 122.02L540.915 97.0102L515.906 197.451L588.917 184.139L555.235 290.832L615.136 292.244L544.949 383.407L583.27 408.013L520.141 438.065L555.033 478.402L453.786 517.126Z"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </HeadSvg>
  );
};
