import React, { PropsWithChildren } from "react";
import { dummyRouter } from "./dummy-router";
import { DummyRouterLink } from "./DummyRouterLink";
import { RouterLinkComponent } from "./RouterLink";
import { Router } from "./router";

export const RouterContext = React.createContext<{
  component: RouterLinkComponent;
  router: {
    push: (url: string) => void;
  };
}>({ component: DummyRouterLink, router: dummyRouter });

export const RouterProvider: React.FunctionComponent<
  PropsWithChildren<{
    component: RouterLinkComponent;
    router: Router;
  }>
> = ({ component, router, children }) => {
  return (
    <RouterContext.Provider value={{ component, router }}>
      {children}
    </RouterContext.Provider>
  );
};
