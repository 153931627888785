import { useEffect } from "react";

export interface SwipeGestures {
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
  onSwipeDown?: () => void;
  onSwipeUp?: () => void;
}

/**
 * Defines how "small" or "big" the swipe gesture needs to be to trigger the callback.
 */
const swipeThreshold = 100;

export function useSwipeGestures(
  { onSwipeLeft, onSwipeRight, onSwipeDown, onSwipeUp }: SwipeGestures,
  disable?: boolean
) {
  useEffect(() => {
    if (disable) {
      return;
    }

    let xFirstTouch: number | null = null;
    let yFirstTouch: number | null = null;

    let gestureComplete = false;

    function handleTouchStart(evt: TouchEvent | Event) {
      if (!("touches" in evt)) {
        return;
      }

      evt.stopPropagation();

      const firstTouch = evt.touches[0];
      xFirstTouch = firstTouch.clientX;
      yFirstTouch = firstTouch.clientY;
    }

    function handleTouchEnd() {
      gestureComplete = false;
    }

    function handleTouchMove(evt: TouchEvent | Event) {
      if (
        !xFirstTouch ||
        !yFirstTouch ||
        gestureComplete ||
        !("touches" in evt)
      ) {
        return;
      }

      const xDiff = xFirstTouch - evt.touches[0].clientX;
      const yDiff = yFirstTouch - evt.touches[0].clientY;

      const maxSwipeDistance = Math.max(Math.abs(xDiff), Math.abs(yDiff));
      if (maxSwipeDistance < swipeThreshold) {
        return;
      }

      gestureComplete = true;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          onSwipeRight?.();
        } else {
          onSwipeLeft?.();
        }
      } else {
        if (yDiff < 0) {
          onSwipeDown?.();
        } else {
          onSwipeUp?.();
        }
      }

      // reset values
      xFirstTouch = null;
      yFirstTouch = null;
    }

    document.addEventListener("touchstart", handleTouchStart, false);
    document.addEventListener("touchmove", handleTouchMove, false);
    document.addEventListener("touchend", handleTouchEnd, false);

    return () => {
      document.removeEventListener("touchstart", handleTouchStart);
      document.removeEventListener("touchmove", handleTouchMove);
      document.addEventListener("touchend", handleTouchEnd);
    };
  }, [onSwipeLeft, onSwipeRight, onSwipeDown, onSwipeUp, disable]);
}
