import { themeGet } from "@styled-system/theme-get";
import React, { useEffect, useRef, useState } from "react";
import styled, { StyledConfig } from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  FlexProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
  zIndex,
  ZIndexProps,
} from "styled-system";
import { useCombinedRefs } from "../../../hooks/use-combined-refs";
import {
  pointerEvents,
  PointerEventsProps,
} from "../../../theme/styled-system-custom";
import { hexToRgba } from "../../../utils/color-utils";
import { Spinner } from "../spinner/Spinner";

import shouldForwardProp from "@styled-system/should-forward-prop";

export enum ButtonVariant {
  DEFAULT = "default",
  DEFAULT_INVERSE = "default-inverse",
  OUTLINED = "outlined",
  OUTLINED_INVERSE = "outlined-inverse",
  TEXT = "text",
  TEXT_INVERSE = "text-inverse",
}

export enum ButtonSize {
  SMALL = "small",
  DEFAULT = "default",
  LARGE = "large",
}

export interface VariantProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
}

export interface CustomButtonProps {
  isLoading?: boolean;
  $clickOverlay?: boolean;
  className?: string;
}

type Props = VariantProps &
  ColorProps &
  FlexProps &
  GridProps &
  FontSizeProps &
  FontWeightProps &
  SpaceProps &
  BorderProps &
  LayoutProps &
  PointerEventsProps &
  ZIndexProps &
  CustomButtonProps;

export const isInverseVariant = (variant?: ButtonVariant) => {
  return [
    ButtonVariant.DEFAULT_INVERSE,
    ButtonVariant.OUTLINED_INVERSE,
    ButtonVariant.TEXT_INVERSE,
  ].includes(variant ?? ButtonVariant.DEFAULT);
};

const StyledButton = styled.button.withConfig({
  // @ts-ignore
  shouldForwardProp,
})<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${themeGet("fontSizes.1")};
  font-family: ${themeGet("fonts.body")};
  font-weight: 600;
  text-align: center;

  border-radius: 30px;
  opacity: 1;
  box-shadow: none;
  white-space: nowrap;
  cursor: pointer;

  :hover {
    text-decoration: none;
  }

  :focus-visible {
    /* Unfortunately outline is not supported by styled-system 
    variant, therefore this verbose workaround */
    outline: 2px solid
      ${(props) =>
        isInverseVariant(props.variant)
          ? themeGet("colors.white")(props)
          : themeGet("colors.black")(props)};

    outline-offset: 2px;
  }

  :disabled,
  &[disabled] {
    cursor: default;
    box-shadow: none;
    pointer-events: none;
  }

  transition: 0.15s all ease-out;
  transition-property: background-color, color, border-color, box-shadow;

  padding: ${10 / 16}rem 1.5rem;
  font-size: ${themeGet("fontSizes.1")};

  ::before {
    ${({ $clickOverlay }) => $clickOverlay && `content: "";`}
    cursor: inherit;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  ${variant({
    prop: "size",
    variants: {
      [ButtonSize.DEFAULT]: {},
      [ButtonSize.LARGE]: {
        padding: `1rem 2rem`,
        fontSize: 2,
      },
      [ButtonSize.SMALL]: {
        padding: `0.5rem 1rem`,
        fontSize: 0,
      },
    },
  })}
  ${(props) =>
    variant({
      variants: {
        [ButtonVariant.DEFAULT]: {
          color: "white",
          bg: "black",
          border: "none",
          "&[disabled]": {
            bg: hexToRgba(themeGet("colors.black")(props), 0.12),
            color: hexToRgba(themeGet("colors.black")(props), 0.4),
          },
          ":hover:not(&[disabled]):not(:active)": {
            color: "white",
            boxShadow:
              "0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15)",
          },
        },
        [ButtonVariant.DEFAULT_INVERSE]: {
          color: "black",
          bg: "white",
          border: "white",
          "&[disabled]": {
            bg: hexToRgba(themeGet("colors.white")(props), 0.12),
            color: hexToRgba(themeGet("colors.white")(props), 0.4),
          },
          ":hover:not(&[disabled]):not(:active)": {
            background: `linear-gradient(0deg, ${hexToRgba(
              themeGet("colors.black")(props),
              0.08
            )}, ${hexToRgba(
              themeGet("colors.black")(props),
              0.08
            )}), ${hexToRgba(themeGet("colors.white")(props))}`,
            color: "black",
          },
          ":focus-visible": {
            background: `linear-gradient(0deg, ${hexToRgba(
              themeGet("colors.black")(props),
              0.12
            )}, ${hexToRgba(
              themeGet("colors.black")(props),
              0.12
            )}), ${hexToRgba(themeGet("colors.white")(props))}`,
          },
        },
        [ButtonVariant.OUTLINED]: {
          color: "black",
          borderWidth: "1px",
          borderColor: "black",
          background: "none",
          borderStyle: "solid",
          ":hover:not(&[disabled]):not(:active)": {
            backgroundColor: hexToRgba(themeGet("colors.black")(props), 0.08),
            boxShadow: "0px",
            color: "black",
          },
          ":active": {
            backgroundColor: hexToRgba(themeGet("colors.black")(props), 0.12),
          },
          "&[disabled]": {
            borderColor: hexToRgba(themeGet("colors.black")(props), 0.12),
            color: hexToRgba(themeGet("colors.black")(props), 0.4),
          },
        },
        [ButtonVariant.OUTLINED_INVERSE]: {
          color: "white",
          bg: "transparent",
          border: "1px solid white",
          borderStyle: "solid",
          "&[disabled]": {
            bg: hexToRgba(themeGet("colors.white")(props), 0.12),
            color: hexToRgba(themeGet("colors.white")(props), 0.4),
          },
          ":hover:not(&[disabled]):not(:active)": {
            bg: hexToRgba(themeGet("colors.white")(props), 0.08),
            color: "white",
          },
          ":focus-visible": {
            bg: hexToRgba(themeGet("colors.white")(props), 0.12),
          },
        },
        [ButtonVariant.TEXT]: {
          color: "black",
          border: "none",
          background: "none",
          ":hover:not(&[disabled]):not(:active)": {
            backgroundColor: hexToRgba(themeGet("colors.black")(props), 0.08),
            boxShadow: "0px",
            color: "black",
          },
          ":focus-visible": {
            backgroundColor: hexToRgba(themeGet("colors.black")(props), 0.12),
          },
          ":active": {
            backgroundColor: hexToRgba(themeGet("colors.black")(props), 0.12),
          },
          "&[disabled]": {
            color: hexToRgba(themeGet("colors.black")(props), 0.4),
          },
        },
        [ButtonVariant.TEXT_INVERSE]: {
          color: "white",
          border: "none",
          background: "none",
          ":hover:not(&[disabled]):not(:active)": {
            backgroundColor: hexToRgba(themeGet("colors.white")(props), 0.08),
            boxShadow: "0px",
            color: "white",
          },
          ":focus-visible": {
            backgroundColor: hexToRgba(themeGet("colors.white")(props), 0.12),
          },
          ":active": {
            backgroundColor: hexToRgba(themeGet("colors.white")(props), 0.12),
          },
          "&[disabled]": {
            color: hexToRgba(themeGet("colors.white")(props), 0.4),
            background: "none",
          },
        },
      },
    })};

  ${color}
  ${space}
  ${flex}
  ${grid}
  ${fontSize}
  ${fontWeight}
  ${border}
  ${layout}
  ${pointerEvents}
  ${zIndex}
`;

export type ButtonProps = React.ComponentProps<typeof StyledButton>;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ isLoading, children, className, clickOverlay, ...props }, ref) => {
    const loadingRef = useRef(isLoading);
    const innerRef = useRef<HTMLButtonElement | null>();
    const [buttonSize, setButtonSize] =
      useState<undefined | { height: string; width: string }>();
    const combinedRef = useCombinedRefs(ref, innerRef);
    const [innerIsLoading, setInnerIsLoading] = useState(isLoading);

    useEffect(() => {
      if (!loadingRef.current && isLoading) {
        const rect = combinedRef.current?.getBoundingClientRect();

        if (rect) {
          setButtonSize({
            width: `${rect.width}px`,
            height: `${rect.height}px`,
          });
        }
        setInnerIsLoading(true);
      }

      if (!isLoading) {
        setInnerIsLoading(false);
        setButtonSize(undefined);
      }

      loadingRef.current = isLoading;
    }, [combinedRef, isLoading]);

    return (
      <StyledButton
        ref={combinedRef}
        {...props}
        width={buttonSize?.width}
        height={buttonSize?.height}
        pointerEvents={innerIsLoading ? "none" : null}
        className={className}
        $clickOverlay={clickOverlay}
      >
        {innerIsLoading ? <Spinner /> : children}
      </StyledButton>
    );
  }
);
