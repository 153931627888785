import React from "react";
import { Box } from "../box/Box";
import { Transaction, TransactionProps } from "./Transaction";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

export interface TransactionListProps {
  transactions: TransactionProps[];
  className?: string;
}

const StyledTransactionList = styled(Box)`
  > ${Box} {
    border-radius: 0;
  }
  > ${Box} + ${Box} {
    border-top: 1px solid ${themeGet("colors.blacks.black100")};
  }

  > ${Box}:first-child {
    padding-top: 1rem;
  }

  > ${Box}:last-child {
    padding-bottom: 1rem;
  }
`;

export const TransactionList: React.FunctionComponent<TransactionListProps> = ({
  transactions,
  className,
}) => {
  return (
    <StyledTransactionList
      overflow="hidden"
      borderRadius="0.5rem"
      boxShadow="card"
      className={className}
    >
      {transactions.map((transaction, i) => (
        <Transaction key={i} {...transaction} />
      ))}
    </StyledTransactionList>
  );
};
