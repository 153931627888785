import React from "react";
import { BaseHeaderProps } from "./header-commons";
import { HeaderDesktop } from "./header-desktop/HeaderDesktop";
import { HeaderMobile } from "./header-mobile/HeaderMobile";

export const BaseHeader: React.FunctionComponent<BaseHeaderProps> = (props) => {
  return (
    <>
      <HeaderDesktop {...props} />
      <HeaderMobile {...props} />
    </>
  );
};
