import { linearGradientDef, Theme as NivoTheme } from "@nivo/core";
import { ResponsiveLine } from "@nivo/line";
import { format } from "date-fns";
import React from "react";
import { useThemeGet } from "../../../hooks/use-theme-get";
import { toSignificant, toSignificantShort } from "../../../utils/number-utils";
import { Box } from "../../base/box/Box";

export enum PriceInterval {
  DAY = "DAY",
  MINUTE = "MINUTE",
}

export interface PriceChartProps {
  data: {
    date: Date;
    price: number;
  }[];
  height: string;
  name: string;
  color: string;
  currency: string;
  interval: PriceInterval;
}

const timeFormat: Record<PriceInterval, string> = {
  [PriceInterval.DAY]: "time:%Y-%m-%d",
  [PriceInterval.MINUTE]: "time:%H:%M",
};

const xScalePrecision: Record<
  PriceInterval,
  "millisecond" | "second" | "minute" | "hour" | "day" | "month" | "year"
> = {
  [PriceInterval.DAY]: "day",
  [PriceInterval.MINUTE]: "hour",
};

const dateFormat: Record<PriceInterval, string> = {
  [PriceInterval.DAY]: "P",
  [PriceInterval.MINUTE]: "P p",
};

export const PriceChart: React.FunctionComponent<PriceChartProps> = ({
  data,
  height,
  name,
  color,
  currency,
  interval = PriceInterval.DAY,
}) => {
  const black600 = useThemeGet("colors.blacks.black600");
  const black100 = useThemeGet("colors.blacks.black100");
  const fontFamily = useThemeGet("fonts.body");

  const series = [
    {
      id: name,
      color,
      data: data.map(({ date, price }) => ({ x: new Date(date), y: price })),
    },
  ];

  const max = Math.max(...data.map(({ price }) => price));

  const numYTicks = 3;

  const theme: NivoTheme = {
    fontFamily,
    axis: {
      ticks: {
        text: {
          fill: black600,
          fontSize: "14px",
        },
      },
    },
    grid: {
      line: {
        stroke: black100,
        strokeWidth: 1,
      },
    },
  };

  const formatPrice = (v: number) => `${currency} ${toSignificantShort(v)}`;

  return (
    <Box height={height}>
      <ResponsiveLine
        theme={theme}
        data={series}
        margin={{ top: 20 }}
        xScale={{
          type: "time",
          format: "native",
          precision: xScalePrecision[interval],
        }}
        xFormat={timeFormat[interval]}
        axisBottom={{
          format: "",
          tickSize: 0,
        }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: true,
          reverse: false,
        }}
        axisLeft={{
          format: formatPrice,
          tickValues: numYTicks,
          ticksPosition: "before",
          tickPadding: -80,
          tickSize: 0,
          tickRotation: 0,
          legendOffset: 80,
          legendPosition: "middle",
        }}
        yFormat=" >-.2f"
        pointSize={0}
        enableArea={true}
        defs={[
          linearGradientDef("gradientA", [
            { offset: 0, color: "inherit", opacity: 1 },
            {
              offset: 6,
              color: "inherit",
              opacity: 0,
            },
          ]),
        ]}
        fill={[{ match: "*", id: "gradientA" }]}
        colors={(d) => d.color}
        isInteractive={true}
        useMesh={true}
        lineWidth={1.5}
        enableSlices={false}
        gridYValues={numYTicks}
        enableGridX={false}
        tooltip={({ point: { data } }) => {
          return (
            <Box
              p={2}
              bg="white"
              opacity="0.9"
              borderRadius="0.5rem"
              fontSize={1}
            >
              {format(new Date(data.x), dateFormat[interval])}:{" "}
              <strong>
                {currency} {toSignificant(Number(data.y), 2)}
              </strong>
            </Box>
          );
        }}
      />
    </Box>
  );
};
