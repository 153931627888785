import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";

export interface LogoProps extends SpaceProps, LayoutProps {
  className?: string;
  color?: ThemeColor | Color;
}

const StyledSvg = styled.svg<SpaceProps & LayoutProps>`
  ${space}
  ${layout}
`;

const StyledPath = styled.path<{ $fill: string }>`
  transition: fill 0.2s ease-in-out;
  fill: ${({ $fill }) => $fill};
`;

export const Logo: React.FunctionComponent<LogoProps> = ({
  className,
  color,
  ...props
}) => {
  const fill = useThemeColor(color ?? "white");

  return (
    <StyledSvg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 245 64"
      {...(props as any)}
    >
      <title>pier logo</title>
      <StyledPath
        $fill={fill}
        d="M93.6319 62.52V51L92.0959 42.2319H93.0559C95.8079 49.2079 102.016 52.408 111.424 52.408C120.256 52.408 126.72 49.6559 129.984 44.9199C131.712 42.4239 132.608 39.2239 132.608 35.6399C132.608 31.9919 131.712 28.8559 129.984 26.3599C126.72 21.6239 120.256 18.8079 111.424 18.8079C102.016 18.8079 95.8079 22.008 93.0559 28.984H92.0959L93.6319 20.2159H86.4639V62.52H93.6319ZM109.312 46.7119C102.272 46.7119 97.4719 44.9199 95.2319 41.6559C94.0799 39.9919 93.5039 38.0079 93.5039 35.6399C93.5039 33.2079 94.0799 31.2239 95.2319 29.5599C97.4719 26.3599 102.272 24.5039 109.312 24.5039C116.416 24.5039 121.216 26.3599 123.456 29.5599C124.608 31.2239 125.184 33.2079 125.184 35.6399C125.184 38.0079 124.608 39.9919 123.456 41.6559C121.216 44.9199 116.416 46.7119 109.312 46.7119Z"
      ></StyledPath>
      <StyledPath
        $fill={fill}
        d="M147.773 14.6479V7.09595H139.453V14.6479H147.773ZM147.197 51V20.2159H140.029V51H147.197Z"
      ></StyledPath>
      <StyledPath
        $fill={fill}
        d="M190.362 41.72C188.25 44.984 184.026 46.84 176.73 46.84C170.33 46.904 165.466 45.1759 163.29 41.9759C162.394 40.6959 161.882 39.096 161.69 37.368H197.594V35.0639C197.594 25.0799 190.234 18.8079 176.154 18.8079C167.578 18.8079 160.538 21.5599 157.146 26.4879C155.418 28.9839 154.394 32.1199 154.394 35.7039C154.394 39.2239 155.29 42.296 156.954 44.728C160.346 49.656 167.258 52.408 176.858 52.408C187.994 52.408 195.674 48.056 197.658 41.72H190.362ZM176.154 24.3119C185.178 24.3119 189.594 27.2559 190.234 32.3119H161.946C162.202 31.1599 162.65 30.136 163.226 29.304C165.466 26.104 170.394 24.3759 176.154 24.3119Z"
      ></StyledPath>
      <StyledPath
        $fill={fill}
        d="M212.592 51V36.472C212.592 29.24 216.624 24.8239 223.984 24.8239C231.28 24.8239 233.904 27.704 233.904 33.208V35.832H241.264V31.9919C241.264 23.6079 236.656 18.8079 226.992 18.8079C219.056 18.8079 214.32 22.2639 211.824 30.5199H210.8L212.592 20.2159H205.424V51H212.592Z"
      ></StyledPath>
      <StyledPath
        $fill={fill}
        d="M0 0H64V28.7987L48.6828 36.7987L64 53.8125V64H49.7863L16.2793 8.61966H9.92827L19.924 64H0V0Z"
      ></StyledPath>
    </StyledSvg>
  );
};
