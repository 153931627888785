import React, {
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  PropsWithChildren,
} from "react";
import { noop } from "../../../utils/noop";
import { AppHeaderProps } from "./AppHeader";

interface AppHeaderContextModel {
  props: AppHeaderProps;
  setProps: Dispatch<SetStateAction<AppHeaderProps>>;
  resetProps(): void;
}

const AppHeaderContext = React.createContext<AppHeaderContextModel>({
  props: {},
  setProps: noop,
  resetProps: noop,
});

export interface AppHeaderContextProviderProps {}

export const AppHeaderContextProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const [props, setProps] = useState<AppHeaderProps>({});
  const resetProps = useCallback(() => setProps({}), [setProps]);

  return (
    <AppHeaderContext.Provider value={{ props, setProps, resetProps }}>
      {children}
    </AppHeaderContext.Provider>
  );
};

export function useAppHeader() {
  return React.useContext(AppHeaderContext);
}
