import { motion } from "framer-motion";
import React, { CSSProperties } from "react";
import styled from "styled-components";

export interface FloatingPolygonProps {
  width?: string | number;
  height?: string | number;
  objectFit?: CSSProperties["objectFit"];
}

const StyledSvg = styled.svg<{ $objectFit?: CSSProperties["objectFit"] }>`
  object-fit: ${({ $objectFit }) => $objectFit ?? "contain"};
`;

const container = {
  small: { opacity: 0 },
  large: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  small: { scale: 0 },
  large: { scale: 1 },
};

const transitionCommon = {
  repeat: Infinity,
  repeatType: "mirror" as const,
  type: "easyInOut",
  duration: 1,
};

export const FloatingPolygon: React.FunctionComponent<FloatingPolygonProps> = ({
  width,
  height,
  objectFit,
}) => {
  return (
    <StyledSvg
      $objectFit={objectFit}
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1, 1.3, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        opacity="0.4"
        cx="100.417"
        cy="174.598"
        rx="18.75"
        ry="3.33333"
        fill="#221C16"
      />
      <motion.g variants={container} initial="small" animate="large">
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.6"
          d="M43.7193 19.6787C38.7249 19.6787 38.7175 27.1787 43.7193 27.1787C48.7212 27.1787 48.7212 19.6787 43.7193 19.6787Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.4"
          d="M181.383 138.729C176.39 138.729 176.383 146.229 181.383 146.229C186.383 146.229 186.39 138.729 181.383 138.729Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M20.7065 109.38C12.3792 109.38 12.3711 121.88 20.7065 121.88C29.0418 121.88 29.0418 109.38 20.7065 109.38Z"
          fill="white"
        />
      </motion.g>
      <motion.g
        animate={{
          translateY: [-5, 0, -5],
          rotate: [3, 0, 3],
          originX: 1,
          originY: 0.5,
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <circle
          cx={100}
          cy="95.8336"
          r={60}
          transform="rotate(6 100 95.8336)"
          fill="#8247E5"
        />
        <path
          d="M120.554 84.8022C119.275 83.8621 117.522 83.6779 115.88 84.3109L104.283 89.7383L96.4433 93.1436L85.0411 98.5914C83.5941 99.2449 81.8414 99.0607 80.3671 98.1002L72.5373 92.0408C71.2578 91.1007 70.4515 89.4048 70.6399 87.612L71.6868 77.652C71.8543 76.0584 72.8008 74.5467 74.4634 73.7144L83.3611 69.6146C84.8081 68.9611 86.5608 69.1453 88.0351 70.1059L95.865 76.1652C97.1445 77.1053 97.9507 78.8013 97.7623 80.5941L97.0714 87.1676L104.932 83.5631L105.644 76.7903C105.812 75.1967 105.2 73.5212 103.747 72.3615L89.1511 61.3616C87.8716 60.4215 86.1188 60.2373 84.4771 60.8703L67.5026 68.7534C65.8609 69.3865 64.9144 70.8982 64.7469 72.4918L62.7788 91.2166C62.6113 92.8102 63.2229 94.4856 64.6762 95.6454L79.4669 106.666C80.7464 107.606 82.4991 107.79 84.1409 107.157L95.5221 101.908L103.383 98.3038L114.764 93.0553C116.211 92.4017 117.964 92.586 119.438 93.5465L127.289 99.4067C128.569 100.347 129.375 102.043 129.187 103.835L128.14 113.795C127.972 115.389 127.026 116.901 125.363 117.733L116.66 121.853C115.213 122.507 113.46 122.323 111.986 121.362L104.135 115.502C102.856 114.562 102.05 112.866 102.238 111.073L102.908 104.699L95.0469 108.303L94.3559 114.877C94.1884 116.47 94.8 118.146 96.2533 119.306L111.044 130.326C112.323 131.266 114.076 131.45 115.718 130.817L132.477 123.113C133.924 122.459 135.065 120.968 135.253 119.175L137.242 100.251C137.41 98.6578 136.798 96.9823 135.345 95.8226L120.554 84.8022Z"
          fill="white"
        />
      </motion.g>
    </StyledSvg>
  );
};
