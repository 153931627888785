import React, { useState } from "react";
import { formatNumber, roundNumber } from "../../../utils/number-utils";
import { RadioButtonsProps } from "../../base/radio-buttons/RadioButtons";
import { BoxProps } from "../../base/box/Box";
import { CurrencyCalculator } from "../currency-calculator/CurrencyCalculator";

export interface SwapInputToken {
  text: string;
  imageUrl: string;
  color: string;
  symbol: string;
  decimals: number;
  baseCurrencyExchangeRate: number;
  onClick?: () => void;
}

export enum SwapType {
  BUY = "buy",
  SELL = "sell",
}

export interface SwapInputProps extends Omit<BoxProps, "color"> {
  swapType: SwapType;
  sourceToken: SwapInputToken;
  targetToken: SwapInputToken;
  baseCurrencySymbol: string;
  totalSourceTokenAvailable: string;
  baseCurrencyDecimals?: number;

  onAmount(amount: { amount: string; symbol: string }): void;
  onTradePossible?(isTradePossible: boolean): void;
}

function calculateExchangeValue({
  amount,
  exchangeRate,
  toCurrencyDecimals,
  baseCurrency,
  selectedCurrency,
  baseCurrencyDecimals,
}: {
  amount: string;
  exchangeRate: number;
  baseCurrency: string;
  baseCurrencyDecimals: number;
  toCurrencyDecimals: number;
  selectedCurrency: string;
}): string {
  const amountAsNumber = Number(amount);

  if (isNaN(amountAsNumber)) {
    return "n/a";
  }

  if (amountAsNumber === 0) {
    return "0";
  }

  if (selectedCurrency === baseCurrency) {
    return formatNumber(amountAsNumber / exchangeRate, toCurrencyDecimals);
  }

  return `~ ${formatNumber(
    amountAsNumber * exchangeRate,
    baseCurrencyDecimals
  )}`;
}

function isTradePossible({
  symbol,
  amount,
  sourceToken,
  targetToken,
  totalSourceTokenAvailable,
}: {
  symbol: string;
  amount: string;
  sourceToken: SwapInputToken;
  targetToken: SwapInputToken;
  totalSourceTokenAvailable: string;
}) {
  if (Number(amount) <= 0) {
    return false;
  }

  if (symbol === sourceToken.symbol) {
    return Number(amount) <= Number(totalSourceTokenAvailable);
  }

  return (
    (Number(amount) * targetToken.baseCurrencyExchangeRate) /
      sourceToken.baseCurrencyExchangeRate <=
    Number(totalSourceTokenAvailable)
  );
}

export const SwapInput: React.FunctionComponent<SwapInputProps> = ({
  swapType,
  onAmount,
  onTradePossible,
  sourceToken,
  targetToken,
  baseCurrencySymbol,
  totalSourceTokenAvailable,
  baseCurrencyDecimals = 2,
  ...boxProps
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(baseCurrencySymbol);
  const [amount, setInternalAmount] = useState("");

  const isBuy = swapType === SwapType.BUY;
  const isBaseCurrencySelected = selectedCurrency === baseCurrencySymbol;

  const currencyRadioButtons: RadioButtonsProps<string>["options"] = [
    baseCurrencySymbol,
    isBuy ? targetToken.symbol : sourceToken.symbol,
  ].map((symbol) => ({
    value: symbol,
    text: symbol,
    testId: `radio-button-${symbol.toLowerCase()}`,
  }));

  const otherToken = isBuy ? targetToken : sourceToken;
  const exchangeRate = otherToken.baseCurrencyExchangeRate;

  const exchangeValue = calculateExchangeValue({
    amount,
    selectedCurrency,
    exchangeRate,
    baseCurrency: baseCurrencySymbol,
    toCurrencyDecimals: otherToken.decimals,
    baseCurrencyDecimals,
  });

  const setAmount = (val: string, emit = true) => {
    setInternalAmount(val);

    let amount: string;
    let symbol: string;

    if (isBaseCurrencySelected) {
      amount = roundNumber(
        Number(val) / otherToken.baseCurrencyExchangeRate,
        otherToken.decimals,
        false,
        true
      );
      symbol = otherToken.symbol;
    } else {
      amount = val;
      symbol = otherToken.symbol;
    }

    onTradePossible?.(
      isTradePossible({
        symbol,
        amount,
        sourceToken,
        targetToken,
        totalSourceTokenAvailable,
      })
    );

    if (emit) {
      onAmount({
        amount,
        symbol,
      });
    }
  };

  const onSetPercentage = (multiplicator: number) => () => {
    let amount: number;
    let decimals: number;

    const amountInSourceToken =
      Number(totalSourceTokenAvailable) * multiplicator;

    if (isBaseCurrencySelected) {
      amount = amountInSourceToken * sourceToken.baseCurrencyExchangeRate;
      decimals = baseCurrencyDecimals;
    } else {
      const exchangeRate = isBuy
        ? sourceToken.baseCurrencyExchangeRate /
          targetToken.baseCurrencyExchangeRate
        : 1;

      amount = amountInSourceToken * exchangeRate;
      decimals = sourceToken.decimals;
    }

    setAmount(roundNumber(amount, decimals, false, true), false);

    onAmount({
      symbol: sourceToken.symbol,
      amount:
        multiplicator === 1
          ? totalSourceTokenAvailable
          : String(amountInSourceToken),
    });
  };

  const onSetCurrency = (currency: string) => {
    if (currency === selectedCurrency) {
      return;
    }

    setSelectedCurrency(currency);

    const amountAsNumber = Number(amount);

    if (isNaN(amountAsNumber) || amount === "") {
      return;
    }

    let newAmount: number;
    let decimals: number;
    if (isBaseCurrencySelected) {
      newAmount = amountAsNumber / exchangeRate;
      decimals = otherToken.decimals;
    } else {
      newAmount = amountAsNumber * exchangeRate;
      decimals = baseCurrencyDecimals;
    }

    setAmount(roundNumber(newAmount, decimals), false);
  };

  const calculatedValue = `${exchangeValue} ${
    isBaseCurrencySelected ? otherToken.symbol : baseCurrencySymbol
  }`;

  const amountAvailable =
    isBaseCurrencySelected || isBuy
      ? `${formatNumber(
          Number(totalSourceTokenAvailable) *
            sourceToken.baseCurrencyExchangeRate,
          baseCurrencyDecimals
        )} ${baseCurrencySymbol}`
      : `${formatNumber(
          Number(totalSourceTokenAvailable),
          baseCurrencyDecimals
        )} ${sourceToken.symbol}`;

  return (
    <CurrencyCalculator
      onSetPercentage={onSetPercentage}
      onAmount={setAmount}
      amount={amount}
      selectedCurrency={selectedCurrency}
      onSetCurrency={onSetCurrency}
      calculatedValue={calculatedValue}
      currencyRadioButtons={currencyRadioButtons}
      amountAvailable={amountAvailable}
      from={sourceToken}
      to={targetToken}
      {...boxProps}
    />
  );
};
