import React, { useState, useEffect, PropsWithChildren } from "react";
import styled from "styled-components";
import { range } from "../../../utils/range";
import { Box } from "../../base/box/Box";
import { themeGet } from "@styled-system/theme-get";
import { useHaptics } from "../../../hooks/use-native";
import { hexToRgba } from "../../../utils/color-utils";

export enum SpecialKey {
  BACKSPACE = "BACKSPACE",
  DECIMAL_POINT = "DECIMAL_POINT",
}

interface BaseKeyblockProps {
  withHapticFeedback?: boolean;
  onInput: (value: number | SpecialKey) => void;
}

interface KeyblockDecimalPointProps extends BaseKeyblockProps {
  withDecimalPoint?: boolean;
}

interface KeyblockCustomActionProps extends BaseKeyblockProps {
  customAction?: {
    text: string;
    onClick: () => void;
  };
}

export type KeyblockProps =
  | KeyblockDecimalPointProps
  | KeyblockCustomActionProps;

const StyledButton = styled(Box)`
  cursor: pointer;

  &:hover {
    background-color: ${(props) =>
      hexToRgba(themeGet("colors.blacks.black200")(props), 0.08)};
    color: ${themeGet("colors.black")};
  }
`;

const KeyButton: React.FunctionComponent<
  PropsWithChildren<{
    onClick: () => void;
    "data-testid"?: string;
    fontSize?: number;
  }>
> = ({ children, onClick, "data-testid": testId, fontSize }) => (
  <StyledButton
    as="button"
    textAlign="center"
    fontSize={fontSize ?? 4}
    lineHeight="1.5"
    color="blacks.black800"
    border="none"
    backgroundColor="transparent"
    onClick={onClick}
    borderRadius="0.5rem"
    data-testid={testId}
    py="6px"
  >
    {children}
  </StyledButton>
);

export const Keyblock: React.FunctionComponent<KeyblockProps> = ({
  onInput,
  withHapticFeedback,
  ...rest
}) => {
  const haptics = useHaptics();

  const onInputWithHaptics = (value: number | SpecialKey) => {
    if (withHapticFeedback) {
      haptics.lightImpact();
    }
    onInput(value);
  };

  const handleNumberPress = (key: number) => () => onInputWithHaptics(key);

  const handleDelete = () => onInputWithHaptics(SpecialKey.BACKSPACE);
  const handleDecimalPoint = () => onInputWithHaptics(SpecialKey.DECIMAL_POINT);

  const withDecimalPoint =
    "withDecimalPoint" in rest && !!rest.withDecimalPoint;
  const customAction = "customAction" in rest && rest.customAction;

  return (
    <Box display="grid" gridTemplateColumns="repeat(3, 1fr)" gridGap={2} py={3}>
      {range(9).map((i) => (
        <KeyButton
          key={i}
          onClick={handleNumberPress(i + 1)}
          data-testid={`keyblock-number-input-${i + 1}`}
        >
          {i + 1}
        </KeyButton>
      ))}
      {withDecimalPoint ? (
        <KeyButton
          onClick={handleDecimalPoint}
          data-testid="keyblock-decimal-point"
        >
          .
        </KeyButton>
      ) : customAction ? (
        <KeyButton
          onClick={customAction.onClick}
          data-testid="keyblock-custom-action"
          fontSize={1}
        >
          {customAction.text}
        </KeyButton>
      ) : (
        <Box />
      )}
      <KeyButton
        onClick={handleNumberPress(0)}
        data-testid="keyblock-number-input-0"
      >
        0
      </KeyButton>
      <KeyButton onClick={handleDelete} data-testid="keyblock-delete">
        {"<-"}
      </KeyButton>
    </Box>
  );
};
