import {
  BottomSheet as ReactBottomSheet,
  BottomSheetProps as ReactBottomSheetProps,
  BottomSheetRef as ReactBottomSheetRefHandles,
} from "react-spring-bottom-sheet-updated";
import { themeGet } from "@styled-system/theme-get";
import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { useCombinedRefs } from "../../../hooks/use-combined-refs";
import { useSwipeGestures } from "../../../hooks/use-swipe-gestures";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { zIndex } from "../../../theme/globals";
import { ThemeColor } from "../../../theme/theme";
import { hexToRgba } from "../../../utils/color-utils";
import { Color } from "../../../utils/types";
import { bottomSheetStyles } from "./bottom-sheet-styles";

export interface CustomBottomSheetProps {
  backgroundColor?: Color | ThemeColor;
}

export type BottomSheetProps = ReactBottomSheetProps & CustomBottomSheetProps;
export type BottomSheetRef = ReactBottomSheetRefHandles;

const StyledReactBottomSheet = styled(ReactBottomSheet)<{
  $backgroundColor?: string;
}>`
  --rsbs-backdrop-bg: ${(props) =>
    hexToRgba(themeGet("colors.white")(props), 0.75)};
  --rsbs-bg: ${(props) =>
    props.$backgroundColor ?? themeGet("colors.black")(props)};
  --rsbs-handle-bg: ${themeGet("colors.blacks.black500")};
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);

  ${bottomSheetStyles}

  * {
    transition: background 0.5s ease-in-out;
  }
`;

const BottomSheetGlobalStyles = createGlobalStyle<{
  ignorePointerEventsOnPortal: boolean;
}>`
  reach-portal {
    ${({ ignorePointerEventsOnPortal }) =>
      ignorePointerEventsOnPortal && "pointer-events: none;"}
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: ${zIndex.bottomSheet};
  }
`;

export const BottomSheet = React.forwardRef<
  ReactBottomSheetRefHandles,
  BottomSheetProps
>(({ children, backgroundColor, ...props }, ref) => {
  const [isClosing, setIsClosing] = useState(false);
  const [ignorePointerEventsOnPortal, setIgnorePointerEventsOnPortal] =
    useState(false);
  const sheetRef = useRef<ReactBottomSheetRefHandles | null>(null);

  const combinedRef = useCombinedRefs(ref, sheetRef);

  useEffect(() => {
    const timer = setTimeout(
      () => setIgnorePointerEventsOnPortal(isClosing),
      40 // no, a lower value will not work
    );

    return () => clearTimeout(timer);
  }, [isClosing]);

  const onSwipeDown = useCallback(() => props.onDismiss?.(), [props.onDismiss]);

  useSwipeGestures(
    {
      onSwipeDown,
    },
    !props.open
  );

  const bg = useThemeColor(backgroundColor);

  return (
    <>
      <BottomSheetGlobalStyles
        ignorePointerEventsOnPortal={ignorePointerEventsOnPortal}
      />
      <StyledReactBottomSheet
        ref={combinedRef}
        snapPoints={({ minHeight }) => [0, minHeight]}
        defaultSnap={({ snapPoints }) => Math.max(...snapPoints)}
        {...props}
        onSpringStart={(event) => {
          if (event.type === "CLOSE") {
            setIsClosing(true);
          }

          props.onSpringStart?.(event);
        }}
        onSpringEnd={(event) => {
          setIsClosing(false);

          // this is somewhat of a hack to  make sure the bottom sheet is dismissed after it snapped to the bottom
          if (event.type === "SNAP" && event.source === "dragging") {
            combinedRef.current?.snapTo((snapToProps) => {
              if (snapToProps.height === 0) {
                props.onDismiss?.();
              }

              return snapToProps.height;
            });
          }

          props.onSpringEnd?.(event);
        }}
        $backgroundColor={bg}
      >
        {children}
      </StyledReactBottomSheet>
    </>
  );
});
