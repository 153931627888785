import * as React from "react";
import { motion } from "framer-motion";
import { MobileMenuItem } from "./MobileMenuItem";

interface MobileNavigationProps {
  pages: { to: string; text: string; active?: boolean }[];
  toggle(): void;
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const MobileNavigation: React.FunctionComponent<
  MobileNavigationProps
> = ({ pages, toggle }) => (
  <motion.ul variants={variants} style={{ paddingLeft: "1.5rem" }}>
    {pages.map(({ to, text, active }) => (
      <MobileMenuItem
        key={to}
        to={to}
        text={text}
        active={active}
        toggle={toggle}
      />
    ))}
  </motion.ul>
);
