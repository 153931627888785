import styled from "styled-components";
import { BoxProps, boxStyles } from "../box/Box";

export interface SectionProps extends Omit<BoxProps, "color"> {}

export const Section = styled.section<SectionProps>`
  transition: background-color 0.2s ease-in-out;

  ${boxStyles}
`;
