import React from "react";
import { IoArrowForwardSharp } from "react-icons/io5";
import { Box, BoxProps } from "../../base/box/Box";
import { Image } from "../../base/image/Image";
import styled from "styled-components";
import { darken } from "../../../utils/color-utils";

export interface SwapToken {
  text: string;
  imageUrl: string;
  color: string;
  onClick?: () => void;
}

export interface SwapProps extends Omit<BoxProps, "color"> {
  from: SwapToken;
  to?: SwapToken;
}

const StyledBox = styled(Box)<{ $bg: string; $clickable: boolean }>`
  :focus {
    outline: 1px solid ${({ $bg }) => darken($bg, 0.24)};
    outline-offset: 1px;
  }

  ${({ $clickable, $bg }) =>
    $clickable &&
    `
  :hover,
  :focus {
    background-color: ${darken($bg, 0.08)};
  }
  :active {
    background-color: ${darken($bg, 0.16)};
  }
  `}
`;

const TokenPill: React.FunctionComponent<SwapToken> = ({
  text,
  imageUrl,
  color,
  onClick,
}) => (
  <StyledBox
    as={onClick ? "button" : "div"}
    onClick={onClick}
    borderRadius="1.25rem"
    bg={color}
    fontSize={1}
    display="flex"
    alignItems="center"
    py={2}
    pl={2}
    pr={3}
    gridGap="0.25rem"
    overflow="hidden"
    textOverflow="ellipsis"
    flexShrink="2"
    border="none"
    cursor={onClick ? "pointer" : undefined}
    whiteSpace="nowrap"
    $bg={color}
    $clickable={!!onClick}
  >
    <Image
      src={imageUrl}
      width="1.25rem"
      height="1.25rem"
      objectFit="contain"
      alt={text}
    />
    <Box
      overflow="hidden"
      textOverflow="ellipsis"
      flex="1"
      color="white"
      as="span"
    >
      {text}
    </Box>
  </StyledBox>
);

export const Swap: React.FunctionComponent<SwapProps> = ({
  from,
  to,
  ...boxProps
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      gridGap="0.75rem"
      {...boxProps}
    >
      <TokenPill {...from} />
      {to && (
        <>
          <IoArrowForwardSharp size="1.25rem" />
          <TokenPill {...to} />
        </>
      )}
    </Box>
  );
};
