import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import { Box, BoxProps } from "../box/Box";

export interface CustomProps {
  $fullWidth?: boolean;
  $noPadding?: boolean;
  $safeInsetTop?: boolean;
  $safeInsetBottom?: boolean;
}

export type ContainerProps = BoxProps & CustomProps;

export const containerPaddingRight = "max(env(safe-area-inset-right), 1.5rem)";
export const containerPaddingLeft = "max(env(safe-area-inset-left), 1.5rem)";

export const Container = styled(Box)<ContainerProps>`
  transition: background 0.25s ease-in-out;
  flex: ${({ flex }) => flex ?? 1};
  padding-right: ${({ $noPadding }) =>
    $noPadding ? 0 : containerPaddingRight};
  padding-left: ${({ $noPadding }) => ($noPadding ? 0 : containerPaddingLeft)};
  padding-top: ${({ $safeInsetTop }) =>
    $safeInsetTop && "env(safe-area-inset-top)"};
  padding-bottom: ${({ $safeInsetBottom }) =>
    $safeInsetBottom && "max(env(safe-area-inset-bottom), 0.5rem)"};

  ${themeGet("mediaQueries.sm")} {
    min-width: min(100%, ${({ theme }) => theme.breakpoints.sm});
    max-width: ${({ $fullWidth }) => ($fullWidth ? null : "84rem")};
    margin-left: ${({ $fullWidth }) => ($fullWidth ? null : "auto")};
    margin-right: ${({ $fullWidth }) => ($fullWidth ? null : "auto")};

    padding-right: ${({ $noPadding }) =>
      $noPadding ? 0 : "max(env(safe-area-inset-right), 4.5rem)"};
    padding-left: ${({ $noPadding }) =>
      $noPadding ? 0 : "max(env(safe-area-inset-left), 4.5rem)"};
  }
`;
