import { motion } from "framer-motion";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";

export enum FloatingStarVariant {
  DEFAULT = "default",
  WARNING = "warning",
  EXPERIMENTAL = "experimental",
  EXPERIMENTAL_50 = "experimental_50",
}

export interface FloatingStarProps {
  width?: string | number;
  height?: string | number;
  objectFit?: CSSProperties["objectFit"];
  variant?: FloatingStarVariant;
  color?: ThemeColor | Color;
}

const StyledSvg = styled.svg<{ $objectFit?: CSSProperties["objectFit"] }>`
  object-fit: ${({ $objectFit }) => $objectFit ?? "contain"};
`;

const colors: Record<FloatingStarVariant, ThemeColor> = {
  [FloatingStarVariant.DEFAULT]: "blue",
  [FloatingStarVariant.WARNING]: "warning.warning20",
  [FloatingStarVariant.EXPERIMENTAL]: "tokens.polygon.polygon50",
  [FloatingStarVariant.EXPERIMENTAL_50]: "experimental.experimental50",
};

export const FloatingStar: React.FunctionComponent<FloatingStarProps> = ({
  width,
  height,
  objectFit,
  variant = FloatingStarVariant.DEFAULT,
  color: colorProp,
}) => {
  const variantColor = useThemeColor(colors[variant]);
  const themeColor = useThemeColor(colorProp);

  const color = themeColor ?? variantColor;

  return (
    <StyledSvg
      $objectFit={objectFit}
      width={width}
      height={height}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1.2, 1, 1.2],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        opacity="0.4"
        cx="100.417"
        cy="174.598"
        rx="18.75"
        ry="3.33333"
        fill="#221C16"
      />
      <motion.g
        animate={{
          translateY: [7, -7, 7],
          rotate: [4, 0, 4],
          originX: 1,
          originY: 0.5,
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <path
          d="M167.029 85.7703L148.263 69.1317L121.421 69.5428L114.674 52.6925L95.905 36.0541L76.7912 73.8514L37.2277 83.1804L64.286 105.946L53.3123 145.384L89.1559 121.658L121.934 136.702L140.704 153.34L135.793 115.911L167.029 85.7703Z"
          fill={color}
        />
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M95.905 36.0538L114.674 52.6922L121.421 69.5425L109.385 69.7253L95.905 36.0538Z"
            fill="#221C16"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.4">
          <path
            d="M148.263 69.132L167.029 85.7705L135.793 115.912L117.026 99.273L148.263 69.132Z"
            fill="#221C16"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M117.026 99.2713L135.793 115.91L140.704 153.339L121.934 136.7L117.026 99.2713Z"
            fill="#221C16"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M106.93 102.891C110.878 107.179 114.827 111.466 119.138 120.531L116.558 99.546L123.28 92.8954C119.548 88.7381 115.765 83.8993 111.626 74.3737C110.972 72.8687 110.397 71.45 109.891 70.1059L109.08 70.1225L95.7815 37.7662L87.8618 53.2012C88.0521 55.11 88.1322 56.9482 88.2133 58.812C88.4873 65.1127 88.7741 71.7052 93.3801 82.2994C98.2216 93.4344 102.576 98.1627 106.93 102.891L106.93 102.891Z"
            fill="white"
          />
          <path
            d="M118.379 71.4323C121.865 79.447 125.144 84.1239 128.366 87.8637L147.084 69.345L117.751 69.9451C117.95 70.4323 118.159 70.9279 118.379 71.4323Z"
            fill="white"
          />
          <path
            d="M67.5344 135.899C64.9188 130.512 62.4099 126.859 59.9417 123.871L64.9425 105.759L38.2873 83.6812L72.6972 75.5108C73.4403 77.898 74.4139 80.5284 75.7016 83.4904C80.5449 94.6307 84.9908 99.3211 89.4374 104.012C93.8834 108.703 98.3301 113.394 103.175 124.534C103.786 125.937 104.326 127.266 104.804 128.529L89.2412 121.484L67.5344 135.899Z"
            fill="white"
          />
          <path
            d="M61.0058 140.234C59.757 137.546 58.5393 135.266 57.3439 133.28L54.1706 144.773L61.0058 140.234Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.2853 105.947L53.3122 145.383L53.3132 145.382L53.3122 145.385L72.0815 162.024L107.923 138.298L140.704 153.341L140.701 153.339L135.793 115.912L135.811 115.892L167.029 85.7688L148.262 69.1303L121.42 69.5413L114.674 52.691L95.9075 36.0525L95.9049 36.0526L76.791 73.85L37.6506 83.0792L37.1546 82.6619L37.6306 83.0839L37.2276 83.1789L37.23 83.181L37.2277 83.1816L47.2841 91.6427L55.9212 99.3004L60.4104 102.687L64.2853 105.947ZM140.701 153.339L121.934 136.703L121.934 136.7L140.701 153.339ZM53.3122 145.385L89.1532 121.659L89.1547 121.66L53.3122 145.385ZM65.5092 105.567L65.5098 105.565L39.5634 83.736L77.5254 74.7855L77.753 74.3391L95.7911 38.6704L95.791 38.6677L108.66 70.8171L120.692 70.6324L120.693 70.6345L145.547 70.2527L115.886 98.875L115.957 99.4136L120.613 134.908L89.059 120.429L55.1254 142.891L65.5099 105.568L65.5092 105.567ZM165.442 85.8061L135.375 114.822L117.415 98.8986L147.127 70.2285L147.858 70.2172L165.442 85.8061ZM121.769 135.439L117.133 100.089L134.671 115.638L134.727 116.055L139.261 150.621L122.65 135.896L122.531 135.789L122.385 135.721L121.769 135.439ZM120.263 69.5625L113.766 53.3322L96.8031 38.292L109.388 69.7294L120.263 69.5625Z"
          fill="#221C16"
        />
      </motion.g>
    </StyledSvg>
  );
};
