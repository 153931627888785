import React from "react";
import styled from "styled-components";
import { Box } from "../box/Box";
import { themeGet } from "@styled-system/theme-get";

const StyledOverlay = styled.div`
  --q: 0.8;
  --s: min(calc(100vh * var(--q)), calc(100vw * var(--q)));
  --y: calc((100vh - var(--s)) / 2);
  --x: calc((100vw - var(--s)) / 2);

  --overlay-border-width: 4px;
  --overlay-border-ratio: 0.2;
  --overlay-border-length: calc(var(--s) * var(--overlay-border-ratio));

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const BaseOverlay = styled(Box)`
  position: absolute;
  background-color: ${themeGet("colors.black")};
  opacity: 0.6;
`;

const Top = styled(BaseOverlay)`
  top: 0;
  left: 0;
  right: 0;
  height: var(--y);
`;

const Bottom = styled(BaseOverlay)`
  bottom: 0;
  left: 0;
  right: 0;
  height: var(--y);
`;

const Left = styled(BaseOverlay)`
  top: var(--y);
  left: 0;
  bottom: var(--y);
  width: var(--x);
`;

const Right = styled(BaseOverlay)`
  bottom: var(--y);
  right: 0;
  top: var(--y);
  width: var(--x);
`;

const BaseBorder = styled(Box)`
  position: absolute;
  background-color: ${themeGet("colors.white")};
`;

const BorderTopLeftLeft = styled(BaseBorder)`
  width: var(--overlay-border-width);
  height: var(--overlay-border-length);
  top: var(--y);
  left: calc(var(--x) - (var(--overlay-border-width) / 2));
`;

const BorderTopLeftTop = styled(BaseBorder)`
  height: var(--overlay-border-width);
  width: var(--overlay-border-length);
  left: calc(var(--x) - (var(--overlay-border-width) / 2));
  top: calc(var(--y) - (var(--overlay-border-width) / 2));
`;

const BorderTopRightRight = styled(BaseBorder)`
  height: var(--overlay-border-length);
  width: var(--overlay-border-width);
  right: calc(var(--x) - (var(--overlay-border-width) / 2));
  top: calc(var(--y) - (var(--overlay-border-width) / 2));
`;

const BorderTopRightTop = styled(BaseBorder)`
  height: var(--overlay-border-width);
  width: var(--overlay-border-length);
  right: calc(var(--x) - (var(--overlay-border-width) / 2));
  top: calc(var(--y) - (var(--overlay-border-width) / 2));
`;

const BorderBottomLeftBottom = styled(BaseBorder)`
  height: var(--overlay-border-width);
  width: var(--overlay-border-length);
  left: calc(var(--x) - (var(--overlay-border-width) / 2));
  bottom: calc(var(--y) - (var(--overlay-border-width) / 2));
`;

const BorderBottomLeftLeft = styled(BaseBorder)`
  width: var(--overlay-border-width);
  height: var(--overlay-border-length);
  bottom: var(--y);
  left: calc(var(--x) - (var(--overlay-border-width) / 2));
`;

const BorderBottomRightBottom = styled(BaseBorder)`
  height: var(--overlay-border-width);
  width: var(--overlay-border-length);
  right: calc(var(--x) - (var(--overlay-border-width) / 2));
  bottom: calc(var(--y) - (var(--overlay-border-width) / 2));
`;

const BorderBottomRightRight = styled(BaseBorder)`
  width: var(--overlay-border-width);
  height: var(--overlay-border-length);
  bottom: var(--y);
  right: calc(var(--x) - (var(--overlay-border-width) / 2));
`;

export const ScannerOverlay: React.FunctionComponent = () => {
  return (
    <StyledOverlay>
      <Top />
      <Bottom />
      <Left />
      <Right />
      <BorderTopLeftLeft />
      <BorderTopLeftTop />
      <BorderTopRightRight />
      <BorderTopRightTop />
      <BorderBottomLeftBottom />
      <BorderBottomLeftLeft />
      <BorderBottomRightBottom />
      <BorderBottomRightRight />
    </StyledOverlay>
  );
};
