import React from "react";
import { ThemeColor } from "../../../theme/theme";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { Box, BoxProps } from "../box/Box";
import styled from "styled-components";

export enum LockState {
  ACTIVE = "ACTIVE",
  ACTIVE_COMPLETE = "ACTIVE_COMPLETE",
  COMPLETE = "COMPLETE",
  INACTIVE = "INACTIVE",
}

export interface LockProps extends Omit<BoxProps, "color"> {
  state: LockState;
}

const colorMap: Record<LockState, ThemeColor> = {
  [LockState.ACTIVE]: "black",
  [LockState.ACTIVE_COMPLETE]: "success.success50",
  [LockState.INACTIVE]: "black",
  [LockState.COMPLETE]: "success.success50",
};
const opacityMap: Record<LockState, number> = {
  [LockState.ACTIVE]: 1,
  [LockState.ACTIVE_COMPLETE]: 1,
  [LockState.INACTIVE]: 0.4,
  [LockState.COMPLETE]: 0.6,
};

const closedPath =
  "M21.75 11L17.5 11L17.5 6C17.5 4.4087 16.8679 2.88258 15.7426 1.75736C14.6174 0.632142 13.0913 1.10077e-06 11.5 9.61651e-07C9.9087 8.22535e-07 8.38258 0.632142 7.25736 1.75736C6.13214 2.88258 5.5 4.4087 5.5 6L5.5 11L1.25 11C1.05109 11 0.860321 11.079 0.719669 11.2197C0.579017 11.3603 0.499999 11.5511 0.499999 11.75L0.499997 29.25C0.499997 29.4489 0.579015 29.6397 0.719667 29.7803C0.86032 29.921 1.05108 30 1.25 30L21.75 30C21.9489 30 22.1397 29.921 22.2803 29.7803C22.421 29.6397 22.5 29.4489 22.5 29.25L22.5 11.75C22.5 11.5511 22.421 11.3603 22.2803 11.2197C22.1397 11.079 21.9489 11 21.75 11ZM15.125 11L7.875 11L7.875 5.95313C7.875 4.99172 8.25692 4.06968 8.93674 3.38986C9.61656 2.71004 10.5386 2.32813 11.5 2.32813C12.4614 2.32813 13.3834 2.71004 14.0633 3.38986C14.7431 4.06968 15.125 4.99172 15.125 5.95313L15.125 11Z";

const openPath =
  "M21.75 11H7.875V5.95312C7.87595 5.23618 8.08919 4.53558 8.48781 3.93967C8.88643 3.34376 9.45259 2.87924 10.1149 2.60468C10.7772 2.33013 11.5059 2.25784 12.2092 2.39693C12.9126 2.53602 13.559 2.88027 14.0669 3.38625C14.7496 4.08443 15.1298 5.0235 15.125 6H17.5C17.5 4.4087 16.8679 2.88258 15.7426 1.75736C14.6174 0.632141 13.0913 0 11.5 0C9.9087 0 8.38258 0.632141 7.25736 1.75736C6.13214 2.88258 5.5 4.4087 5.5 6V11H1.25C1.05109 11 0.860322 11.079 0.71967 11.2197C0.579018 11.3603 0.5 11.5511 0.5 11.75V29.25C0.5 29.4489 0.579018 29.6397 0.71967 29.7803C0.860322 29.921 1.05109 30 1.25 30H21.75C21.9489 30 22.1397 29.921 22.2803 29.7803C22.421 29.6397 22.5 29.4489 22.5 29.25V11.75C22.5 11.5511 22.421 11.3603 22.2803 11.2197C22.1397 11.079 21.9489 11 21.75 11Z";

const StyledPath = styled.path`
  transition: fill 0.2s ease-in-out;
`;

const StyledBox = styled(Box)`
  transition: opacity 0.2s ease-in-out;
`;

export const Lock: React.FunctionComponent<LockProps> = ({
  state,
  ...boxProps
}) => {
  const fillColor = useThemeColor(colorMap[state]);

  return (
    <StyledBox
      as={"svg" as any} // hack 🥴
      viewBox="0 0 23 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      opacity={opacityMap[state]}
      {...boxProps}
    >
      <StyledPath
        fill={fillColor}
        d={state === LockState.COMPLETE ? openPath : closedPath}
      />
    </StyledBox>
  );
};
