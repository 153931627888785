import useSize from "@react-hook/size";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import React, { useRef } from "react";
import {
  ScrollDirection,
  useScrollDirection,
} from "../../../../hooks/use-scroll-direction";
import { zIndex } from "../../../../theme/globals";
import { Box } from "../../../base/box/Box";
import { Button, ButtonSize, ButtonVariant } from "../../../base/button/Button";
import { Container } from "../../../base/container/Container";
import { Logo } from "../../../base/logo/Logo";
import { RouterLink } from "../../../base/router/RouterLink";
import { BaseHeaderProps } from "../header-commons";
import { MenuToggle } from "./MenuToggle";
import { MobileNavigation } from "./MobileNavigation";

const headerHeight = 5 * 16;
const fabSize = 56;
const fabMargin = 16;
const fabCenter = fabSize / 2 + fabMargin;

const sidebar = {
  open: (
    { height, width }: { width: number; height: number } = {
      width: 1000,
      height: 1000,
    }
  ) => {
    return {
      y: 0,
      clipPath: `circle(${
        Math.max(height, width) * 2 + 200
      }px at ${fabCenter}px ${fabCenter}px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2,
      },
    };
  },
  closed: (
    { width }: { width: number; height: number } = {
      width: 1000,
      height: 1000,
    }
  ) => {
    return {
      y: 0,
      clipPath: `circle(${fabSize / 2}px at ${
        width - fabCenter
      }px ${fabCenter}px)`,
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
    };
  },
  hidden: (
    { width }: { width: number; height: number } = {
      width: 1000,
      height: 1000,
    }
  ) => ({
    y: -headerHeight,
    clipPath: `circle(${fabSize / 2}px at ${width - fabCenter}px ${
      fabCenter - headerHeight
    }px)`,
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  }),
};

export const HeaderMobile: React.FunctionComponent<BaseHeaderProps> = ({
  pages,
  logo: overrideLogo,
  actions,
}) => {
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const [width, height] = useSize(containerRef);
  const scrollDirection = useScrollDirection();

  const scrollingDown = scrollDirection === ScrollDirection.DOWN;

  return (
    <Box
      display={{ _: "block", sm: "none" }}
      position="fixed"
      as={motion.nav}
      initial={false}
      animate={scrollingDown ? "hidden" : isOpen ? "open" : "closed"}
      ref={containerRef}
      top="0"
      left="0"
      right="0"
      zIndex={zIndex.overlay}
    >
      <Box
        position="fixed"
        top={`${headerHeight}px`}
        bottom="0"
        left="0"
        right="0"
        zIndex={zIndex.overlay + 2}
        pointerEvents={isOpen ? "auto" : "none"}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <MobileNavigation pages={pages} toggle={toggleOpen} />
        <AnimatePresence>
          {isOpen && (
            <Container
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              $safeInsetBottom={true}
              flex="0 0"
              mb={2}
              display="flex"
            >
              {actions.map(({ key, buttonProps, text }) => (
                <Button
                  as={RouterLink}
                  flex="1"
                  key={key}
                  variant={ButtonVariant.DEFAULT_INVERSE}
                  size={ButtonSize.LARGE}
                  {...buttonProps}
                >
                  {text}
                </Button>
              ))}
            </Container>
          )}
        </AnimatePresence>
      </Box>
      <Box
        as={motion.div}
        variants={{
          shown: {
            y: 0,
          },
          hidden: {
            y: "-100%",
          },
        }}
        animate={scrollingDown ? "hidden" : "shown"}
        position="fixed"
        height={`${headerHeight}px`}
        top="0"
        left="0"
        right="0"
        zIndex={zIndex.overlay + 1}
        display="flex"
        alignItems="center"
        pl="1.5rem"
      >
        <RouterLink href="/">
          {!overrideLogo && (
            <Logo color={isOpen ? "white" : "black"} height="1.75rem" />
          )}
          {overrideLogo && <>{overrideLogo}</>}
        </RouterLink>
      </Box>
      {width > 0 && (
        <Box
          as={motion.div}
          custom={{ height, width }}
          variants={sidebar}
          position="fixed"
          top="0"
          right="0"
          bottom="0"
          width="100vw"
          bg="black"
          // trigger framer motion rerender on resize
          key={height + width}
          pointerEvents="none"
        />
      )}
      <MenuToggle
        toggle={() => toggleOpen()}
        size={fabSize}
        margin={fabMargin}
      />
    </Box>
  );
};
