import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  PropsWithChildren,
} from "react";
import { Box } from "../box/Box";
import useSize from "@react-hook/size";
import { PageIndicator } from "../page-indicator/PageIndicator";
import { useScrollPosition } from "../../../hooks/use-scroll-position";
import styled from "styled-components";

export interface CarouselRootProps {
  hidePageIndicator?: boolean;
  fitContent?: boolean;
}

const noop = () => {};

interface CarouselContextType {
  parentWidth: number;
  numItems: number;
  registerItem: () => void;
  unregisterItem: () => void;
  fitContent?: boolean;
}

const CarouselContext = React.createContext<CarouselContextType>({
  parentWidth: 0,
  numItems: 0,
  registerItem: noop,
  unregisterItem: noop,
});

const StyledRoot = styled(Box)`
  scroll-snap-type: x mandatory;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Root: React.FunctionComponent<
  PropsWithChildren<CarouselRootProps>
> = ({ hidePageIndicator, fitContent, children }) => {
  const ref = React.useRef<null | HTMLDivElement>(null);
  const [numItems, setNumItems] = useState(0);
  const [width] = useSize(ref);
  const scroll = useScrollPosition(ref);

  const registerItem = () => setNumItems((n) => n + 1);
  const unregisterItem = () => setNumItems((n) => n - 1);

  const currentPage = Math.floor((scroll.x + width / 2) / width);

  const onPageClick = useCallback(
    (page: number) => {
      if (ref.current?.children[page]) {
        ref.current.children[page].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    },
    [ref, width]
  );

  const numChildren =
    React.Children.map(children, React.isValidElement)?.filter(Boolean)
      .length ?? 0;

  return (
    <Box
      display="flex"
      flexDirection="column"
      onTouchStart={(e) => {
        if (numChildren > 1) {
          e.stopPropagation();
        }
      }}
    >
      <StyledRoot ref={ref} display="flex" overflowX="auto" pb="0.75rem">
        <CarouselContext.Provider
          value={{
            parentWidth: width,
            numItems: 0,
            registerItem,
            unregisterItem,
            fitContent,
          }}
        >
          {children}
        </CarouselContext.Provider>
      </StyledRoot>
      {!hidePageIndicator && (
        <Box display="flex" justifyContent="center" pt="0.75rem">
          <PageIndicator
            numPages={numItems}
            currentPage={currentPage}
            onPageClick={onPageClick}
          />
        </Box>
      )}
    </Box>
  );
};

const StyledItem = styled(Box)`
  scroll-snap-align: start;
`;

export const Item: React.FunctionComponent<
  PropsWithChildren<CarouselRootProps>
> = ({ children }) => {
  const ref = React.useRef<null | HTMLDivElement>(null);
  const { registerItem, unregisterItem, fitContent } =
    useContext(CarouselContext);

  useEffect(() => {
    registerItem();

    return unregisterItem;
  }, []);

  return (
    <StyledItem
      ref={ref}
      flexGrow={fitContent ? "0" : "1"}
      flexShrink={fitContent ? "1" : "0"}
      width="100%"
      display="flex"
      justifyContent="center"
    >
      {children}
    </StyledItem>
  );
};
``;
