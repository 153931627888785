import { motion } from "framer-motion";
import React, { CSSProperties } from "react";
import styled from "styled-components";

export interface FloatingZkSyncProps {
  width?: string | number;
  height?: string | number;
  objectFit?: CSSProperties["objectFit"];
}

const StyledSvg = styled.svg<{ $objectFit?: CSSProperties["objectFit"] }>`
  object-fit: ${({ $objectFit }) => $objectFit ?? "contain"};
`;

const container = {
  small: { opacity: 0 },
  large: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  small: { scale: 0 },
  large: { scale: 1 },
};

const transitionCommon = {
  repeat: Infinity,
  repeatType: "mirror" as const,
  type: "easyInOut",
  duration: 1,
};

export const FloatingZkSync: React.FunctionComponent<FloatingZkSyncProps> = ({
  width,
  height,
  objectFit,
}) => {
  return (
    <StyledSvg
      $objectFit={objectFit}
      width={width}
      height={height}
      viewBox="0 0 166 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1, 1.3, 1],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        opacity="0.4"
        cx="100.417"
        cy="174.598"
        rx="18.75"
        ry="3.33333"
        fill="#221C16"
      />
      <motion.g variants={container} initial="small" animate="large">
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.6"
          d="M43.7193 19.6787C38.7249 19.6787 38.7175 27.1787 43.7193 27.1787C48.7212 27.1787 48.7212 19.6787 43.7193 19.6787Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.4"
          d="M181.383 138.729C176.39 138.729 176.383 146.229 181.383 146.229C186.383 146.229 186.39 138.729 181.383 138.729Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M20.7065 109.38C12.3792 109.38 12.3711 121.88 20.7065 121.88C29.0418 121.88 29.0418 109.38 20.7065 109.38Z"
          fill="white"
        />
      </motion.g>
      <motion.g
        animate={{
          translateY: [-5, 0, -5],
          rotate: [3, 0, 3],
          originX: 1,
          originY: 0.5,
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <path
          d="M94.2629 154.112C133.538 147.892 160.334 111.01 154.113 71.7354C147.893 32.4605 111.011 5.66462 71.7364 11.8852C32.4614 18.1057 5.66555 54.9871 11.8861 94.262C18.1066 133.537 54.988 160.333 94.2629 154.112Z"
          fill="#1E69FF"
        />
        <path
          d="M65.202 119.977L29.6634 91.4415L54.6447 53.3206L57.462 71.108L87.5112 66.3719L61.3673 95.7655L65.202 119.977Z"
          fill="white"
        />
        <path
          d="M100.797 46.0234L136.336 74.5589L111.355 112.68L108.537 94.8923L78.4881 99.6284L104.663 70.4313L100.797 46.0234Z"
          fill="white"
        />
      </motion.g>
    </StyledSvg>
  );
};
