import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { noop } from "../../../utils/noop";
import {
  NotificationOverlay,
  NotificationOverlayProps,
} from "./NotificationOverlay";

export type PartialOverlayProps = Omit<
  NotificationOverlayProps,
  "open" | "onDismiss"
>;

export interface NotificationOverlayContextType {
  open: (props: PartialOverlayProps) => void;
  close: () => void;
}

export const NotificationOverlayContext =
  React.createContext<NotificationOverlayContextType>({
    open: noop,
    close: noop,
  });

export const NotificationOverlayProvider: React.FunctionComponent<
  PropsWithChildren
> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState<PartialOverlayProps>({});

  const onDismiss = useCallback(() => {
    setOpen(false);
  }, []);

  const openOverlay = useCallback((props: PartialOverlayProps) => {
    setProps(props);
    setOpen(true);
  }, []);
  const closeOverlay = useCallback(() => setOpen(false), []);

  const value = useMemo(
    () => ({
      open: openOverlay,
      close: closeOverlay,
    }),
    [openOverlay, closeOverlay]
  );

  return (
    <NotificationOverlayContext.Provider value={value}>
      {children}
      <NotificationOverlay {...props} open={open} onDismiss={onDismiss} />
    </NotificationOverlayContext.Provider>
  );
};
