import themeGet from "@styled-system/theme-get";
import { useTheme } from "styled-components";
import { ThemeColor } from "../theme/theme";
import { isThemeColor } from "../utils/theme-utils";
import { Color } from "../utils/types";

export function useThemeColor(color: Color): Color;
export function useThemeColor(color: ThemeColor): Color;
export function useThemeColor(color: Color | ThemeColor): Color;
export function useThemeColor(color?: Color | ThemeColor): Color | undefined;
export function useThemeColor(color?: Color | ThemeColor): Color | undefined {
  const theme = useTheme();

  if (!color) {
    return undefined;
  }

  if (isThemeColor(color)) {
    return themeGet(`colors.${color}`)({ theme });
  }

  return color;
}

export function useThemeColors(colors: (Color | ThemeColor)[]): Color[] {
  const theme = useTheme();

  return colors.map((color) => {
    if (isThemeColor(color)) {
      return themeGet(`colors.${color}`)({ theme });
    }

    return color;
  });
}
