import React, { PropsWithChildren, useMemo } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Box } from "../../base/box/Box";

import { SpaceProps } from "styled-system";
import { P } from "../../base/typography/Typography";

export interface DeckProps extends SpaceProps {}

const NoScrollbarsBox = styled(Box)`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Deck: React.FunctionComponent<PropsWithChildren<DeckProps>> = ({
  children,
  ...spaceProps
}) => {
  return (
    <NoScrollbarsBox
      display="flex"
      overflowX="auto"
      py={4}
      px={5}
      {...spaceProps}
    >
      {children}
    </NoScrollbarsBox>
  );
};

export interface CardProps extends SpaceProps {
  tag: string;
  bg?: string;
  color?: string;
}

const StyledBox = styled(Box)<{ rotate: number }>`
  transform: rotate(${({ rotate }) => rotate}deg);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
`;

const maxRotationDegrees = 7;

export const Card: React.FunctionComponent<PropsWithChildren<CardProps>> = ({
  tag,
  children,
  color,
  bg,
  ...spaceProps
}) => {
  const rotation = useMemo(
    () => (Math.random() - 0.5) * maxRotationDegrees * 2,
    []
  );

  return (
    <StyledBox
      bg={bg}
      height="26rem"
      borderRadius="1rem"
      p="2rem"
      mr={4}
      flex="0 0 18rem"
      rotate={rotation}
      as={motion.div}
      display="flex"
      flexDirection="column"
      textAlign="start"
      transition={{ type: "spring", stiffness: 180 }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: "all" }}
      variants={{
        visible: { rotate: rotation },
        hidden: { rotate: 0 },
      }}
      whileHover={{
        scale: 1.05,
        rotate: rotation + (Math.random() > 0.5 ? 1 : -1),
      }}
      {...spaceProps}
    >
      <Box mb={3} color={color} fontWeight="600">
        {tag}
      </Box>
      <Box overflowY="auto">
        <P
          fontWeight="600"
          lineHeight="1.25"
          fontSize="1.5rem"
          color={color}
          my={0}
        >
          {children}
        </P>
      </Box>
    </StyledBox>
  );
};
