import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";
import { variant } from "styled-system";
import { Box } from "../../base/box/Box";
import { InputVariant } from "../input/Input";

export interface AssistiveTextProps {
  variant: InputVariant;
  $hasError?: boolean;
}

export const AssistiveText = styled(Box)<AssistiveTextProps>`
  font-size: ${themeGet("fontSizes.0")};

  ${(props) =>
    variant({
      variants: {
        [InputVariant.LIGHT]: {
          color: themeGet(
            props.$hasError ? "colors.danger.danger50" : "colors.blacks.black600"
          )(props),
        },
        [InputVariant.DARK]: {
          color: themeGet(
            props.$hasError ? "colors.danger.danger50" : "colors.blacks.black400"
          )(props),
        },
      },
    })};
`;
