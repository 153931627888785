import styled from "styled-components";
import {
  border,
  BorderProps,
  compose,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
} from "styled-system";
import {
  cursor,
  CursorProps,
  object,
  ObjectProps,
  pointerEvents,
  PointerEventsProps,
} from "../../../theme/styled-system-custom";
import { shouldForwardProp } from "../../../utils/should-forward-prop";

export type BaseSvgProps = SpaceProps &
  LayoutProps &
  PositionProps &
  BorderProps &
  ShadowProps &
  PointerEventsProps &
  CursorProps &
  ObjectProps;

export const BaseSvg = styled.svg.withConfig({
  shouldForwardProp,
})<BaseSvgProps>`
  ${compose(
    space,
    layout,
    position,
    border,
    shadow,
    pointerEvents,
    cursor,
    object
  )}
`;
