import React, { PropsWithChildren } from "react";
import { Box, BoxProps } from "../box/Box";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { AnimatePresence, motion } from "framer-motion";

export interface SimpleTableRootProps {}

const StyledRoot = styled(Box)`
  > * {
    border-top: 1px solid ${themeGet("colors.blacks.black100")};

    &:last-child {
      border-bottom: 1px solid ${themeGet("colors.blacks.black100")};
    }
  }
`;

export const Root: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <StyledRoot mt="0.75rem" fontSize={1} as={motion.div}>
      <AnimatePresence>{children}</AnimatePresence>
    </StyledRoot>
  );
};

const StyledRow = styled(Box)`
  > div:first-child {
    flex: 1;
    overflow: hidden;
  }
`;

export const Row: React.FunctionComponent<
  PropsWithChildren<Omit<BoxProps, "color">>
> = ({ children, ...boxProps }) => {
  return (
    <StyledRow
      py={3}
      display="flex"
      {...boxProps}
      as={motion.div}
      initial={{ opacity: 0, y: "-100%" }}
      animate={{ opacity: 1, y: 0 }}
    >
      {children}
    </StyledRow>
  );
};

const StyledCol = styled(Box)`
  &:nth-child(2) {
    color: ${themeGet("colors.blacks.black600")};
  }
`;

export const Col: React.FunctionComponent<
  PropsWithChildren<Omit<BoxProps, "color">>
> = ({ children, ...boxProps }) => {
  return (
    <StyledCol {...boxProps} as={motion.div}>
      {children}
    </StyledCol>
  );
};
