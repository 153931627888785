import themeGet from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";
import { range } from "../../../utils/range";
import { Box, BoxProps } from "../box/Box";

export interface PageIndicatorProps extends Omit<BoxProps, 'color'> {
  numPages: number;
  currentPage: number;
  onPageClick: (page: number) => void;
}

const StyledButton = styled.button<{ active: boolean }>`
  background-color: ${({ active }) =>
    active ? themeGet("colors.black") : "transparent"};
  border-radius: 50%;
  border: 1px solid ${themeGet("colors.black")};

  height: 0.75rem;
  width: 0.75rem;
  padding: 0;

  cursor: pointer;
`;

export const PageIndicator: React.FunctionComponent<PageIndicatorProps> =
  React.memo(({ numPages, onPageClick, currentPage, ...boxProps }) => {
    return (
      <Box
        aria-hidden="true"
        display="flex"
        justifyContent="center"
        gridGap="0.5rem"
        {...boxProps}
      >
        {range(numPages).map((i) => (
          <StyledButton
            key={i}
            active={currentPage === i}
            onClick={() => onPageClick(i)}
          />
        ))}
      </Box>
    );
  });
