import React from "react";
import { motion } from "framer-motion";

export enum FloatingCrossVariant {
  WARNING = "warning",
  INFO = "info",
}

export interface FloatingCrossProps {
  size: string;
  variant?: FloatingCrossVariant;
}

const fillColorMap: Record<FloatingCrossVariant, string> = {
  [FloatingCrossVariant.WARNING]: "#EC971C",
  [FloatingCrossVariant.INFO]: "#4C75B7",
};

export const FloatingCross: React.FunctionComponent<FloatingCrossProps> = ({
  size,
  variant = FloatingCrossVariant.INFO,
}) => {
  const fillColor = fillColorMap[variant];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1.3, 1, 1.3],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        cx="80.3333"
        cy="139.679"
        rx={15}
        ry="2.66667"
        fill="#221C16"
        fillOpacity="0.24"
      />
      <motion.g
        animate={{
          y: [6, 0, 6],
          rotate: [3, -3, 3],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        id="cross"
      >
        {" "}
        <path
          d="M103.113 121.26L120.585 107.456L96.8556 77.2955L127.21 53.3139L113.142 35.5077L82.8237 59.4607L58.4244 28.4488L40.667 42.2208L65.2544 73.3416L34.0871 97.9656L48.1551 115.772L79.3223 91.1478L103.113 121.26Z"
          fill={fillColor}
        />
        <g style={{ mixBlendMode: "overlay" }} opacity="0.15">
          <path
            d="M114.526 37.2595C114.226 37.8121 113.922 38.3624 113.613 38.9102C106.903 51.1149 101.347 55.9866 95.7907 60.8582C90.2345 65.7299 84.6782 70.6016 77.9684 82.8063C74.8323 88.5075 72.9097 93.1477 71.6334 97.2222L48.155 115.772L34.087 97.9654L65.2543 73.3414L47.843 51.3035C51.6692 46.328 55.1996 43.2337 58.7301 40.1393C60.4442 38.6369 62.1584 37.1344 63.9065 35.4166L82.8236 59.4606L113.142 35.5075L114.526 37.2595Z"
            fill="white"
          />
          <path
            d="M120.182 44.4194C113.826 55.6133 108.58 60.3477 103.333 65.0821C97.8738 70.0086 92.4143 74.9351 85.7045 87.1398C84.2237 89.8269 83.0084 92.2751 82.0002 94.5372L98.3819 115.272C99.6705 110.591 101.723 105.221 105.563 98.2381C106.707 96.1573 107.815 94.2881 108.892 92.5945L96.8555 77.2953L127.21 53.3137L120.182 44.4194Z"
            fill="white"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M120.585 107.456L103.113 121.26L91.3774 118.09L74.5941 94.8832L48.1549 115.772L48.1026 115.705L41.0849 114.882L26.2482 96.1033L58.5533 72.9883L32.5769 42.1412L48.937 28.8144L57.7063 29.0056L58.4242 28.4488L78.736 54.2655L103.784 35.2469L113.176 35.4516L112.076 36.3497L113.142 35.5077L127.209 53.3139L96.8554 77.2955L120.585 107.456ZM67.1307 73.5617L42.6395 42.5625L44.7032 40.7811L58.1946 30.3176L82.6013 61.339L112.921 37.3843L125.333 53.0938L94.9809 77.0737L118.71 107.234L103.333 119.383L79.5422 89.2712L48.375 113.895L35.9635 98.1856L67.1307 73.5617Z"
          fill="#221C16"
        />
      </motion.g>
    </svg>
  );
};
