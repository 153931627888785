import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import * as HeadlessTabs from "@radix-ui/react-tabs";
import themeGet from "@styled-system/theme-get";

export const List = styled(HeadlessTabs.List)`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const Trigger = styled(HeadlessTabs.Trigger)`
  border: none;
  background: none;

  margin-inline-start: 0.5rem;
  margin-inline-end: 0.5rem;
  padding-inline-start: 0;
  padding-inline-end: 0;

  cursor: pointer;

  font-size: ${themeGet("fontSizes.4")};
  line-height: 1.5;
  color: ${themeGet("colors.blacks.black600")};
  font-weight: 600;

  &[data-state="active"] {
    color: ${themeGet("colors.black")};
  }
`;

export const Content = HeadlessTabs.Content;

const Line = styled.div<{
  animate: boolean;
}>`
  height: 2px;
  transition: ${({ animate }) =>
    animate ? "transform 0.2s ease-in-out" : "none"};
  position: absolute;
  left: 0;
  top: 0;
  background-color: ${themeGet("colors.black")};
`;

const StyledRoot = styled(HeadlessTabs.Root)`
  position: relative;
`;

interface ActiveTabPosition {
  x: number;
  y: number;
  height: number;
  width: number;
}

export const Root: React.FunctionComponent<HeadlessTabs.TabsProps> = ({
  children,
  onValueChange,
  defaultValue,
  ...tabsProps
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [activeTab, setActiveTab] = useState<string | undefined>(defaultValue);
  const [windowSize, setWindowSize] = useState<number>(0);
  const [tabPosition, setTabPosition] = useState<null | ActiveTabPosition>();
  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    // calculate the position of the line when the active tab changes
    if (ref.current) {
      const position = ref.current
        .querySelector('[role="tab"][data-state="active"]')
        ?.getBoundingClientRect();
      const parentPostion = ref.current.getBoundingClientRect();

      setTabPosition(
        position
          ? {
              x: position.left - parentPostion.left,
              y: position.top - parentPostion.top,
              height: position.height,
              width: position.width,
            }
          : null
      );
    }
  }, [activeTab, windowSize, ref]);

  useEffect(() => {
    // reposition the line when window is resized
    let timer: NodeJS.Timeout;
    const handleResize = () => {
      setAnimate(false);
      setWindowSize(window.innerWidth);

      timer = setTimeout(() => setAnimate(true));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onValueChangeHandler = (val: string) => {
    onValueChange?.(val);
    setActiveTab(val);
  };

  return (
    <StyledRoot
      ref={ref}
      value={activeTab}
      orientation="vertical"
      onValueChange={onValueChangeHandler}
      defaultValue={defaultValue}
      {...tabsProps}
    >
      {tabPosition && (
        <Line
          animate={animate}
          style={{
            transform: `translate(${tabPosition.x}px, ${
              tabPosition.y + tabPosition.height - 6
            }px)`,
            width: `${tabPosition.width}px`,
          }}
        />
      )}

      {children}
    </StyledRoot>
  );
};
