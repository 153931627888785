import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { MoneyStack } from "../../graphics/money-stack/MoneyStack";
import { ProductCard } from "../product-card/ProductCard";

export interface ProductCardYieldProps extends Omit<BoxProps, "color"> {}

const IllustrationContainer = styled(Box)`
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  height: 100%;

  > svg {
    height: 100%;
    position: relative;
    right: 1rem;
  }
`;

export const ProductCardYield: React.FunctionComponent<
  PropsWithChildren<ProductCardYieldProps>
> = ({ ...boxProps }) => {
  return (
    <ProductCard
      {...boxProps}
      bg="warning.warning30"
      title="Yield"
      subtitle="Earning"
      illustration={
        <IllustrationContainer mr="-1.5rem" ml="-1rem">
          <MoneyStack withoutAnimation={true} />
        </IllustrationContainer>
      }
      numberPostfix="Coming soon"
      description="By providing liquidity to DeFi platforms your assets facilitate trading and you earn rewards. "
    />
  );
};
