import React from "react";
import { ThemeColor } from "../../../theme/theme";
import { ButtonVariant } from "../../base/button/Button";
import { BaseHeader } from "../base-header/BaseHeader";
import { HeaderProps } from "./header-commons";

export const Header: React.FunctionComponent<HeaderProps> = ({
  pages,
  logo,
  bg,
  ...props
}) => {
  const actions =
    "isLoggedIn" in props
      ? props.isLoggedIn
        ? [
            {
              key: "app",
              text: "Go to app",
              buttonProps: {
                href: props.appRoute,
              },
            },
          ]
        : [
            {
              key: "app",
              text: "Sign in",
              buttonProps: {
                href: props.appRoute,
                variant: ButtonVariant.OUTLINED_INVERSE,
              },
            },
          ]
      : [];

  return (
    <BaseHeader
      pages={pages}
      actions={actions}
      logo={logo}
      bg={bg as ThemeColor}
    />
  );
};
