import React, { PropsWithChildren, useMemo } from "react";
import { ThemeProvider } from "styled-components";
import { getTheme } from "./theme";
import { ThemeId } from "./theme-id";

export interface ForceThemeProps {
  theme: ThemeId;
}

export const ForceTheme: React.FunctionComponent<
  PropsWithChildren<ForceThemeProps>
> = ({ children, theme: themeId }) => {
  const theme = useMemo(() => getTheme(themeId), [themeId]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const DarkTheme: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return <ForceTheme theme={ThemeId.DARK}>{children}</ForceTheme>;
};

export const LightTheme: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return <ForceTheme theme={ThemeId.LIGHT}>{children}</ForceTheme>;
};
