import React, { PropsWithChildren, useEffect } from "react";
import { RouterLinkProps } from "./RouterLink";

export const DummyRouterLink = React.forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<RouterLinkProps>
>(({ href, onClick, className, children, onBeforeNavigation }, ref) => {
  useEffect(() => {
    console.warn(
      `WARNING: You are using dummy links. Please use the LinkProvider to configure a` +
        ` link component. Dummy links should never be used in production. The link in question links to "${href}"`
    );
  }, []);

  return (
    <a
      onClick={(e) => {
        onClick?.(e);
        onBeforeNavigation?.(e);

        e.preventDefault();

        alert(`Dummy link: Navigate to ${href}`);
      }}
      className={className}
      ref={ref}
      data-dummy-link="true"
      data-link-to={href}
      href="/"
    >
      {children}
    </a>
  );
});
