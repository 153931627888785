import themeGet from "@styled-system/theme-get";
import React from "react";
import { IoCloseSharp, IoCopySharp, IoHelpBuoySharp } from "react-icons/io5";
import styled from "styled-components";
import { useLongPress } from "../../../hooks/use-long-press";
import { LightTheme } from "../../../theme/ForceTheme";
import { hexToRgba } from "../../../utils/color-utils";
import { isTouchDevice } from "../../../utils/touch-utils";
import { Avatar } from "../../base/avatar/Avatar";
import { Box } from "../../base/box/Box";
import { Button, ButtonSize, ButtonVariant } from "../../base/button/Button";
import { IconButton } from "../../base/icon-button/IconButton";
import { Image } from "../../base/image/Image";
import { Overlay } from "../../base/overlay/Overlay";
import { ShortenedAddress } from "../../base/shortened-address/ShortenedAddress";
import { SrOnly } from "../../base/sr-only/SrOnly";
import {
  NavigationList,
  NavigationListProps,
} from "../navigation-list/NavigationList";
import { zIndex } from "../../../theme/globals";

export interface UserMenuProps {
  onClose: () => void;
  walletName: string;
  walletAddress: string;
  navigationList: NavigationListProps;
  onSignOut: () => void;
  onCopyAddress?: (walletAddress: string) => void;
  onEnterPlayground?: () => void;
  isVisible: boolean;
  blockchain?: {
    name: string;
    iconUrl: string;
  };
  supportLink?: string;
  onBlockchainIconClick?: () => void;
}

const StyledAddressBox = styled(Box)`
  background-color: ${(props) =>
    hexToRgba(themeGet("colors.white")(props), 0.08)};

  user-select: ${isTouchDevice() && "none"};
`;

const variants = {
  hidden: { opacity: 1, x: "-100%" },
  enter: { opacity: 1, x: 0 },
};

export const UserMenu: React.FunctionComponent<UserMenuProps> = ({
  onClose,
  walletName,
  walletAddress,
  navigationList,
  onSignOut,
  onCopyAddress,
  onEnterPlayground,
  isVisible,
  blockchain,
  supportLink,
  onBlockchainIconClick,
}) => {
  const {
    onTouchStart: onTouchStartPlayground,
    onTouchEnd: onTouchEndPlayground,
  } = useLongPress(onEnterPlayground, 3000);

  return (
    <LightTheme>
      <Overlay
        variants={variants}
        initial="hidden"
        animate="enter"
        exit="hidden"
        transition={{ type: "tween", duration: 0.25, ease: "easeInOut" }}
        isVisible={isVisible}
        zIndex={zIndex.userMenu}
      >
        <Box display="flex" flexDirection="column">
          <Box
            mt="3.5rem"
            display="flex"
            flexShrink="0"
            justifyContent="space-between"
          >
            <IconButton
              color="white"
              hoverColor="blacks.black200"
              onClick={onClose}
            >
              <IoCloseSharp title="close menu" size="2rem" />
            </IconButton>
            {supportLink && (
              <Button
                as="a"
                variant={ButtonVariant.TEXT_INVERSE}
                size={ButtonSize.DEFAULT}
                gridGap="0.5rem"
                mr="-1.5rem"
                href={supportLink}
                fontWeight={400}
              >
                Support
                <IoHelpBuoySharp size="1.5rem" />
              </Button>
            )}
          </Box>
          <Box flexShrink="1" overflow="auto">
            <Box
              mt="3.5rem"
              display="flex"
              justifyContent="center"
              onTouchStart={onTouchStartPlayground}
              onTouchEnd={onTouchEndPlayground}
            >
              <Avatar
                id={walletAddress}
                size="6rem"
                borderWidth="2px"
                borderStyle="solid"
                borderColor="blacks.black200"
              />
            </Box>
            <Box color="white" lineHeight="1.5" textAlign="center" mt="1.5rem">
              {walletName}
            </Box>
            <StyledAddressBox
              p="1rem 0.75rem"
              my="1.5rem"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              gridGap="1rem"
            >
              <Box flex="1" overflow="hidden">
                <ShortenedAddress
                  address={walletAddress}
                  color="blacks.black400"
                  lineHeight="1.5"
                />
              </Box>
              <Box flex="0 0">
                <IconButton
                  color="blacks.black400"
                  onClick={() => onCopyAddress?.(walletAddress)}
                >
                  <SrOnly>Copy Address</SrOnly>
                  <IoCopySharp size="1.5rem" />
                </IconButton>
              </Box>
            </StyledAddressBox>
            {blockchain && (
              <Box
                display="flex"
                alignItems="center"
                gridGap={2}
                justifyContent="center"
                mb={4}
                onClick={onBlockchainIconClick}
                cursor={onBlockchainIconClick ? "pointer" : undefined}
              >
                <Image
                  src={blockchain.iconUrl}
                  flex="0 0"
                  height="1.25rem"
                  width="1.25rem"
                  alt=""
                />
                <Box as="span" fontSize={0} color="blacks.black600">
                  This wallet lives on the {blockchain.name} blockchain
                </Box>
              </Box>
            )}
            <NavigationList {...navigationList} />
            <Box mt="4.5rem" display="flex" justifyContent="center">
              <Button
                variant={ButtonVariant.OUTLINED_INVERSE}
                size={ButtonSize.LARGE}
                onClick={onSignOut}
              >
                Sign out
              </Button>
            </Box>
          </Box>
        </Box>
      </Overlay>
    </LightTheme>
  );
};
