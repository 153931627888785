import React, { PropsWithChildren } from "react";
import { ColorProps, SpaceProps } from "styled-system";
import { Box } from "../../base/box/Box";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { hexToRgba } from "../../../utils/color-utils";

export interface PositionCardFrameProps
  extends SpaceProps,
    Omit<ColorProps, "color"> {
  onClick?: () => void;
  withoutShadow?: boolean;
  disabled?: boolean;
}

const StyledBox = styled(Box)<{ $disabled?: boolean }>`
  ::before {
    content: ${({ $disabled }) => ($disabled ? "''" : "none")};
    position: absolute;
    inset: 0;
    background: ${(props) => hexToRgba(themeGet("colors.white")(props), 0.4)};
  }
`;

export const PositionCardFrame: React.FunctionComponent<
  PropsWithChildren<PositionCardFrameProps>
> = ({ onClick, children, withoutShadow, disabled, ...props }) => {
  return (
    <StyledBox
      position="relative"
      as={onClick ? "button" : "div"}
      borderRadius="0.5rem"
      px={3}
      py="0.75rem"
      display="flex"
      alignItems="center"
      gridGap="1rem"
      overflowX="hidden"
      boxShadow={withoutShadow || disabled ? null : "card"}
      borderWidth={disabled ? "1px" : 0}
      borderStyle="solid"
      borderColor="blacks.black200"
      bg="white"
      width="100%"
      minHeight="4.75rem"
      onClick={onClick}
      cursor={onClick ? "pointer" : undefined}
      $disabled={disabled}
      {...props}
    >
      {children}
    </StyledBox>
  );
};
