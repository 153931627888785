import React from "react";
import styled from "styled-components";
import { ColorProps, color } from "styled-system";
import { Box } from "../../base/box/Box";
import { Image } from "../../base/image/Image";
import { RouterLink } from "../../base/router/RouterLink";
import { H2, P } from "../../base/typography/Typography";

export interface AppBlogTeaserProps {
  imageUrl: string;
  title: string;
  lead: string;
  dateUpdated: Date;
  href: string;
}

const StyledLink = styled(RouterLink)<ColorProps>`
  display: block;

  :hover {
    ${color}
  }
`;

export const AppBlogTeaser = React.forwardRef<
  HTMLDivElement,
  AppBlogTeaserProps
>(({ imageUrl, title, lead, dateUpdated, href }, ref) => {
  const textColor = "peach";

  return (
    <Box p="1.5rem" bg="indigo" ref={ref}>
      <Box textAlign="end" py={2} color={textColor} fontSize={1}>
        Blog
      </Box>
      <StyledLink href={href} color={textColor}>
        <Box display="flex" justifyContent="center" mb="1.5rem" as="span">
          <Image
            height="15rem"
            width="100%"
            src={imageUrl}
            objectFit="contain"
            alt=""
          />
        </Box>
        <H2 color={textColor} mb={2} textAlign="center">
          {title}
        </H2>
      </StyledLink>
      <P mt={0} mb={3} textAlign="center" color={textColor}>
        {lead}
      </P>
      <Box textAlign="center" fontSize={0} color={textColor} mb="1.5rem">
        Updated {new Intl.DateTimeFormat().format(dateUpdated)}
      </Box>
    </Box>
  );
});
