import React, { useMemo } from 'react';
import styled from 'styled-components';
import themeGet from '@styled-system/theme-get';
import {
  IoLogoDiscord,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoTwitter,
} from 'react-icons/io5';
import {useGlobals} from "react-utils";
import { useThemeGet } from '../../../hooks/use-theme-get';
import { Box } from '../../base/box/Box';
import { Logo } from '../../base/logo/Logo';
import { SrOnly } from '../../base/sr-only/SrOnly';
import { Link } from '../../base/typography/Typography';
import { Container } from '../../base/container/Container';

export interface FooterProps {
  linkedInUrl?: string;
  twitterUrl?: string;
  discordUrl?: string;
  instagramUrl?: string;
  logo?: React.ReactNode;
  privacyPolicyPath?: string;
  legalPath?: string;
  termsPath?: string;
}

const StyledFooterLink = styled(Link)`
  :hover {
    color: ${themeGet('colors.white')};
  }
`;

export const Footer: React.FunctionComponent<FooterProps> = ({
  linkedInUrl,
  twitterUrl,
  discordUrl,
  instagramUrl,
  logo: overrideLogo,
  privacyPolicyPath,
  legalPath,
  termsPath,
}) => {
  const { twitterLink,  linkedInLink, discordLink, instagramLink } = useGlobals();
  const iconColor = useThemeGet('colors.white');
  const year = useMemo(() => new Date().getFullYear(), []);

  return (
    <Container $fullWidth={true} bg="black" flex="0 0 auto">
      <Box
        as="footer"
        p={{ _: '3rem 0', sm: '3rem 4rem' }}
        display="flex"
        flexDirection={{ _: 'column', sm: 'row' }}
        alignItems={{ _: 'flex-start', sm: 'center' }}
      >
        <Box order="1">
          {!overrideLogo && <Logo width="7rem" />}
          {overrideLogo && <>{overrideLogo}</>}
        </Box>
        <Box
          display="flex"
          py={{ _: 4, sm: 0 }}
          gridGap="1rem"
          order={{ _: 2, sm: 3 }}
        >
          <a
            href={twitterUrl ?? twitterLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SrOnly>Twitter</SrOnly>
            <IoLogoTwitter size="1.5rem" color={iconColor} />
          </a>
          <a
            href={linkedInUrl ?? linkedInLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SrOnly>LinkedIn</SrOnly>
            <IoLogoLinkedin size="1.5rem" color={iconColor} />
          </a>
          <a
            href={discordUrl ?? discordLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <SrOnly>Discord</SrOnly>
            <IoLogoDiscord size="1.5rem" color={iconColor} />
          </a>
          <a
            href={
              instagramUrl ??
              instagramLink
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <SrOnly>Instagram</SrOnly>
            <IoLogoInstagram size="1.5rem" color={iconColor} />
          </a>
        </Box>
        <Box
          opacity={0.5}
          display="flex"
          flexDirection={{ _: 'column', sm: 'row' }}
          gridGap="0.75rem"
          order={{ _: 3, sm: 2 }}
          flex="1"
          justifyContent="center"
        >
          <Box color="white" lineHeight="1">
            ©{year} NBK Labs
          </Box>
          <StyledFooterLink
            href={privacyPolicyPath ?? '/'}
            color="white"
            lineHeight="1"
          >
            Privacy policy
          </StyledFooterLink>
          <StyledFooterLink
            href={termsPath ?? '/'}
            color="white"
            lineHeight="1"
          >
            Terms & Conditions
          </StyledFooterLink>
        </Box>
      </Box>
    </Container>
  );
};
