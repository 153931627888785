import React, { PropsWithChildren, SyntheticEvent } from "react";
import { useRouterLink } from "./use-router-link";

export type RouterLinkComponent = React.ForwardRefExoticComponent<
  PropsWithChildren<RouterLinkProps> & React.RefAttributes<HTMLAnchorElement>
>;

export interface RouterLinkProps {
  href: string;
  onClick?: (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => void;
  onBeforeNavigation?: (
    e: SyntheticEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  className?: string;
}

export interface RouterLinkProps {}

export const RouterLink: RouterLinkComponent = React.forwardRef(
  ({ children, ...props }, ref) => {
    const InjectedRouterLinkComponent = useRouterLink();

    return (
      <InjectedRouterLinkComponent ref={ref} {...props}>
        {children}
      </InjectedRouterLinkComponent>
    );
  }
);
