import React, { PropsWithChildren } from "react";

import * as prismic from "@prismicio/client";

import { PrismicProvider as UnconfiguredPrismicProvider } from "@prismicio/react";
import Link from "next/link";

// Fill in your repository name
export const repositoryName = "nobank";

const endpoint = prismic.getEndpoint(repositoryName);

export const client = prismic.createClient(endpoint, {
  // If your repo is private, add an access token
  // accessToken: '',

  // This defines how you will structure URL paths in your project.
  // Update the types to match the Custom Types in your project, and edit
  // the paths to match the routing in your project.
  //
  // If you are not using a router in your project, you can change this
  // to an empty array or remove the option entirely.
  routes: [
    {
      type: "blog_post",
      path: "/blog/:uid",
    },
  ],
});

function linkResolver(doc: { type: string }) {
  return "/";
}

export const PrismicProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <UnconfiguredPrismicProvider
      client={client}
      linkResolver={linkResolver}
      internalLinkComponent={({ href, children, ...props }) => (
        <Link href={href} {...props}>
          {children}
        </Link>
      )}
    >
      {/* <PrismicPreview repositoryName={repositoryName}>{children}</PrismicPreview> */}
      {children}
    </UnconfiguredPrismicProvider>
  );
};
