import { useContext } from "react";
import { NativeContext, Native } from "../context/native/NativeProvider";

export function useNative(): Native {
  return useContext(NativeContext);
}

export function useHaptics() {
  const { haptics } = useNative();

  return haptics;
}

export function useIsNativePlatform() {
  const { isNativePlatform } = useNative();

  return isNativePlatform;
}
