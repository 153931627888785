import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { Pacman } from "../../graphics/pacman/Pacman";
import { ProductCard } from "../product-card/ProductCard";

export interface ProductCardPlayProps extends Omit<BoxProps, "color"> {}

const IllustrationContainer = styled(Box)`
  height: 100%;
  overflow: hidden;

  > svg {
    height: 100%;
    width: 100%;
    transform: scale(1.1) translate(1rem, -2rem);
  }
`;

export const ProductCardPlay: React.FunctionComponent<
  PropsWithChildren<ProductCardPlayProps>
> = ({ ...boxProps }) => {
  return (
    <ProductCard
      {...boxProps}
      bg="danger.danger30"
      title="Play"
      subtitle="Index"
      illustration={
        <IllustrationContainer pr="1.5rem" mr="-1.5rem">
          <Pacman withoutAnimation={true} />
        </IllustrationContainer>
      }
      numberPostfix="Coming soon"
      description="Invest into a bundle of GameFi tokens weighted by market cap to easily diversify your portfolio."
    />
  );
};
