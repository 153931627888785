import React from "react";
import { Box, BoxProps } from "../box/Box";
import styled, { keyframes } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { hexToRgba } from "../../../utils/color-utils";

export interface SkeletonProps extends Omit<BoxProps, "color"> {
  isLoading?: boolean;
}

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const StyledBox = styled(Box)<{ $isLoading: boolean }>`
  overflow: hidden;

  &::after {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      ${(props) => hexToRgba(themeGet("colors.white")(props), 0)} 0,
      ${(props) => hexToRgba(themeGet("colors.white")(props), 0.2)} 20%,
      ${(props) => hexToRgba(themeGet("colors.white")(props), 0.5)} 60%,
      ${(props) => hexToRgba(themeGet("colors.white")(props), 0)}
    );
    animation: ${shimmer} 1.5s infinite;
    content: ${({ $isLoading }) => ($isLoading ? "''" : "none")};
  }
`;

export const SkeletonBox: React.FunctionComponent<SkeletonProps> = ({
  isLoading = true,
  ...boxProps
}) => {
  const props: Omit<BoxProps, "color"> & { $isLoading: boolean } = isLoading
    ? {
        $isLoading: true,
        position: "relative",
        bg: "blacks.black100",
        borderRadius: "4px",
      }
    : {
        $isLoading: false,
      };

  return <StyledBox {...props} {...boxProps}></StyledBox>;
};
