import { motion, useAnimation } from "framer-motion";
import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { Box, BoxProps } from "../../base/box/Box";
import { Button, ButtonSize, ButtonVariant } from "../../base/button/Button";
import { Container } from "../../base/container/Container";
import throttle from "lodash.throttle";
import { noop } from "../../../utils/noop";
import useSize from "@react-hook/size";

export interface SwitcherRootProps extends Omit<BoxProps, "color"> {}
export interface SwitcherItemProps {
  bg?: ThemeColor | Color;
  onSelect(): void;

  // used by parent component, don't remove
  buttonText: string;
}

const ScrollContainer = styled(Box)`
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  scroll-snap-type: x mandatory;
`;

const overlapSizePx = 16;

const ButtonContext = createContext<{
  setButtonText: React.Dispatch<React.SetStateAction<string | null>>;
  callbackRef: React.MutableRefObject<() => void>;
} | null>(null);

export const Root: React.FunctionComponent<
  PropsWithChildren<SwitcherRootProps>
> = ({ children, ...boxProps }) => {
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [buttonText, setButtonText] = useState<string | null>(null);
  const callbackRef = useRef<() => void>(noop);
  const context = useMemo(() => ({ setButtonText, callbackRef }), []);
  const [activeChild, setActiveChild] = useState(0);

  useEffect(() => {
    // set button text to the first item even if it's not in the viewport
    let child =
      (Array.isArray(children) ? children[activeChild] : children) || null;

    if (!child && Array.isArray(children)) {
      child = children[children.length - 1];
    }

    if (!child) {
      return;
    }

    const props = React.Children.only(child)?.props as SwitcherItemProps;

    setButtonText(props.buttonText);
    callbackRef.current = props.onSelect;
  }, [children, buttonText, activeChild]);

  const [x] = useSize(scrollContainerRef);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const scrollListener = throttle(() => {
      setActiveChild(Math.round((scrollContainer?.scrollLeft ?? 0) / x));
    }, 200);

    scrollContainer?.addEventListener("scroll", scrollListener);

    return () => {
      scrollContainer?.removeEventListener("scroll", scrollListener);
    };
  }, [x]);

  return (
    <ButtonContext.Provider value={context}>
      <Box overflowX="hidden" position="relative" {...boxProps}>
        <ScrollContainer
          display="flex"
          overflowX="auto"
          ref={scrollContainerRef}
        >
          {children}
        </ScrollContainer>
      </Box>
      <Box
        zIndex="5000"
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        display="flex"
        justifyContent="center"
      >
        <Container
          $safeInsetBottom={true}
          display="flex"
          justifyContent="center"
        >
          <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.LARGE}
            flex="1"
            onClick={callbackRef.current}
          >
            {buttonText}
          </Button>
        </Container>
      </Box>
    </ButtonContext.Provider>
  );
};

const ScrollSnapChild = styled(Box)`
  scroll-snap-align: start;
`;

const JUMPING_INTERVAL_MS = 4000;

export const Item: React.FunctionComponent<
  PropsWithChildren<SwitcherItemProps>
> = ({ bg, children, onSelect }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [jumping, setJumping] = useState(false);
  const context = useContext(ButtonContext);
  const controls = useAnimation();
  const mountedRef = useRef(true);

  useEffect(() => {
    controls.start({
      x: jumping ? -overlapSizePx * 2 : 0,
    });
  }, [controls, jumping]);

  useEffect(() => {
    const scrollListener = throttle(() => {
      if (!mountedRef.current) {
        return;
      }
      // cancel running animation if user scrolls
      controls.stop();
      controls.set({
        x: 0,
      });
    }, 100);

    const parent = ref.current?.parentElement;

    if (parent && context) {
      parent.addEventListener("scroll", scrollListener);
    }

    return () => {
      parent?.removeEventListener("scroll", scrollListener);
    };
  }, [controls, context, onSelect]);

  useEffect(() => {
    let timeout1: NodeJS.Timeout;
    let timeout2: NodeJS.Timeout;

    const jump = () => {
      if (ref.current?.parentElement?.scrollLeft === 0) {
        setJumping(true);
        timeout1 = setTimeout(() => {
          setJumping(false);
        }, 750);
      }
      timeout2 = setTimeout(jump, JUMPING_INTERVAL_MS);
    };

    jump();

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  useEffect(
    () => () => {
      mountedRef.current = false;
    },
    []
  );

  return (
    <ScrollSnapChild
      ref={ref}
      as={motion.div}
      animate={controls}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: jumping ? 10 : 30,
      }}
      bg={bg}
      minWidth={`calc(100% - ${overlapSizePx}px)`}
      px={`${overlapSizePx}px`}
      py="1.5rem"
      pb="8rem"
    >
      {children}
    </ScrollSnapChild>
  );
};
