import { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box } from "../box/Box";
import { Button, ButtonSize, ButtonVariant } from "../button/Button";

interface BaseRadioButtonOption<T> {
  testId?: string;
  text: string;
  value: T;
}

interface BaseRadioButtonOptionWithKey<T> extends BaseRadioButtonOption<T> {
  key: string | number;
}

export type RadioButtonOption<T> = T extends string | number
  ? BaseRadioButtonOption<T>
  : BaseRadioButtonOptionWithKey<T>;

export interface RadioButtonsProps<T> {
  options: RadioButtonOption<T>[];
  value: T;
  onChange: (value: T) => void;
}

const RadioButton = styled(Button)<{ active?: boolean }>`
  box-shadow: 0px;
`;

export function RadioButtons<T>({
  options,
  value,
  onChange,
}: PropsWithChildren<RadioButtonsProps<T>>) {
  return (
    <Box display="flex" gridGap={2}>
      {options.map((option) => (
        <RadioButton
          borderRadius="0.5rem"
          borderColor="blacks.black200"
          size={ButtonSize.SMALL}
          variant={
            value === option.value
              ? ButtonVariant.DEFAULT
              : ButtonVariant.OUTLINED
          }
          onClick={() => onChange(option.value)}
          key={"key" in option ? option.key : option.value}
          data-testid={option.testId}
        >
          {option.text}
        </RadioButton>
      ))}
    </Box>
  );
}
