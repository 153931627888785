import { motion } from "framer-motion";
import React, { PropsWithChildren, useMemo } from "react";
import { ForceTheme } from "../../../theme/ForceTheme";
import { ThemeColor } from "../../../theme/theme";
import { ThemeId } from "../../../theme/theme-id";
import { randomInRange } from "../../../utils/random-in-range";
import { range } from "../../../utils/range";
import { Box } from "../box/Box";
import { Button, ButtonSize, ButtonVariant } from "../button/Button";
import { Container } from "../container/Container";
import { ProgressBar } from "../progress-bar/ProgressBar";
import { Svg } from "../svg/Svg";
import dots from "./dots.svg";
import { securityIllustrations } from "./illustrations/security";
import { tokenIllustrtaions } from "./illustrations/tokens";

export enum TutorialStyle {
  TOKENS = "TOKENS",
  SECURITY = "SECURITY",
}

export interface TutorialContainerProps {
  revealIndex: number;
  completedText: string;
  onComplete: () => void;
  onNext?: () => void;
  hideProgress?: boolean;
  completeDisabled?: boolean;
  tutorialStyle?: TutorialStyle;
  completeButtonVariant?: ButtonVariant;
}

interface TutorialStyleConfig {
  illustrations: string[];
  backgroundColor: ThemeColor;
  theme: ThemeId;
}

const tutorialStyleConfigMap: Record<TutorialStyle, TutorialStyleConfig> = {
  [TutorialStyle.TOKENS]: {
    illustrations: tokenIllustrtaions,
    backgroundColor: "indigo",
    theme: ThemeId.DARK,
  },
  [TutorialStyle.SECURITY]: {
    illustrations: securityIllustrations,
    backgroundColor: "peach",
    theme: ThemeId.LIGHT,
  },
};

const numFloatingIllustrations = 6;

const AnimatedSvg = motion(Svg);

export const TutorialContainer: React.FunctionComponent<
  PropsWithChildren<TutorialContainerProps>
> = ({
  revealIndex,
  completedText,
  onComplete,
  onNext,
  hideProgress,
  completeDisabled,
  tutorialStyle = TutorialStyle.TOKENS,
  completeButtonVariant = ButtonVariant.DEFAULT,
  children,
}) => {
  const totalChildren = React.Children.count(children);

  const { illustrations, backgroundColor, theme } =
    tutorialStyleConfigMap[tutorialStyle];

  const randomTokenSizeAndPosition = useMemo(
    () =>
      range(numFloatingIllustrations).map(() => ({
        x: `${randomInRange(10, 90)}%`,
        y: `${randomInRange(10, 90)}%`,
        size: `${randomInRange(1.5, 3)}rem`,
        float: randomInRange(10, 20),
        scale: randomInRange(1, 1.1),
        delay: randomInRange(0, 5),
        duration: randomInRange(4, 6),
      })),
    []
  );

  return (
    <ForceTheme theme={theme}>
      <Box
        flex="1"
        display="flex"
        flexDirection="column"
        bg={backgroundColor}
        padding="1.5rem"
        height="100%"
        position="relative"
        onClick={() => onNext?.()}
        overflow="hidden"
      >
        {!hideProgress && (
          <ForceTheme
            theme={theme === ThemeId.DARK ? ThemeId.LIGHT : ThemeId.DARK}
          >
            <Box display="flex" justifyContent="center" pt={2} pb={4}>
              <ProgressBar
                total={totalChildren + 1}
                value={revealIndex + 1}
                width="60%"
              />
            </Box>
          </ForceTheme>
        )}

        <Svg
          src={dots}
          position="fixed"
          top="0"
          bottom="0"
          left="0"
          right="0"
          width="100%"
          height="100%"
          objectFit="cover"
        />

        {randomTokenSizeAndPosition.map(
          ({ x, y, size, float, scale, delay, duration }, i) => (
            <Box
              key={i}
              as={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.8 }}
              transition={{
                duration: duration / 2,
                delay,
              }}
              display="inline-block"
            >
              <AnimatedSvg
                animate={{
                  opacity: [1, 0.9, 1],
                  y: [0, float, 0],
                  scale: [1, scale, 1],
                }}
                transition={{
                  duration: duration,
                  repeat: Infinity,
                  delay,
                }}
                src={illustrations[i % illustrations.length]}
                left={x}
                top={y}
                height={size}
                width={size}
                position="absolute"
              />
            </Box>
          )
        )}
        <Container
          zIndex="1"
          display="flex"
          flexDirection="column"
          $noPadding={true}
          overflowY="auto"
        >
          <Box flex="1" overflowY="auto">
            {React.Children.map(children, (child, i) =>
              revealIndex >= i ? <>{child}</> : null
            )}
          </Box>
          <Box display="flex" pt={3} zIndex="1">
            {totalChildren <= revealIndex && (
              <Button
                as={motion.button}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                flex="1"
                variant={completeButtonVariant}
                size={ButtonSize.LARGE}
                onClick={onComplete}
                disabled={completeDisabled}
              >
                {completedText}
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </ForceTheme>
  );
};
