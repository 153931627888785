import React from "react";
import type { IconType } from "react-icons";
import styled from "styled-components";
import { SpaceProps } from "styled-system";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { Box } from "../../base/box/Box";
import { Button, ButtonSize, ButtonVariant } from "../../base/button/Button";
import { Image } from "../../base/image/Image";
import { SrOnly } from "../../base/sr-only/SrOnly";
import { P } from "../../base/typography/Typography";
import { PositionCardFrame } from "./PositionCardFrame";

export interface PositionCardProps extends SpaceProps {
  topLeft: React.ReactNode;
  bottomLeft?: React.ReactNode;
  topRight?: React.ReactNode;
  bottomRight?: React.ReactNode;
  bottomRightColor?: Color | ThemeColor;
  iconRight?: IconType | IconType[];
  iconRightSize?: string;
  image?: string | React.ReactNode;
  imageAlt?: string;
  onClick?: () => void;
  disabled?: boolean;
  backgroundColor?: Color | ThemeColor;
  withoutShadow?: boolean;
  action?:
    | {
        onClick(): void;
        icon: IconType;
        alt: string;
        isLoading?: boolean;
      }
    | React.ReactNode;
}

const RoundButton = styled(Button)`
  padding: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
`;

export const PositionCard: React.FunctionComponent<PositionCardProps> = ({
  disabled,
  topLeft,
  bottomLeft,
  topRight,
  bottomRight,
  bottomRightColor,
  image,
  imageAlt,
  onClick,
  backgroundColor,
  withoutShadow,
  action,
  iconRight: IconRight,
  iconRightSize,
  ...props
}) => {
  return (
    <PositionCardFrame
      onClick={disabled ? undefined : onClick}
      bg={backgroundColor ?? "white"}
      withoutShadow={withoutShadow}
      disabled={disabled}
      {...props}
    >
      {typeof image === "string" && (
        <Image
          src={image}
          alt={imageAlt ?? ""}
          width="2rem"
          flex="0 0 auto"
          objectFit="contain"
        />
      )}
      {image && typeof image !== "string" && <Box width="2rem">{image}</Box>}
      <Box
        as="span"
        flex="1 1 auto"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        overflowX="hidden"
        whiteSpace="nowrap"
        textAlign="start"
      >
        <P
          fontWeight="600"
          color="blacks.black800"
          as="span"
          overflowX="hidden"
          textOverflow="ellipsis"
          fontSize={2}
        >
          {topLeft}
        </P>
        <P
          color="blacks.black800"
          as="span"
          overflow="hidden"
          textOverflow="ellipsis"
          fontSize={1}
          overflowY="visible"
        >
          {bottomLeft}
        </P>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="end"
        whiteSpace="nowrap"
        flex="0 0 auto"
      >
        <P color="blacks.black800" as="span" fontSize={2}>
          {topRight}
        </P>
        <P color={bottomRightColor ?? "blacks.black800"} as="span" fontSize={1}>
          {bottomRight}
        </P>
      </Box>
      {action && typeof action === "object" && "onClick" in action && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex="0 0 auto"
        >
          <RoundButton
            isLoading={action.isLoading}
            variant={ButtonVariant.DEFAULT}
            size={ButtonSize.DEFAULT}
            onClick={(e: React.SyntheticEvent) => {
              e.stopPropagation();
              action.onClick();
            }}
          >
            <SrOnly>{action.alt}</SrOnly>
            <action.icon size="1rem" />
          </RoundButton>
        </Box>
      )}
      {action && React.isValidElement(action) && <>{action}</>}{" "}
      {Array.isArray(IconRight) ? (
        <Box display="flex" flexDirection="column" justifyContent="center">
          {IconRight.map((Icon, index) => (
            <Icon key={index} size={iconRightSize ?? "1rem"} />
          ))}
        </Box>
      ) : (
        IconRight && (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            flex="0 0 auto"
          >
            <IconRight size={iconRightSize ?? "1rem"} />
          </Box>
        )
      )}
    </PositionCardFrame>
  );
};
