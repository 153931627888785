import { GlobalsProvider } from 'react-utils';

import { HtmlHead } from 'shared/components/html-head';

import { RootComponent } from 'shared/components/root-component';

import { ConfiguredNobankThemeProvider } from 'shared/contexts/configured-nobank-theme-provider';

import { PrismicProvider } from 'shared/contexts/prismic-provider';

import { RootStyleProvider } from 'shared/contexts/root-style-context';

import { AppPropsWithLayout } from 'shared/util-types/next-types';
import * as globals from '../src/shared/globals';

// DO NOT REMOVE! Required for next getConfig() to work
export const getInitialProps = async () => {
  return {};
};

const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  return (
    <>
      <GlobalsProvider globals={globals}>
        <RootStyleProvider>
          <PrismicProvider>
            <ConfiguredNobankThemeProvider>
              <HtmlHead />
              <RootComponent>{getLayout(<Component {...pageProps} />)}</RootComponent>
            </ConfiguredNobankThemeProvider>
          </PrismicProvider>
        </RootStyleProvider>
      </GlobalsProvider>
    </>
  );
};

export default MyApp;
