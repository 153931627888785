export const isMobileSafari =
  typeof window !== "undefined" &&
  (window.navigator.userAgent.match(/iPad/i) ||
    window.navigator.userAgent.match(/iPhone/i));

export const userLocale =
  typeof window === "undefined"
    ? "en-US"
    : navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
