import React, { PropsWithChildren, useMemo, useRef } from "react";
import { useStoryContainer } from "../../../hooks/use-story-container";
import { Box, BoxProps } from "../box/Box";

export interface StoryContainerProps extends Omit<BoxProps, "color"> {
  onContinue?: () => void;
  onPause?: () => void;
  onNext?: () => void;
  onPrevious?: () => void;
}

export const StoryContainer: React.FunctionComponent<
  PropsWithChildren<StoryContainerProps>
> = ({ onContinue, onPause, onNext, onPrevious, children, ...boxProps }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const handlers = useMemo(
    () => ({
      onPause,
      onNext,
      onPrevious,
      onContinue,
    }),
    [onPause, onNext, onPrevious, onContinue]
  );

  useStoryContainer(ref, handlers);

  return (
    <Box {...boxProps} ref={ref}>
      {children}
    </Box>
  );
};
