import themeGet from "@styled-system/theme-get";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import {
  ScrollDirection,
  useScrollDirection,
} from "../../../../hooks/use-scroll-direction";
import { Box } from "../../../base/box/Box";
import { Button, ButtonVariant, ButtonSize } from "../../../base/button/Button";
import { Container } from "../../../base/container/Container";
import { Logo } from "../../../base/logo/Logo";
import { RouterLink } from "../../../base/router/RouterLink";
import { Link } from "../../../base/typography/Typography";
import { BaseHeaderProps } from "../header-commons";
import { motion } from "framer-motion";
import { zIndex } from "../../../../theme/globals";

const StyledLink = styled(Link)<{ active?: boolean }>`
  &:hover {
    color: ${themeGet("colors.white")};
  }

  :marker {
    display: none;
  }
`;

const StyledListItem = styled(Box)`
  list-style-type: none;
`;

const overlapTop = "1rem";

export const HeaderDesktop: React.FunctionComponent<
  PropsWithChildren<BaseHeaderProps>
> = ({ pages, logo: overrideLogo, actions, bg, sticky }) => {
  const scrollDirection = useScrollDirection();

  return (
    <Box
      bg={bg ?? "black"}
      position="fixed"
      zIndex={zIndex.overlay}
      top={`-${overlapTop}`}
      left="0"
      right="0"
      pb="1.25rem"
      pt={`calc(${overlapTop} + 1.25rem)`}
      display={{ _: "none", sm: "block" }}
      as={motion.div}
      animate={
        scrollDirection === ScrollDirection.DOWN && !sticky
          ? "hidden"
          : "visible"
      }
      variants={{
        visible: {
          y: 0,
        },
        hidden: {
          y: "-120%",
        },
      }}
    >
      <Container display="flex" alignItems="center">
        <Box flex="0 0">
          <RouterLink href="/">
            {!overrideLogo && <Logo height="2rem" />}
            {overrideLogo && <>{overrideLogo}</>}
          </RouterLink>
        </Box>

        <Box
          flex="1"
          as="ul"
          display="flex"
          gridGap="1.5rem"
          my={0}
          justifyContent="center"
        >
          {pages.map((page) => {
            return (
              <StyledListItem key={page.to} as="li">
                <StyledLink
                  color={page.active ? "white" : "blacks.black200"}
                  href={page.to}
                  fontWeight="600"
                >
                  {page.text}
                </StyledLink>
              </StyledListItem>
            );
          })}
        </Box>

        <Box flex="0 0" display="flex" justifyContent="flex-end">
          {actions.map(({ key, buttonProps, text }) => (
            <Button
              as={RouterLink}
              flex="1"
              key={key}
              variant={ButtonVariant.DEFAULT_INVERSE}
              size={ButtonSize.LARGE}
              {...buttonProps}
            >
              {text}
            </Button>
          ))}
        </Box>
      </Container>
    </Box>
  );
};
