import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
} from "react";

export interface AnalyticsContextType {
  track(event: string, params?: { [k: string]: string }): Promise<void>;
  page({ name }: { name: string }): Promise<void>;
  identify(userId: string): Promise<void>;
  setUserProperty({
    name,
    value,
  }: {
    name: string;
    value: string;
  }): Promise<void>;
}

interface AnalyticsProviderProps {
  analytics?: AnalyticsContextType;
}

const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

const noop = () => Promise.resolve();

const analyticsStub: AnalyticsContextType = {
  track: noop,
  page: noop,
  identify: noop,
  setUserProperty: noop,
};

export const AnalyticsProvider: React.FunctionComponent<
  PropsWithChildren<AnalyticsProviderProps>
> = ({ analytics, children }) => {
  useEffect(() => {
    if (analytics === analyticsStub) {
      console.debug("Analytics not set up, using analytics stub");
    }
  }, [analytics]);

  return (
    <AnalyticsContext.Provider value={analytics ?? analyticsStub}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const value = useContext(AnalyticsContext);

  if (!value) {
    throw new Error("useAnalytics must be used inside an AnalyticsProvider");
  }

  return value;
};
