import React, { PropsWithChildren } from "react";
import type { IconType } from "react-icons";
import { useThemeGet } from "../../../hooks/use-theme-get";
import { ThemeColor } from "../../../theme/theme";
import { hexToRgba } from "../../../utils/color-utils";
import { Box, BoxProps } from "../box/Box";

export enum NoticeVariant {
  INFO = "INFO",
  DANGER = "DANGER",
  RISKY = "RISKY",
  EXPERIMENTAL = "EXPERIMENTAL",
  WARNING = "WARNING",
}

export enum NoticeSize {
  DEFAULT = "DEFAULT",
  LARGE = "LARGE",
}

export interface NoticeProps extends Omit<BoxProps, "color"> {
  icon: IconType;
  variant?: NoticeVariant;
  size?: NoticeSize;
  color?: string | ThemeColor;
}

export const Notice: React.FunctionComponent<
  PropsWithChildren<NoticeProps>
> = ({
  icon: Icon,
  variant = NoticeVariant.INFO,
  size = NoticeSize.DEFAULT,
  color,
  children,
  ...boxProps
}) => {
  const black = useThemeGet("colors.black");
  const danger = useThemeGet("colors.danger.danger50");

  const bgMap: Record<NoticeVariant, string> = {
    [NoticeVariant.DANGER]: hexToRgba(danger, 0.15),
    [NoticeVariant.WARNING]: "warning.warning20",
    [NoticeVariant.INFO]: "blacks.black100",
    [NoticeVariant.RISKY]: "warning.warning40",
    [NoticeVariant.EXPERIMENTAL]: "experimental.experimental20",
  };

  const bg = bgMap[variant];
  const effectiveColor = color ?? black;

  return (
    <Box borderRadius="0.5rem" bg={bg} display="flex" p={3} {...boxProps}>
      <Box flex="0 0 auto" display="flex" alignItems="center" mr={2}>
        <Icon
          color={effectiveColor}
          size={size === NoticeSize.LARGE ? "1.25rem" : undefined}
        />
      </Box>
      <Box
        flex="1"
        color={effectiveColor}
        fontSize={size === NoticeSize.LARGE ? 1 : 0}
        display="flex"
        alignItems="center"
      >
        {children}
      </Box>
    </Box>
  );
};
