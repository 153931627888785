import React, { createContext, PropsWithChildren, useContext } from "react";

export interface GlobalsContextType {
  supportEmail: string;
  discordLink: string;
  nobankAppStoreLink: string;
  nobankPlayStoreLink: string;
  instagramLink: string;
  linkedInLink: string;
  twitterLink: string;
  baseCurrency: string;
}

interface GlobalsProviderProps {
  globals: GlobalsContextType;
}

const GlobalsContext = createContext<GlobalsContextType | null>(null);

export const GlobalsProvider: React.FunctionComponent<
  PropsWithChildren<GlobalsProviderProps>
> = ({ globals, children }) => {
  return (
    <GlobalsContext.Provider value={globals}>
      {children}
    </GlobalsContext.Provider>
  );
};

export const useGlobals = () => {
  const value = useContext(GlobalsContext);

  if (!value) {
    throw new Error("useGlobals must be used inside a GlobalsProvider");
  }

  return value;
};
