import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from "styled-system";
import {
  backdropFilter,
  BackdropFilterProps,
  cursor,
  CursorProps,
  pointerEvents,
  PointerEventsProps,
  textDecoration,
  TextDecorationProps,
  textOverflow,
  TextOverflowProps,
  whiteSpace,
  WhiteSpaceProps,
  wordBreak,
  WordBreakProps,
} from "../../../theme/styled-system-custom";
import { shouldForwardProp } from "../../../utils/should-forward-prop";

export type BoxProps = SpaceProps &
  ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  TypographyProps &
  BorderProps &
  PositionProps &
  ShadowProps &
  TextOverflowProps &
  PointerEventsProps &
  WhiteSpaceProps &
  WordBreakProps &
  TextDecorationProps &
  BackdropFilterProps &
  CursorProps;

export const boxStyles = compose(
  color,
  space,
  flexbox,
  grid,
  layout,
  position,
  typography,
  border,
  shadow,
  textOverflow,
  whiteSpace,
  pointerEvents,
  wordBreak,
  cursor,
  textDecoration,
  backdropFilter
);

export const Box = styled.div.withConfig({
  shouldForwardProp,
})<BoxProps>`
  font-family: ${themeGet("fonts.body")};

  color: ${themeGet("colors.black")};

  ${boxStyles}
`;
