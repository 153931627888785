import React from "react";
import { HeadProps } from "./head-props";
import { HeadSvg } from "./HeadSvg";

export const Head4: React.FunctionComponent<HeadProps> = ({
  hairColor,
  noseColor,
  skinColor,
}) => {
  return (
    <HeadSvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4234_40508"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4234_40508)">
        <path
          d="M163.664 387.409C146.562 399.701 122.869 415.378 118.237 419.119C113.427 422.86 113.605 428.382 114.496 430.698C115.565 433.192 118.059 435.686 124.294 435.686C130.529 435.686 168.296 433.192 168.296 433.192"
          fill={noseColor}
        />
        <path
          d="M158.32 391.15C158.32 402.373 156.004 413.062 152.797 419.297C149.591 425.888 125.897 435.33 125.897 435.33L172.215 431.945L164.911 386.519L158.32 391.15Z"
          fill="#221C16"
        />
        <path
          d="M163.664 387.409C146.562 399.701 122.869 415.378 118.237 419.119C113.427 422.86 113.605 428.382 114.496 430.698C115.565 433.192 118.059 435.686 124.294 435.686C130.529 435.686 168.296 433.192 168.296 433.192"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M246.857 532.24C233.318 534.912 219.245 532.596 207.488 525.292C195.73 517.989 187.179 506.587 183.438 493.405C180.766 483.785 172.928 446.196 170.255 432.658C169.543 429.095 168.83 425.71 168.118 422.147C167.94 421.256 167.761 420.366 167.583 419.475C166.514 414.131 165.624 408.786 164.733 403.264C164.733 402.729 164.555 402.195 164.555 401.661C163.842 397.029 163.13 392.397 162.417 387.943C162.417 387.765 162.239 387.587 162.239 387.231C162.061 386.34 157.073 356.234 158.142 333.253V332.719C158.854 325.593 160.458 318.824 162.952 312.054C173.462 283.551 199.471 260.57 229.043 254.157C240.088 251.841 252.024 251.663 262 256.829C276.607 264.133 284.09 280.879 286.227 297.09C288.365 313.301 286.227 329.69 286.94 345.901C287.296 354.809 287.831 368.526 292.462 376.008C297.094 383.49 309.92 385.093 318.828 384.202C327.735 383.49 333.079 371.554 336.642 364.428C337.355 363.003 338.245 361.578 339.136 360.153C342.521 355.343 347.687 351.246 353.744 351.068C362.829 350.533 370.311 358.55 374.409 366.744C381 380.461 382.247 395.96 377.971 410.568C376.19 416.446 373.34 421.969 369.42 426.779C369.42 426.779 365.501 429.629 365.323 442.634C365.323 455.46 370.133 458.31 370.133 458.31C376.546 462.229 383.494 465.08 390.976 466.683C409.147 470.424 435.512 468.643 453.861 463.298C457.245 491.98 467.043 572.144 470.428 599.935C470.428 599.935 304.932 599.935 303.329 599.935L298.341 558.784L294.422 518.523C288.365 521.195 256.477 530.28 246.857 532.24Z"
          fill={skinColor}
        />
        <path
          d="M337.354 501.422C336.463 516.207 325.062 529.925 314.195 538.654C303.329 547.383 303.329 565.91 303.151 578.736H301.013L294.6 518.523L337.354 501.422Z"
          fill="#221C16"
        />
        <path
          d="M453.861 464.011L470.25 600.648"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
        />
        <path
          d="M303.329 600.648L294.6 518.523C288.365 520.661 256.655 529.568 246.857 531.706C233.318 534.378 219.245 532.062 207.488 524.758C195.73 517.455 187.357 507.657 182.547 489.308C177.737 471.137 156.004 361.408 158.142 331.48C158.142 309.365 175.956 286.424 175.956 286.424"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M280.883 522.442C303.685 516.741 334.682 501.065 334.682 501.065"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M362.295 377.968L340.74 397.92"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M185.042 491.089C185.042 491.089 232.428 453.323 236.169 449.938C239.91 446.553 245.076 439.962 243.295 432.302C240.979 423.394 231.181 420.01 221.739 423.216C212.12 426.601 176.669 448.869 176.669 448.869C179.519 464.546 181.479 475.769 185.042 491.089Z"
          fill="white"
        />
        <path
          d="M175.243 449.76C175.243 449.76 212.297 426.779 221.917 423.216C231.359 419.832 240.979 423.216 243.473 432.302C245.61 439.962 240.088 446.375 236.347 449.938C232.606 453.323 185.219 491.089 185.219 491.089"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M230.186 404.458C240.995 401.855 247.647 390.984 245.044 380.175C242.442 369.366 231.57 362.714 220.761 365.317C209.953 367.919 203.301 378.791 205.903 389.6C208.506 400.409 219.378 407.061 230.186 404.458Z"
          fill="#221C16"
        />
        <path
          d="M208.2 375.83C207.844 375.83 207.844 376.008 207.488 376.008C205.528 380.105 204.816 384.915 205.884 389.725C206.241 390.972 206.597 391.863 206.953 392.932C208.735 393.644 210.516 393.822 212.476 393.288C217.286 392.219 220.314 387.231 219.067 382.421C217.82 377.611 212.832 374.583 208.2 375.83Z"
          fill="white"
        />
        <path
          d="M185.042 390.082C195.552 377.255 210.16 367.635 227.618 363.538C241.869 360.153 256.121 360.688 269.125 364.785"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M379.04 37C503.563 37.1781 604.57 133.376 605.817 252.376C605.817 253.267 605.817 254.158 605.817 255.048C605.639 327.909 571.97 395.782 514.786 435.152C495.724 448.335 474.525 457.954 452.079 463.833C433.73 469.177 407.365 470.959 389.194 467.218C381.89 465.615 374.764 462.764 368.351 458.845C364.076 452.788 362.473 448.156 362.473 443.168C362.473 438.18 364.076 434.261 367.461 427.314C371.38 422.504 374.23 416.981 376.011 411.102C380.287 396.495 379.04 380.996 372.449 367.279C368.351 359.084 361.047 351.068 351.784 351.602C345.905 351.959 340.561 355.878 337.176 360.688C336.285 362.113 335.395 363.538 334.682 364.963C331.119 372.089 325.775 383.846 316.868 384.737C308.139 385.45 295.134 384.025 290.502 376.542C285.871 369.06 285.514 355.165 284.98 346.436C284.267 330.225 286.405 313.658 284.267 297.625C282.13 281.414 274.469 264.668 260.04 257.364C250.064 252.198 237.95 252.376 227.083 254.692C197.511 260.749 171.68 284.086 160.992 312.411C154.757 293.706 151.55 274.11 151.372 254.514C151.55 134.267 253.448 37 379.04 37Z"
          fill={hairColor}
        />
        <path
          d="M377.08 415.378C374.586 425.889 385.988 439.249 402.021 439.962C432.127 441.387 470.072 433.549 493.052 420.901C533.847 398.633 578.561 354.453 601.186 284.264L605.639 282.126C605.639 282.126 590.497 397.742 499.109 444.772C420.726 485.032 372.271 462.052 372.271 462.052C372.271 462.052 363.007 459.558 363.007 442.99C363.363 426.779 377.08 415.378 377.08 415.378Z"
          fill="#221C16"
        />
        <path
          d="M284.98 303.504C286.762 333.966 295.847 357.125 305.823 359.797C315.799 362.469 332.01 365.498 340.027 357.125C340.027 357.125 327.2 382.956 316.868 384.559C306.535 386.162 294.778 383.312 290.503 376.365C286.227 369.417 284.624 350.177 284.624 340.914C284.624 331.65 284.98 303.504 284.98 303.504Z"
          fill="#221C16"
        />
        <path
          d="M188.96 270.19C174.352 285.689 160.992 312.411 160.992 312.411C160.992 312.411 154.044 286.758 153.153 277.851C159.567 291.39 188.96 270.19 188.96 270.19Z"
          fill="#221C16"
        />
        <path
          d="M379.04 37C503.563 37.1781 604.57 133.376 605.817 252.376C605.817 253.267 605.817 254.158 605.817 255.048C605.639 327.909 571.97 395.782 514.786 435.152C495.724 448.335 474.525 457.954 452.079 463.833C433.73 469.177 405.761 469.177 389.194 467.218C378.684 465.971 373.339 463.299 368.351 458.845C363.541 454.391 363.007 449.225 362.473 443.168C361.938 437.111 364.61 431.589 367.461 427.314C370.133 423.038 374.23 416.981 376.011 411.102C380.287 396.495 379.04 380.996 372.449 367.279C368.351 359.084 361.047 351.068 351.784 351.602C345.905 351.959 340.561 355.878 337.176 360.688C336.285 362.113 335.395 363.538 334.682 364.963C334.682 364.963 325.775 384.025 316.868 384.737C308.139 385.45 295.134 384.025 290.502 376.542C285.871 369.06 285.514 355.165 284.98 346.436C284.267 330.225 286.405 313.658 284.267 297.625C282.13 281.414 274.469 264.668 260.04 257.364C250.064 252.198 237.95 252.376 227.083 254.692C197.511 260.749 171.68 284.086 160.992 312.411C154.757 293.706 151.55 274.11 151.372 254.514C151.55 134.267 253.448 37 379.04 37Z"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </HeadSvg>
  );
};
