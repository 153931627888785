import _ from "lodash";
import { Color } from "./types";

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (_, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    throw new Error(`Failed to convert hex string "${hex}" to rgb`);
  }

  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
}

function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex({ r, g, b }: { r: number; g: number; b: number }): Color {
  return ("#" +
    componentToHex(r) +
    componentToHex(g) +
    componentToHex(b)) as Color;
}

export function hexToRgba(hex: string, alpha = 1): Color {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r},${g},${b},${alpha})`;
}

export function lighten(color: string, value: number) {
  const { r, g, b } = hexToRgb(color);

  const [ra, ga, ba] = [r, g, b].map((c) => Math.round((255 - c) * value));

  return rgbToHex({
    r: r + ra,
    g: g + ga,
    b: b + ba,
  });
}

export function darken(color: string, value: number) {
  const { r, g, b } = hexToRgb(color);

  const [ra, ga, ba] = [r, g, b].map((c) => Math.round(c * (1 - value)));

  return rgbToHex({
    r: ra,
    g: ga,
    b: ba,
  });
}
