import useSize from "@react-hook/size";
import themeGet from "@styled-system/theme-get";
import React, { useRef } from "react";
import styled from "styled-components";
import { Box } from "../box/Box";

export interface ProgressCircleProps {
  value: number;
  total: number;
  strokeWidth?: number;
  size?: string;
}

const StyledDonut = styled.svg<{ $size: string }>`
  min-width: 100%;
  min-height: 100%;
`;

const StyledCheck = styled.svg<{ $opacity: number }>`
  width: 1.25rem;
  height: 1.25rem;
  opacity: ${({ $opacity }) => $opacity};
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCircle = styled.circle<{ $size: number; $strokeWidth: number }>`
  fill: transparent;
  stroke: ${themeGet("colors.blacks.black200")};
  stroke-width: ${({ $strokeWidth }) => $strokeWidth};
  vector-effect: non-scaling-stroke;
  transform: rotate(-90deg);
  transform-origin: center center;
  stroke-dasharray: ${({ $size, $strokeWidth }) =>
    ($size - $strokeWidth) * Math.PI};
`;

const StyledInnerCircle = styled(StyledCircle)<{
  $size: number;
  $percentage: number;
}>`
  stroke: ${themeGet("colors.black")};

  stroke-dashoffset: ${({ $size, $percentage }) =>
    $size * Math.PI * ($size ? 1 - $percentage : 0)};
`;

export const ProgressCircle: React.FunctionComponent<ProgressCircleProps> = ({
  value,
  total,
  strokeWidth = 2,
  size = "1.5rem",
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const isComplete = value >= total;

  const [x] = useSize(ref);

  return (
    <Box width={size} height={size} ref={ref} position="relative">
      <StyledCheck
        $opacity={isComplete ? 1 : 0}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9824 5.67572C15.2996 5.94214 15.3407 6.41523 15.0743 6.73239L8.77428 14.2324C8.63465 14.3986 8.42986 14.4962 8.21281 14.4999C7.99576 14.5036 7.78775 14.4131 7.64253 14.2517L4.94253 11.2517C4.66544 10.9438 4.69039 10.4696 4.99828 10.1925C5.30616 9.91544 5.78038 9.94039 6.05747 10.2483L8.18048 12.6072L13.9257 5.76761C14.1921 5.45044 14.6652 5.4093 14.9824 5.67572Z"
          fill="currentcolor"
        />
      </StyledCheck>
      <StyledDonut display="flex" $size={size} viewBox={`0 0 ${x} ${x}`}>
        <StyledCircle
          $strokeWidth={strokeWidth}
          $size={x}
          cx={`${x / 2}`}
          cy={`${x / 2}`}
          r={Math.max(0, x / 2 - strokeWidth)}
        />
        <StyledInnerCircle
          $strokeWidth={strokeWidth}
          $size={x}
          $percentage={value / total}
          cx={`${x / 2}`}
          cy={`${x / 2}`}
          r={Math.max(0, x / 2 - strokeWidth)}
        />
      </StyledDonut>
    </Box>
  );
};
