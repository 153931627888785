import { CSSProperties } from "react";
import { system } from "styled-system";

export interface WhiteSpaceProps {
  whiteSpace?: CSSProperties["whiteSpace"];
}
export const whiteSpace = system({
  whiteSpace: true,
});

export interface TextOverflowProps {
  textOverflow?: CSSProperties["textOverflow"];
}

export const textOverflow = system({
  textOverflow: true,
});

export interface PointerEventsProps {
  pointerEvents?: CSSProperties["pointerEvents"];
}

export const pointerEvents = system({
  pointerEvents: true,
});

export interface WordBreakProps {
  wordBreak?: CSSProperties["wordBreak"];
}

export const wordBreak = system({
  wordBreak: true,
});

export interface CursorProps {
  cursor?: CSSProperties["cursor"];
}

export const cursor = system({
  cursor: true,
});

export interface TextDecorationProps {
  textDecoration?: CSSProperties["textDecoration"];
  textUnderlineOffset?: CSSProperties["textUnderlineOffset"];
}

export const textDecoration = system({
  textDecoration: true,
  textUnderlineOffset: true,
});

export interface ObjectProps {
  objectFit?: CSSProperties["objectFit"];
  objectPosition?: CSSProperties["objectPosition"];
}

export const object = system({
  objectFit: true,
  objectPosition: true,
});

export interface BackdropFilterProps {
  backdropFilter?: CSSProperties["backdropFilter"];
}

export const backdropFilter = system({
  backdropFilter: true,
});
