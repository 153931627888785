import React from "react";
import { ThemeColor } from "../../../theme/theme";
import { BottomSheet } from "../../base/bottom-sheet/BottomSheet";
import { Box } from "../../base/box/Box";
import { Container } from "../../base/container/Container";
import { Image } from "../../base/image/Image";
import { H4, P } from "../../base/typography/Typography";
import styled from "styled-components";
import { DarkTheme, LightTheme } from "../../../theme/ForceTheme";
import { Color } from "../../../utils/types";

export interface NotificationBottomSheetProps {
  open: boolean;
  onDismiss: () => void;
  onOpened?: () => void;
  backgroundColor?: Color | ThemeColor;
  illustration?: string | React.ReactNode;
  title: string;
  body: string;
  action?: React.ReactNode;
}

const StyledBottomSheet = styled(BottomSheet)`
  [data-rsbs-footer] {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`;

export const NotificationBottomSheet: React.FunctionComponent<
  NotificationBottomSheetProps
> = ({
  open,
  onDismiss,
  onOpened,
  backgroundColor,
  illustration,
  title,
  body,
  action,
}) => {
  return (
    <DarkTheme>
      <StyledBottomSheet
        onSpringEnd={() => {
          if (open) {
            onOpened?.();
          }
        }}
        open={open}
        onDismiss={onDismiss}
        backgroundColor={backgroundColor}
        initialFocusRef={false}
        footer={
          action ? (
            <LightTheme>
              <Container $noPadding={true}>
                <Box display="flex" justifyContent="center">
                  {action}
                </Box>
              </Container>
            </LightTheme>
          ) : undefined
        }
      >
        <LightTheme>
          <Container textAlign="center" mb={action ? 0 : 4}>
            <Box display="flex" justifyContent="center">
              {typeof illustration === "string" && (
                <Image
                  src={illustration}
                  alt=""
                  height="7.5rem"
                  width="100%"
                  objectFit="contain"
                />
              )}
              {typeof illustration !== "string" && <>{illustration}</>}
            </Box>
            <H4 mt="1.5rem">{title}</H4>
            <P mt="1.5rem" fontSize={1}>
              {body}
            </P>
          </Container>
        </LightTheme>
      </StyledBottomSheet>
    </DarkTheme>
  );
};
