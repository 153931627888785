import React, { PropsWithChildren } from "react";
import {
  ToastContainer as ToastifyToastContainer,
  ToastContainerProps as ToastifyToastContainerProps,
} from "react-toastify";
import styled from "styled-components";
import { zIndex } from "../../../theme/globals";

export interface ToastContainerProps extends ToastifyToastContainerProps {}

/*
ToastContainer from react-toastify is missing the children prop
*/
type FixedToastifyToastContainerType = React.ForwardRefExoticComponent<
  PropsWithChildren<ToastifyToastContainerProps> &
    React.RefAttributes<HTMLDivElement>
>;
const FixedToastifyToastContainer =
  ToastifyToastContainer as FixedToastifyToastContainerType;

const StyledToastContainer = styled(FixedToastifyToastContainer)`
  --toastify-toast-width: min(max-content, 100%);

  .Toastify__toast {
    min-height: 0;
    margin-bottom: 0;
    background-color: transparent;
    padding: 0;
    box-shadow: none;
    display: flex;
  }

  .Toastify__close-button {
    display: none;
  }

  &.Toastify__toast-container {
    z-index: ${zIndex.toast};
    align-items: stretch;
  }

  &.Toastify__toast-container--bottom-center {
    bottom: calc(1rem + env(safe-area-inset-bottom, 0));
  }

  &.Toastify__toast-container--top-center {
    top: calc(1rem + env(safe-area-inset-top, 0));
  }
`;
export const ToastContainer: React.FunctionComponent<
  PropsWithChildren<ToastContainerProps>
> = ({ children, ...props }) => {
  const { theme, ...propsWithoutTheme } = props;
  return (
    <StyledToastContainer
      hideProgressBar={true}
      position="bottom-center"
      {...propsWithoutTheme}
    >
      {children}
    </StyledToastContainer>
  );
};
