import { lock, unlock } from "tua-body-scroll-lock";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { zIndex } from "../../../theme/globals";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { BoxProps } from "../box/Box";
import { Container } from "../container/Container";

export interface OverlayProps
  extends Omit<BoxProps, "color">,
    HTMLMotionProps<"div"> {
  offsetTop?: string;
  isVisible: boolean;
  backgroundColor?: ThemeColor | Color;
}

const StyledContainer = styled(Container)`
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Overlay: React.FunctionComponent<OverlayProps> = ({
  offsetTop,
  children,
  isVisible,
  backgroundColor,
  ...props
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (isVisible) {
      lock(ref.current);
    } else {
      unlock(ref.current);
    }

    return () => unlock(ref.current);
  }, [isVisible]);

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <StyledContainer
          ref={ref}
          as={motion.div}
          $fullWidth={true}
          $safeInsetBottom={true}
          bg={backgroundColor ?? "black"}
          position="fixed"
          left={0}
          right={0}
          bottom={0}
          top={offsetTop ?? 0}
          zIndex={zIndex.overlay}
          {...props}
        >
          {children}
        </StyledContainer>
      )}
    </AnimatePresence>
  );
};
