import React, { ForwardedRef } from "react";
import styled from "styled-components";
import { Image, ImageProps } from "../image/Image";

export interface SvgProps extends Omit<ImageProps, "color"> {
  src?: string;
}

const StyledImage = styled(Image)`
  > object {
    width: 100%;
    height: 100%;
  }
`;

export const Svg = React.forwardRef<HTMLImageElement, SvgProps>(
  ({ src, width, height, ...imageProps }, ref) => {
    if (!src) {
      return null;
    }

    // hack to make the svg work with all kinds of image srcs
    const effectiveSrc = "src" in (src as any) ? (src as any).src : src;

    if (effectiveSrc.startsWith?.("<svg")) {
      return (
        <StyledImage
          ref={ref as ForwardedRef<HTMLObjectElement>}
          as="object"
          width={height as any}
          height={height as any}
          {...imageProps}
          dangerouslySetInnerHTML={{ __html: effectiveSrc }}
        ></StyledImage>
      );
    }

    return (
      // eslint-disable-next-line jsx-a11y/alt-text
      <Image
        ref={ref}
        width={height as any}
        height={height as any}
        {...imageProps}
        src={effectiveSrc}
      />
    );
  }
);
