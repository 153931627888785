import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import {
  color,
  ColorProps,
  compose,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from "styled-system";
import {
  textDecoration,
  TextDecorationProps,
  textOverflow,
  TextOverflowProps,
  whiteSpace,
  WhiteSpaceProps,
  wordBreak,
  WordBreakProps,
} from "../../../theme/styled-system-custom";
import { shouldForwardProp } from "../../../utils/should-forward-prop";
import { RouterLink } from "../router/RouterLink";

export type TextProps = TypographyProps &
  SpaceProps &
  ColorProps &
  LayoutProps &
  DisplayProps &
  FlexboxProps &
  WordBreakProps &
  WhiteSpaceProps &
  TextDecorationProps &
  TextOverflowProps;

const typographyStyled = compose(
  color,
  typography,
  space,
  display,
  layout,
  flexbox,
  textOverflow,
  whiteSpace,
  wordBreak,
  textDecoration
);

export const H1 = styled.h1.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.heading")};
  font-weight: 500;
  line-height: 1;

  font-size: 3rem;

  ${themeGet("mediaQueries.sm")} {
    font-size: ${themeGet("fontSizes.11")};
  }

  ${typographyStyled}
`;

export const H1Dot5 = styled.h2.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.heading")};
  font-weight: 500;
  line-height: 1.125;

  font-size: ${themeGet("fontSizes.8")};

  ${themeGet("mediaQueries.sm")} {
    font-size: ${themeGet("fontSizes.10")};
  }

  ${typographyStyled}
`;

export const H2 = styled.h2.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.heading")};
  font-weight: 500;
  line-height: 1.125;

  font-size: ${themeGet("fontSizes.6")};

  ${themeGet("mediaQueries.sm")} {
    font-size: ${themeGet("fontSizes.10")};
  }

  ${typographyStyled}
`;

export const H3 = styled.h3.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.heading")};
  font-weight: 500;

  font-size: ${themeGet("fontSizes.4")};

  ${themeGet("mediaQueries.sm")} {
    font-size: 3rem;
  }

  ${typographyStyled}
`;

export const H4 = styled.h4.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.body")};
  font-weight: 600;

  font-size: ${themeGet("fontSizes.3")};

  ${typographyStyled}
`;

export const H5 = styled.h5.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.body")};
  font-weight: 600;
  line-height: 1.5;

  font-size: ${themeGet("fontSizes.3")};

  ${typographyStyled}
`;

export const Lead = styled.p.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.blacks.black800")};
  font-family: ${themeGet("fonts.body")};
  line-height: 1.5;

  font-size: ${themeGet("fontSizes.3")};

  ${themeGet("mediaQueries.sm")} {
    font-size: ${themeGet("fontSizes.4")};
  }

  ${typographyStyled}
`;

export const P = styled.p.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.body")};
  font-size: ${themeGet("fontSizes.2")};
  line-height: 1.5;

  ${typographyStyled}
`;

export const A = styled.a.withConfig({
  shouldForwardProp,
})<TextProps>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.body")};
  font-size: ${themeGet("fontSizes.2")};
  line-height: 1.5;

  ${typographyStyled}
`;

export const Link = styled(RouterLink).withConfig({
  shouldForwardProp,
})<TextProps & { $clickOverlay?: boolean }>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.body")};
  font-size: ${themeGet("fontSizes.2")};
  line-height: 1.5;

  ${typographyStyled}

  ::before {
    ${({ $clickOverlay }) => $clickOverlay && `content: "";`}
    cursor: inherit;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    width: 100%;
    height: 100%;
  }
`;

export enum LabelSize {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
}

export const Label = styled.div.withConfig({
  shouldForwardProp,
})<TextProps & { size: LabelSize }>`
  color: ${themeGet("colors.black")};
  font-family: ${themeGet("fonts.body")};
  line-height: 1.5;

  font-size: ${themeGet("fontSizes.2")};
  font-weight: 600;

  ${variant({
    prop: "size",
    variants: {
      [LabelSize.LARGE]: {
        fontSize: 2,
      },
      [LabelSize.MEDIUM]: {
        fontSize: 1,
      },
      [LabelSize.SMALL]: {
        fontSize: 0,
      },
    },
  })}

  ${typographyStyled}
`;
