import themeGet from "@styled-system/theme-get";
import React, { useState } from "react";
import styled from "styled-components";
import {
  ColorProps,
  FlexProps,
  FontSizeProps,
  SpaceProps,
  BorderProps,
  LayoutProps,
  color,
  space,
  flex,
  fontSize,
  border,
  layout,
  variant,
  typography,
  TypographyProps,
} from "styled-system";
import { hexToRgba } from "../../../utils/color-utils";
import { Box } from "../../base/box/Box";
import { motion } from "framer-motion";
import { IoWarningOutline } from "react-icons/io5";
import { useThemeGet } from "../../../hooks/use-theme-get";
import { useLongPress } from "../../../hooks/use-long-press";
import { useEffect } from "react";
import { SrOnly } from "../../base/sr-only/SrOnly";
import type { IconType } from "react-icons";
import { IconButton } from "../../base/icon-button/IconButton";
import { shouldForwardProp } from "../../../utils/should-forward-prop";
import { AssistiveText } from "../assistive-text/AssistiveText";

export enum InputVariant {
  LIGHT = "LIGHT",
  DARK = "DARK",
}

type CustomInputProps = {
  id: string;
  label?: string;
  hideLabel?: boolean;
  hideErrorIcon?: boolean;
  hideAssistiveText?: boolean;
  hasError?: boolean;
  assistiveText?: string;
  variant?: InputVariant;
  focus?: boolean;
  onLongPress?: () => void;
  inputAction?: {
    icon: IconType;
    onClick: () => void;
  };
  textarea?: boolean;
  withoutLine?: boolean;
  lineAlwaysVisible?: boolean;
  disableEdit?: boolean;
};

type StyledInputProps = ColorProps &
  LayoutProps &
  FlexProps &
  FontSizeProps &
  SpaceProps &
  BorderProps &
  TypographyProps &
  CustomInputProps &
  LayoutProps;

type InnerInputProps = {
  $hideLabel?: boolean;
  $hasAction?: boolean;
};

export type InputProps = StyledInputProps & React.HTMLProps<HTMLInputElement>;

const StyledInput = styled.input.withConfig({ shouldForwardProp })<
  StyledInputProps & InnerInputProps
>`
  font-family: inherit;
  border: none;
  border-radius: 4px 4px 0px 0px;
  font-size: ${themeGet("fontSizes.2")};
  width: 100%;
  color: ${themeGet("colors.black")};
  background-color: ${(props) =>
    hexToRgba(themeGet("colors.black")(props), 0.04)};

  padding: ${({ $hideLabel, $hasAction }) =>
    $hideLabel
      ? `1rem ${$hasAction ? "3rem" : "0.75rem"} 1rem 0.75rem`
      : `1.5rem ${$hasAction ? "3rem" : "0.75rem"} 0.5rem 0.75rem`};

  overflow: hidden;
  text-overflow: ellipsis;

  &::placeholder {
    color: ${themeGet("colors.blacks.black600")};
  }

  &:hover,
  &:focus,
  &.focus {
    &:not(:disabled) {
      background-color: ${(props) =>
        hexToRgba(themeGet("colors.black")(props), 0.08)};
    }
  }

  &:disabled {
    color: ${(props) => hexToRgba(themeGet("colors.black")(props), 0.4)};
  }

  &:focus-visible {
    outline: none;
  }

  ${(props) =>
    variant({
      variants: {
        [InputVariant.LIGHT]: {},
        [InputVariant.DARK]: {
          color: "white",
          bg: hexToRgba(themeGet("colors.white")(props), 0.04),
          ":hover:not(:disabled), :focus:not(:disabled), .focus": {
            bg: hexToRgba(themeGet("colors.white")(props), 0.08),
          },
        },
      },
    })}

  ${color}
  ${space}
  ${flex}
  ${fontSize}
  ${border}
  ${layout}
  ${typography}
`;

enum LabelState {
  HIGH = "HIGH",
  DEFAULT = "DEFAULT",
}

enum InputState {
  FOCUS = "FOCUS",
  DEFAULT = "DEFAULT",
}

const labelVariants = {
  [LabelState.HIGH]: {
    y: -10,
    scale: 0.75,
    originX: "0px",
  },
  [LabelState.DEFAULT]: {
    scale: 1,
    y: 0,
    originX: "0px",
  },
};

const lineVariants = {
  showLine: {
    scaleX: 1,
    originX: "50%",
  },
  default: {
    scaleX: 0,
    originX: "50%",
  },
};

const Label = styled(Box)<{ variant: InputVariant; $hasError?: boolean }>`
  ${({ $hasError }) =>
    variant({
      variants: {
        [InputVariant.DARK]: {
          color: $hasError ? "colors.danger.danger50" : "blacks.black400",
          ":placeholder": {
            color: "blacks.black400",
          },
        },
      },
    })}
`;

const Line = styled(Box)<{ variant: InputVariant; $hasError?: boolean }>`
  ${(props) =>
    variant({
      variants: {
        [InputVariant.LIGHT]: {
          backgroundColor: props.$hasError
            ? "danger.danger50"
            : themeGet("colors.black")(props),
        },
        [InputVariant.DARK]: {
          backgroundColor: props.$hasError
            ? "danger.danger50"
            : themeGet("colors.blacks.black200")(props),
        },
      },
    })}
`;

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      onFocus,
      onBlur,
      id,
      hideLabel,
      placeholder,
      hasError,
      onChange,
      width,
      maxWidth,
      disabled,
      assistiveText,
      variant = InputVariant.LIGHT,
      focus,
      value,
      inputAction,
      onLongPress,
      hideErrorIcon,
      hideAssistiveText,
      textarea,
      withoutLine,
      lineAlwaysVisible,
      disableEdit = false,
      ...props
    },
    ref
  ) => {
    const [inputState, setInputState] = useState(InputState.DEFAULT);
    const [innerValue, setInnerValue] = useState("");
    const black = useThemeGet("colors.black");
    const { onTouchStart, onTouchEnd } = useLongPress(onLongPress);

    useEffect(() => {
      if (typeof value === "string") {
        setInnerValue(value);
      } else if (value) {
        throw new Error(
          `Currently only string values are supported. Value [${value}] is not a string.`
        );
      }
    }, [value]);

    const labelState =
      innerValue.length || inputState === InputState.FOCUS || focus
        ? LabelState.HIGH
        : LabelState.DEFAULT;

    const hasLine = lineAlwaysVisible || !!innerValue.length;
    const hasFatLine = hasError || inputState === InputState.FOCUS || focus;

    const faintedBlack = hexToRgba(black, 0.4);

    const ActionIcon = inputAction?.icon;

    return (
      <Box
        display="flex"
        flexDirection="column"
        width={width}
        maxWidth={maxWidth}
      >
        <Box display="flex" position="relative" alignItems="center">
          {label && hideLabel && (
            <SrOnly as="label" htmlFor={id}>
              {label}
            </SrOnly>
          )}
          {label && !hideLabel && (
            <Label
              as={motion.label}
              variant={variant}
              variants={labelVariants}
              animate={labelState}
              display="inline-block"
              position="absolute"
              left="0.75rem"
              top={textarea ? "0.75rem" : null}
              htmlFor={id}
              color={
                hasError
                  ? "danger.danger50"
                  : disabled
                  ? faintedBlack
                  : "blacks.black600"
              }
              $hasError={hasError}
              cursor="text"
            >
              {label}
            </Label>
          )}
          {!hideErrorIcon && !inputAction && (
            <Box
              as="span"
              position="absolute"
              right="0.75rem"
              color="danger"
              display={hasError ? "inline-block" : "none"}
            >
              <IoWarningOutline size="1.25rem" />
            </Box>
          )}
          <StyledInput
            as={textarea ? motion.textarea : motion.input}
            ref={ref}
            id={id}
            placeholder={label ? "" : placeholder}
            $hideLabel={hideLabel}
            $hasAction={!!inputAction}
            disabled={disabled}
            aria-invalid={hasError}
            variant={variant}
            value={innerValue}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            {...(props as any)}
            onFocus={(e) => {
              onFocus?.(e);
              setInputState(InputState.FOCUS);
            }}
            onBlur={(e) => {
              onBlur?.(e);
              setInputState(InputState.DEFAULT);
            }}
            onChange={(e) => {
              if (!disableEdit) {
                setInnerValue(e.currentTarget.value);
                onChange?.(e);
              }
            }}
            className={focus ? "focus" : ""}
          />
          {!withoutLine && (
            <Line
              as={motion.div}
              variants={lineVariants}
              variant={variant}
              animate={hasFatLine || hasLine ? "showLine" : "default"}
              initial="default"
              transition={{ duration: 0.15, ease: "easeOut" }}
              height={hasFatLine ? "2px" : "1px"}
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              $hasError={hasError}
            ></Line>
          )}
          {inputAction && ActionIcon && (
            <Box
              position="absolute"
              right="0"
              top="0"
              bottom="0"
              display="flex"
              alignItems="stretch"
              p="0.75rem"
            >
              <IconButton onClick={inputAction.onClick} type="button">
                <ActionIcon size="1.5rem" />
              </IconButton>
            </Box>
          )}
        </Box>
        {!hideAssistiveText && (
          <AssistiveText
            mt={2}
            pl="0.75rem"
            minHeight="1em"
            $hasError={hasError}
            variant={variant}
          >
            {assistiveText}&nbsp;
          </AssistiveText>
        )}
      </Box>
    );
  }
);
