import React, { PropsWithChildren } from "react";

export interface Native {
  haptics: {
    lightImpact(): void;
  };
  isNativePlatform: boolean;
}

const dummyNative: Native = {
  haptics: {
    lightImpact: () => {
      console.debug("[dummy native]: haptics.lightImpact() was called");
    },
  },
  isNativePlatform: false,
};

export const noopNative: Native = {
  haptics: {
    lightImpact: () => {},
  },
  isNativePlatform: false,
};

export const NativeContext = React.createContext<Native>(dummyNative);

export interface NativeProviderProps {
  native?: Native;
}

export const NativeProvider: React.FunctionComponent<
  PropsWithChildren<NativeProviderProps>
> = ({ native, children }) => {
  return (
    <NativeContext.Provider value={native ?? dummyNative}>
      {children}
    </NativeContext.Provider>
  );
};
