import themeGet from "@styled-system/theme-get";
import { useTheme } from "styled-components";

type ThemeGetParams = Parameters<typeof themeGet>;

/**
 *
 * @param path Hook to get a value from the styled system
 * @param fallback
 * @returns
 */
export function useThemeGet(
  path: ThemeGetParams[0],
  fallback?: ThemeGetParams[0]
) {
  const theme = useTheme();

  return themeGet(path, fallback)({ theme });
}
