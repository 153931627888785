import { motion, useAnimationControls } from "framer-motion";
import React, { useEffect } from "react";
import styled from "styled-components";
import { useTimer } from "use-timer";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { hexToRgba } from "../../../utils/color-utils";
import { range } from "../../../utils/range";
import { Box } from "../box/Box";

export interface ProgressStepsProps {
  numSteps: number;
  currentStep: number;
  progressTimeS?: number;
  onStepComplete?: () => void;
  paused?: boolean;
}

const OuterProgress = styled(Box)`
  transform: translateZ(0);
`;

const InnerProgress = styled(Box)`
  transform-origin: left;
  transform: scaleX(0.5);
`;

const DEFAULT_PROGRESS_TIME_S = 7;

const PROGRESS_STEP_WIDTH_PX = 24;
const PROGRESS_STEP_HEIGHT_PX = 4;

export const ProgressSteps: React.FunctionComponent<ProgressStepsProps> = ({
  numSteps,
  currentStep,
  onStepComplete,
  paused,
  progressTimeS = DEFAULT_PROGRESS_TIME_S,
}) => {
  const controls = useAnimationControls();
  const black = useThemeColor("black");
  const { start, pause, reset, time, status } = useTimer({
    endTime: 0,
    initialTime: progressTimeS,
    timerType: "DECREMENTAL",
    onTimeOver: onStepComplete,
  });

  useEffect(() => {
    if (paused) {
      pause();
      controls.stop();
    } else if (status === "PAUSED") {
      start();
      controls.start({
        scaleX: 1,
        transition: {
          duration: time,
        },
      });
    }
  }, [paused, controls, status, time]);

  useEffect(() => {
    reset();
    start();
    controls.set({
      scaleX: 0,
    });
    controls.start({
      scaleX: 1,
      transition: {
        ease: "linear",
        duration: progressTimeS,
      },
    });
  }, [controls, progressTimeS, currentStep, reset]);

  return (
    <OuterProgress display="flex" justifyContent="center" gridGap={1}>
      {range(numSteps).map((i) => (
        <Box
          key={i}
          height={`${PROGRESS_STEP_HEIGHT_PX}px`}
          borderRadius={`${PROGRESS_STEP_HEIGHT_PX / 2}px`}
          width={`${PROGRESS_STEP_WIDTH_PX}px`}
          bg={i < currentStep ? "black" : hexToRgba(black, 0.24)}
          overflow="hidden"
        >
          {currentStep === i && (
            <InnerProgress
              as={motion.div}
              animate={controls}
              bg="black"
              height="100%"
              width="100%"
            />
          )}
        </Box>
      ))}
    </OuterProgress>
  );
};
