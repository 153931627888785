import { themeGet } from 'react-components';
import { createGlobalStyle } from 'styled-components';

export const TermlyStyles = createGlobalStyle`
  #termly-code-snippet-support {

    /* hack to hide consent banner on ios */
    display: 'none';

    span, div {
      font-family: 'Inter', sans-serif !important;
      color: ${themeGet('colors.black')} !important;
    }
    
    > div {
      background-color: ${themeGet('colors.cloud')} !important;
    }

    @media screen and (min-width: 1280px) {
      border-radius: 0 !important;
    }

    #CybotCookiebotDialogBodyContent {
      letter-spacing: 0 !important;
    }

    #CybotCookiebotDialogBodyEdgeMoreDetailsLink {
      color: ${themeGet('colors.black')} !important;
    }

    #CybotCookiebotDialogBodyContentText {
      color: ${themeGet('colors.blacks.black800')} !important;
    }

    #CybotCookiebotDialogHeader, .CybotCookiebotScrollContainer {
      border: none !important;
    }

    #CybotCookiebotDialogPoweredByText {
      display: none !important;
    }

    button.t-acceptAllButton {
      background-color: ${themeGet('colors.black')} !important;
    }

    button {
      font-size: ${themeGet('fontSizes.1')} !important;
      font-family: ${themeGet('fonts.body')} !important;
      font-weight: 600 !important;
      text-align: center !important;

      border-radius: 30px !important;
      opacity: 1 !important;
      box-shadow: none !important;
      white-space: nowrap !important;
      cursor: pointer !important;

      :hover {
        text-decoration: none !important;
      }

      :focus-visible {
        outline: 2px solid themeGet('colors.white') !important;
        outline-color: ${themeGet('colors.black')} !important;
        outline-style: solid !important;
        outline-offset: 2px !important;
        outline-width: 2px !important;
      }

      :disabled {
        cursor: default !important;
        box-shadow: none !important;
      } 

      transition: 0.15s all ease-out !important;
      padding: ${10 / 16}rem 1.5rem !important;
      font-size: ${themeGet('fontSizes.1')} !important;
    }
  }
`;
