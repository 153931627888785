export const discordLink = 'https://discord.gg/gHeHD4fAqK';
export const twitterLink = 'https://twitter.com/pier_wallet';
export const linkedInLink = 'https://www.linkedin.com/company/pierwallet';
export const instagramLink = 'https://www.instagram.com/pierwallet/';

/**
 * Template for wallet name. Be very careful changing this, as the file name is used to find
 * existing wallets in the file system. If this is changed, the wallet name regex has to be adapted as well,
 * and in a backwards compatible way.
 */
// TODO: Update to pier
export const walletFileNameTemplate = (walletName: string) => `my-nobank-pass-${walletName}.png`;

/**
 * Regex to find wallets in the file system.
 */
export const walletFileNameRegex = /^my\-nobank\-pass\-(.*)\.png$/i;

export const baseCurrency = 'USD';
export const baseCurrencyDecimals = 2;

export const googleAuthAppStoreLink = 'https://apps.apple.com/app/google-authenticator/id388497605';
export const googleAuthPlayStoreLink =
  'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';

export const nobankAppStoreLink = 'https://apps.apple.com/lb/app/id1613187762';
export const nobankPlayStoreLink = 'https://play.google.com/store/apps/details?id=one.nobank.app';

export const privacyPolicyUrl =
  'https://app.termly.io/document/privacy-policy/f4e73148-c22c-49c2-9588-3176c45d3b3a';
export const termsUrl =
  'https://app.termly.io/document/terms-of-use-for-website/db94a907-71d9-4261-bc42-453e74ec238b';

export const pinDigits = 6;

export const supportEmail = 'support@pierwallet.com';
export const contactEmail = 'info@pierwallet.com';

export const otpDigits = 6;

export const nativeCurrencyAddress = '0x0000000000000000000000000000000000000000';

export const sentryDsn =
  'https://b9665981063543b58165b038e902d36b@o1048075.ingest.sentry.io/6046292';
export const sentryOrganizationSlug = 'pierwallet';
export const sentryProjectSlug = 'nobank-frontend';

export const peskoFriends = ['pesko-lenzerheide', 'pesko-rothornbahn'];

export const walletConnectLocalStorageKey = 'walletconnect';

export const FIXED_SALT = 'randomFixHighSecuritySalt';
