import React from "react";
import { Helmet } from "react-helmet";
import { getTheme } from "../theme/theme";
import { ThemeId } from "./theme-id";

interface HtmlHeadProps {
  fontPath: string;
}

export const HtmlHead: React.FunctionComponent<HtmlHeadProps> = ({
  fontPath,
}) => {
  return (
    <Helmet>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap"
        rel="stylesheet"
      />
      <meta
        name="viewport"
        content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <meta name="theme-color" content={getTheme(ThemeId.LIGHT).colors.black} />
      <style>{`
@font-face {
  font-family: 'GT Flexa';
  src: url('${fontPath}/GT-Flexa-Standard-Medium.woff2') format('woff2'), url('${fontPath}/GT-Flexa-Standard-Medium.woff') format('woff'), url('${fontPath}/GT-Flexa-Standard-Medium.ttf') format('truetype');
  font-weight: 500 500;
}
    `}</style>
    </Helmet>
  );
};
