import styled from "styled-components";
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  FlexProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  zIndex,
  ZIndexProps,
} from "styled-system";
import {
  pointerEvents,
  PointerEventsProps,
} from "../../../theme/styled-system-custom";
import { themeGet } from "@styled-system/theme-get";
import { hexToRgba } from "../../../utils/color-utils";

type StyledSystemProps = ColorProps &
  FlexProps &
  GridProps &
  FontSizeProps &
  FontWeightProps &
  SpaceProps &
  BorderProps &
  LayoutProps &
  PointerEventsProps &
  ZIndexProps;

export interface EditNavBarButtonProps extends StyledSystemProps {
  isLoading?: boolean;
}
// TODO: Andrew, add storybook
export const EditNavBarButton = styled.button<EditNavBarButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${themeGet("fontSizes.1")};
  font-family: ${themeGet("fonts.body")};
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border: none;
  padding-left: 0;
  padding-right: 0;

  transition: color 0.2s ease-out;

  :hover {
    color: ${(props) => hexToRgba(themeGet("colors.black")(props), 0.92)};
  }

  :active {
    color: ${(props) => hexToRgba(themeGet("colors.black")(props), 0.88)};
  }

  :disabled,
  &[disabled] {
    cursor: default;
    box-shadow: none;
    pointer-events: none;
    color: ${(props) =>
      hexToRgba(themeGet("colors.blacks.black600")(props), 0.4)};
  }

  ${color}
  ${space}
  ${flex}
  ${grid}
  ${fontSize}
  ${fontWeight}
  ${border}
  ${layout}
  ${pointerEvents}
  ${zIndex}
`;
