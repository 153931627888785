import React from "react";
import { HeadProps } from "./head-props";
import { HeadSvg } from "./HeadSvg";

export const Head3: React.FunctionComponent<HeadProps> = ({
  hairColor,
  noseColor,
  skinColor,
}) => {
  return (
    <HeadSvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4234_40474"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4234_40474)">
        <path
          d="M177.306 275.315C152.248 290.604 117.635 309.503 110.84 314.175C103.62 318.847 103.407 326.492 104.469 329.677C105.531 333.287 108.716 337.109 117.423 337.534C126.129 337.959 178.793 339.02 178.793 339.02"
          fill={noseColor}
        />
        <path
          d="M169.449 279.987C168.387 295.488 163.716 310.141 158.619 318.635C153.523 327.341 116.786 336.685 116.786 336.685L180.491 339.445L179.217 274.89L169.449 279.987Z"
          fill="#221C16"
        />
        <path
          d="M177.306 275.315C152.248 290.604 117.635 309.503 110.84 314.175C103.62 318.847 103.407 326.492 104.469 329.677C105.531 333.287 108.716 337.109 117.423 337.534C126.129 337.959 178.793 339.02 178.793 339.02"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M312.362 487.668C298.772 489.791 284.969 491.49 271.166 492.764C251.842 494.25 232.73 488.305 217.653 476.2C202.576 464.096 192.596 446.683 189.835 427.572C188.986 421.838 183.252 361.53 183.252 361.53C182.827 355.159 182.403 348.364 181.766 340.719C180.704 326.704 179.854 312.901 179.217 299.098C178.793 290.817 178.58 282.535 178.368 274.253C177.943 246.435 177.094 231.783 182.615 199.93C182.827 199.08 183.04 198.018 183.252 196.957L342.729 201.204C349.099 201.416 355.258 202.903 361.204 205.451C366.937 207.999 372.246 211.821 376.493 216.493C380.74 221.165 384.138 226.474 386.474 232.42C388.597 238.365 389.659 244.736 389.447 251.107L389.022 262.574C388.597 271.492 391.782 280.199 397.516 286.782C403.462 293.577 411.531 297.612 420.45 298.461C421.512 298.674 422.786 298.674 423.848 298.674C429.156 298.674 434.465 297.4 439.137 295.064C443.809 292.728 448.056 289.33 451.241 285.083C452.728 283.172 454.214 281.473 455.701 279.987C461.646 274.041 469.928 270.006 477.998 271.068C490.739 272.767 498.808 285.72 502.206 298.249C507.727 318.847 505.391 340.719 495.623 359.619C489.889 370.661 481.183 380.854 469.716 385.738C461.434 389.348 452.303 389.773 443.809 387.012C442.747 386.588 441.473 386.588 440.199 386.588C438.925 386.8 437.863 387.225 436.801 387.862C435.739 388.499 434.89 389.348 434.253 390.41C433.616 391.472 433.191 392.533 433.191 393.808C432.979 395.082 432.979 396.356 432.979 397.63C432.979 405.487 435.102 413.132 439.349 419.715C443.384 426.298 449.33 431.819 456.338 435.216L549.773 485.119C549.348 497.223 545.526 600.852 545.526 600.852H333.385L339.756 482.359C330.625 484.482 321.494 485.969 312.362 487.668Z"
          fill={skinColor}
        />
        <path
          d="M337.632 562.203C339.968 539.482 349.524 525.679 358.868 516.123C368.211 506.567 380.315 500.196 387.535 491.702C394.331 483.633 400.276 476.625 399.002 468.131L338.906 482.571L334.659 559.868L337.632 562.203Z"
          fill="#221C16"
        />
        <path
          d="M478.21 314.813L441.898 351.337"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M333.173 601.064L339.543 482.571C330.412 484.482 321.493 486.181 312.15 487.668C298.559 489.791 284.756 491.49 270.953 492.764C251.629 494.251 232.518 488.305 217.44 476.201C202.363 464.096 192.383 446.684 189.622 427.572C188.773 421.838 183.039 361.53 183.039 361.53C182.615 355.16 182.19 348.364 181.553 340.72C180.491 326.704 179.642 312.901 179.005 299.098C178.58 290.817 178.368 282.535 178.155 274.253C177.731 246.435 176.881 231.783 182.402 199.93C182.615 199.08 182.827 198.018 183.039 196.957"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M549.561 485.332L545.313 601.064"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M325.316 485.332C345.277 481.51 396.029 468.981 396.029 468.981"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M188.773 388.499C189.834 402.727 190.896 414.831 190.896 414.831C190.896 414.831 265.22 385.951 271.378 382.978C277.324 380.005 286.455 373.847 286.88 363.441C287.304 351.337 276.474 343.48 263.309 343.905C249.93 344.542 186.224 361.105 186.224 361.105C186.224 361.105 187.499 374.271 188.773 388.499Z"
          fill="white"
        />
        <path
          d="M184.101 361.742C184.101 361.742 247.807 347.09 261.185 346.241C275.413 343.268 287.092 351.337 286.88 363.653C286.667 374.059 277.324 380.005 271.378 383.19C265.432 386.163 189.198 416.742 189.198 416.742"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M273.926 319.485C289.407 319.485 301.957 306.935 301.957 291.454C301.957 275.973 289.407 263.423 273.926 263.423C258.445 263.423 245.896 275.973 245.896 291.454C245.896 306.935 258.445 319.485 273.926 319.485Z"
          fill="#221C16"
        />
        <path
          d="M251.417 276.165C250.992 276.165 250.78 276.165 250.355 276.377C246.957 281.686 245.259 288.269 246.108 295.065C246.32 296.763 246.745 298.25 247.17 299.736C249.506 301.01 251.842 301.435 254.602 301.223C261.397 300.373 266.282 294.215 265.432 287.207C264.583 280.2 258 275.316 251.417 276.165Z"
          fill="white"
        />
        <path
          d="M217.228 292.728C233.792 276.59 255.452 265.547 280.084 262.362C300.258 259.814 320.007 262.787 337.42 270.219"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M183.251 196.745C186.437 181.031 192.17 165.741 200.24 151.938C200.24 151.938 234.216 87.5953 307.69 66.9971V66.7848C318.945 63.8118 330.412 61.9006 341.879 60.8389L343.365 60.6265L349.736 59.9895H350.585L354.195 59.7771C369.485 58.503 384.774 58.9277 399.851 61.0512C416.415 63.5995 432.341 68.9083 446.994 76.553C458.248 82.4989 468.866 89.7188 478.634 98.0006C514.734 128.579 540.853 172.112 552.745 218.405C574.617 303.133 555.93 415.256 549.56 485.332L456.337 435.429C449.329 432.031 443.384 426.51 439.349 419.927C435.314 413.344 432.978 405.487 432.978 397.843C432.978 396.569 432.978 395.294 433.191 394.02C433.191 392.746 433.615 391.684 434.252 390.623C434.889 389.561 435.739 388.711 436.801 388.074C437.862 387.437 438.924 387.013 440.198 386.8C441.472 386.588 442.534 386.8 443.808 387.225C452.302 389.986 461.646 389.561 469.715 385.951C481.182 380.854 489.889 370.874 495.622 359.831C505.391 340.932 507.726 319.06 502.205 298.462C498.808 285.933 490.738 272.979 477.997 271.28C469.715 270.219 461.434 274.253 455.7 280.199C454.001 281.898 452.515 283.597 451.241 285.296C448.055 289.543 443.808 292.94 439.136 295.276C434.465 297.612 429.156 298.886 423.847 298.886C422.785 298.886 421.511 298.886 420.449 298.674C411.531 297.825 403.461 293.577 397.515 286.995C391.569 280.199 388.597 271.705 389.021 262.786L389.446 251.319C389.658 244.949 388.597 238.578 386.473 232.632C384.349 226.686 380.952 221.165 376.492 216.706C372.245 212.034 366.937 208.424 361.203 205.663C355.47 203.115 349.099 201.629 342.728 201.416L183.251 196.745Z"
          fill={hairColor}
        />
        <path
          d="M495.622 359.407L564 296.975C564 296.975 562.726 337.11 559.753 374.271C556.992 411.433 549.56 485.12 549.56 485.12L456.337 435.217C456.337 435.217 439.136 425.661 433.828 405.7C428.306 385.101 443.596 386.588 443.596 386.588C443.596 386.588 465.256 392.109 479.696 379.156C494.348 366.627 495.622 359.407 495.622 359.407Z"
          fill="#221C16"
        />
        <path
          d="M183.252 196.744C186.437 181.03 191.321 166.59 200.24 151.938C209.159 137.286 219.139 121.571 246.32 98.8497C268.83 80.1627 299.621 68.6957 307.69 66.7845C325.528 62.5374 341.879 59.9892 361.628 59.1398C377.979 58.5027 393.056 60.2015 399.851 61.051C416.415 63.5992 432.341 68.908 446.994 76.5527C458.248 82.4986 468.866 89.7186 478.634 98.0003C514.734 128.579 540.854 172.111 552.745 218.404C574.618 302.921 556.78 383.615 549.56 485.119L461.858 437.977C454.851 434.579 445.295 429.695 439.137 419.715C432.129 408.035 431.492 394.232 434.04 390.198C436.376 386.8 440.198 385.738 443.384 386.8C451.878 389.348 461.221 389.136 469.291 385.526C480.758 380.429 489.464 370.449 495.198 359.407C504.966 340.507 507.302 318.635 501.781 298.037C498.383 285.508 490.314 272.554 477.572 270.855C469.291 269.794 460.372 274.041 455.275 279.774C450.179 285.295 444.658 292.515 438.5 295.064C432.341 297.612 428.731 299.098 419.812 298.461C410.894 297.824 401.975 292.728 396.878 286.782C394.755 284.021 390.295 277.651 388.809 270.218C387.11 261.087 390.508 241.551 385.624 232.207C383.075 227.535 379.678 220.316 374.581 215.219C369.697 209.91 363.114 206.513 360.354 205.238C354.62 202.69 348.25 201.204 341.879 200.991L183.252 196.744Z"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </HeadSvg>
  );
};
