import React, { CSSProperties, useState, useCallback, PropsWithChildren } from 'react';

const noop = () => {};

const Context = React.createContext<{
  style: CSSProperties;
  setStyle: <T extends keyof CSSProperties>(styleKey: T, value: CSSProperties[T]) => void;
  removeStyle: (key: keyof CSSProperties) => void;
}>({ style: {}, setStyle: noop, removeStyle: noop });

export const RootStyleProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [rootStyle, setRootStyle] = useState<CSSProperties>({});

  const setStyle = useCallback(<T extends keyof CSSProperties>(key: T, value: CSSProperties[T]) => {
    setRootStyle((current) => {
      return {
        ...current,
        [key]: value,
      };
    });
  }, []);

  const removeStyle = useCallback((key: keyof CSSProperties) => {
    setRootStyle((current) => {
      const { [key]: _, ...rest } = current;
      return rest;
    });
  }, []);

  return (
    <Context.Provider value={{ style: rootStyle, setStyle, removeStyle }}>
      {children}
    </Context.Provider>
  );
};

export function useRootStyle() {
  return React.useContext(Context);
}
