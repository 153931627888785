import React from "react";
import { Box } from "../../base/box/Box";
import { Image } from "../../base/image/Image";
import { RouterLink } from "../../base/router/RouterLink";
import { H2, Link } from "../../base/typography/Typography";

export interface BlogTeaserProps {
  imageSrc: string;
  title: string;
  to: string;
  date: Date;
}

export const BlogTeaser: React.FunctionComponent<BlogTeaserProps> = ({
  imageSrc,
  title,
  date,
  to,
}) => {
  return (
    <Box position="relative">
      <Image
        src={imageSrc}
        height={{ _: "13.5rem", sm: "25rem" } as any}
        width="100%"
        alt={title}
        objectFit="cover"
        objectPosition="center"
      />
      <Box my={{ _: 3, sm: "1.5rem" }}>
        <Link
          href={to}
          fontSize={{ sm: "3rem", _: "1.5rem" }}
          $clickOverlay={true}
          fontWeight="500"
          fontFamily="heading"
        >
          {title}
        </Link>
      </Box>
      <Box>pier — {new Intl.DateTimeFormat().format(date)}</Box>
    </Box>
  );
};
