import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { Flowers } from "../../graphics/flowers/Flowers";
import { ProductCard } from "../product-card/ProductCard";

export interface ProductCardDiversifyProps extends Omit<BoxProps, "color"> {
  numAssetsIncluded: number;
}

const FlowersContainer = styled(Box)`
  > svg {
    position: relative;
    top: -2rem;
    right: -3rem;
    transform: scale(1.25);
  }
`;

export const ProductCardDiversify: React.FunctionComponent<
  PropsWithChildren<ProductCardDiversifyProps>
> = ({ numAssetsIncluded, ...boxProps }) => {
  return (
    <ProductCard
      {...boxProps}
      bg="experimental.experimental30"
      title="Diversify"
      subtitle="Index"
      illustrationOverflow="visible"
      illustration={
        <FlowersContainer mr="-1.5rem" ml="-1rem">
          <Flowers withoutAnimation={true} />
        </FlowersContainer>
      }
      number={`${numAssetsIncluded}+`}
      numberPostfix="assets included"
      description="Invest into a bundle of tokens weighted by market cap to easily diversify your portfolio."
    />
  );
};
