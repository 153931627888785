import { motion } from "framer-motion";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { ThemeColor } from "../../../theme/theme";
import { darken } from "../../../utils/color-utils";
import { Color } from "../../../utils/types";

export interface ExclamationProps {
  width?: string | number;
  height?: string | number;
  objectFit?: CSSProperties["objectFit"];
  color?: ThemeColor | Color;
}

const StyledSvg = styled.svg<{ $objectFit?: CSSProperties["objectFit"] }>`
  object-fit: ${({ $objectFit }) => $objectFit ?? "contain"};
`;

const container = {
  small: { opacity: 0 },
  large: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  small: { scale: 0 },
  large: { scale: 1 },
};

const transitionCommon = {
  repeat: Infinity,
  repeatType: "mirror" as const,
  type: "easyInOut",
  duration: 1,
};

export const Exclamation: React.FunctionComponent<ExclamationProps> = ({
  width,
  height,
  objectFit,
  color = "warning.warning40",
}) => {
  const hexColor = useThemeColor(color);

  console.log({ hexColor, color });

  return (
    <StyledSvg
      $objectFit={objectFit}
      width={width}
      height={height}
      viewBox="0 0 241 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1.2, 1, 1.2],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        opacity="0.4"
        cx={121}
        cy="209.518"
        rx="22.5"
        ry={4}
        fill="#221C16"
      />
      <motion.g
        animate={{
          translateY: [4, -4, 4],
          rotate: [2, 0, 2],
          originX: 1,
          originY: 0.5,
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <path
          d="M138.725 37.1213L118.837 39.795L38.4688 171.169L173.474 192.244L193.361 189.571L138.725 37.1213Z"
          fill={hexColor}
        />
        <g style={{ mixBlendMode: "overlay" }} opacity="0.1">
          <path
            d="M104.442 142.489L108.082 70.6356L125.512 73.0845L117.119 144.269L104.442 142.489Z"
            fill="#221C16"
          />
          <path
            d="M116.297 173.066L117.802 155.186L101.957 152.959L100.452 170.839L116.297 173.066Z"
            fill="#221C16"
          />
        </g>
        <path
          d="M118.837 39.7926L138.725 37.1193L193.361 189.569L173.474 192.242L118.837 39.7926Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.837 39.7926L38.4688 171.17L173.471 192.245L118.837 39.7926ZM118.573 42.3532L171.797 190.86L40.2845 170.328L118.573 42.3532Z"
          fill="#221C16"
        />
        <path
          d="M135.433 169.004C136.878 168.823 137.884 167.352 137.68 165.718C137.475 164.084 136.138 162.906 134.692 163.086C133.247 163.267 132.241 164.739 132.445 166.373C132.65 168.007 133.987 169.185 135.433 169.004Z"
          fill={hexColor}
        />
        <path
          d="M123.142 104.239C124.587 104.058 125.593 102.587 125.389 100.953C125.184 99.3189 123.847 98.1407 122.401 98.3215C120.956 98.5024 119.95 99.9736 120.154 101.608C120.359 103.242 121.696 104.42 123.142 104.239Z"
          fill={hexColor}
        />
        <path
          d="M149.188 162.204C150.634 162.023 151.64 160.552 151.436 158.918C151.231 157.284 149.894 156.106 148.448 156.286C147.003 156.467 145.997 157.938 146.201 159.573C146.406 161.207 147.743 162.385 149.188 162.204Z"
          fill={hexColor}
        />
        <path
          d="M138.72 130.49C140.165 130.309 141.171 128.838 140.967 127.204C140.762 125.57 139.425 124.392 137.979 124.573C136.534 124.753 135.528 126.225 135.732 127.859C135.937 129.493 137.274 130.671 138.72 130.49Z"
          fill={hexColor}
        />
        <path
          d="M111.688 80.642C113.134 80.4612 114.14 78.9899 113.936 77.3558C113.731 75.7217 112.394 74.5436 110.948 74.7244C109.503 74.9052 108.497 76.3765 108.701 78.0106C108.906 79.6446 110.243 80.8228 111.688 80.642Z"
          fill={hexColor}
        />
        <path
          d="M71.5547 145.458C73.0001 145.277 74.0062 143.806 73.8018 142.172C73.5973 140.538 72.2599 139.359 70.8145 139.54C69.369 139.721 68.363 141.192 68.5674 142.826C68.7718 144.461 70.1092 145.639 71.5547 145.458Z"
          fill={hexColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M111.572 74.7195C113.024 74.9222 114.09 76.4131 113.954 78.0429C113.818 79.6726 112.527 80.8304 111.075 80.625C109.762 80.4393 108.765 79.2061 108.686 77.7676L97.8928 76.084L98.064 74.9867L108.81 76.663C109.184 75.3812 110.318 74.5421 111.572 74.7195ZM111.166 79.5255C112.077 79.6532 112.888 78.9258 112.974 77.9041C113.06 76.8824 112.388 75.9467 111.477 75.819C110.567 75.6913 109.756 76.4187 109.67 77.4404C109.584 78.4621 110.256 79.3978 111.166 79.5255Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M87.6257 89.3283L98.9022 91.0877L106.196 99.5141L120.173 101.694C120.373 102.989 121.316 104.053 122.527 104.224C123.979 104.43 125.27 103.272 125.406 101.642C125.542 100.013 124.476 98.5216 123.024 98.319C121.672 98.1276 120.459 99.1188 120.189 100.573L106.769 98.4795L99.4756 90.0532L87.7969 88.231L87.6257 89.3283ZM122.619 103.125C123.53 103.253 124.34 102.525 124.426 101.503C124.512 100.482 123.841 99.5461 122.93 99.4184C122.019 99.2907 121.209 100.018 121.122 101.04C121.036 102.062 121.708 102.997 122.619 103.125Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M135.324 163.084C136.775 163.286 137.84 164.776 137.706 166.404L148.873 168.149L158.94 179.349L187.092 183.744L186.921 184.841L158.379 180.385L148.311 169.185L137.424 167.484C136.943 168.513 135.931 169.146 134.827 168.989C133.375 168.784 132.309 167.296 132.445 165.666C132.581 164.036 133.872 162.878 135.324 163.084ZM134.918 167.89C135.829 168.018 136.64 167.29 136.726 166.268C136.812 165.247 136.14 164.311 135.229 164.183C134.319 164.056 133.508 164.783 133.422 165.805C133.336 166.827 134.008 167.762 134.918 167.89Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.078 156.281C150.529 156.483 151.595 157.973 151.46 159.602L162.627 161.347L172.827 172.694L172.001 173.436L162.066 162.383L151.177 160.682C150.696 161.71 149.685 162.342 148.581 162.186C147.129 161.981 146.063 160.492 146.199 158.863C146.335 157.233 147.626 156.075 149.078 156.281ZM148.673 161.087C149.583 161.214 150.394 160.487 150.48 159.465C150.566 158.443 149.894 157.508 148.984 157.38C148.073 157.252 147.262 157.98 147.176 159.002C147.09 160.023 147.762 160.959 148.673 161.087Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M138.605 124.566C140.057 124.768 141.124 126.259 140.988 127.889L152.155 129.631L162.357 140.981L161.531 141.723L151.593 130.668L140.704 128.969C140.223 129.996 139.212 130.627 138.108 130.471C136.656 130.266 135.59 128.778 135.726 127.148C135.862 125.518 137.153 124.36 138.605 124.566ZM138.2 129.372C139.111 129.499 139.921 128.772 140.008 127.75C140.094 126.729 139.422 125.793 138.511 125.665C137.6 125.537 136.79 126.265 136.704 127.287C136.618 128.308 137.289 129.244 138.2 129.372Z"
          fill="#221C16"
        />
        <path
          d="M64.7757 158.851L55.1721 165.228L43.9075 163.471L43.7363 164.568L55.4271 166.392L65.0306 160.014L78.6134 162.136L78.7848 161.038L64.7757 158.851Z"
          fill="#221C16"
        />
        <path
          d="M63.3655 152.407L51.8878 150.616L52.059 149.519L63.5367 151.31L63.3655 152.407Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.7655 140.904L68.5586 142.588C68.6383 144.027 69.6353 145.26 70.9474 145.445C72.3994 145.651 73.6904 144.493 73.8265 142.863C73.9625 141.233 72.8964 139.743 71.4443 139.54C70.1903 139.362 69.0564 140.202 68.6828 141.484L57.9367 139.807L57.7655 140.904ZM71.039 144.346C71.9496 144.474 72.7603 143.746 72.8464 142.724C72.9325 141.703 72.2606 140.767 71.3499 140.639C70.4393 140.512 69.6286 141.239 69.5425 142.261C69.4564 143.282 70.1283 144.218 71.039 144.346Z"
          fill="#221C16"
        />
        <path
          d="M158.768 141.018L158.179 140.077L145.258 148.16L133.985 146.401L133.814 147.499L145.496 149.321L158.768 141.018Z"
          fill="#221C16"
        />
        <path
          d="M106.346 47.4415L96.4538 131.339L87.7887 132.424L72.8489 130.326L77.1384 45.6401L85.8036 44.5544L106.346 47.4415Z"
          fill={hexColor}
        />
        <path
          d="M68.1484 163.739L69.9198 142.663L78.5849 141.581L97.259 144.204L95.4877 165.28L86.8197 166.366L68.1484 163.739Z"
          fill={hexColor}
        />
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M69.9199 142.664L78.5851 141.581L97.2591 144.205L88.594 145.291L69.9199 142.664Z"
            fill="#221C16"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M77.1387 45.6404L85.8038 44.5548L106.346 47.4423L97.6813 48.5278L77.1387 45.6404Z"
            fill="#221C16"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M70.5923 142.579L69.9196 142.663L68.1455 163.739L86.8196 166.362V166.365L95.4875 165.28L97.2589 144.204V144.201L78.5848 141.577L71.3584 142.483L70.6223 142.375L70.5923 142.579ZM78.5764 142.699L93.0444 144.732L89.601 145.164L75.4743 143.088L78.5764 142.699ZM71.3502 143.604L88.5232 146.128L86.915 165.232L86.8251 165.243L69.3394 162.786L70.9497 143.654L71.3502 143.604Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.6769 44.0748L106.83 47.0481L96.8234 131.917L87.6535 133.063L72.1377 130.883L76.4765 45.2245L77.2172 45.132L77.2237 45.09L77.3702 45.1128L85.6769 44.0748ZM77.5359 46.2628L73.2982 129.924L87.6621 131.942L87.8478 131.919L97.5782 49.3947L77.5359 46.2628ZM98.5334 48.4199L81.3499 45.7347L85.6684 45.1951L103.862 47.7524L98.5334 48.4199Z"
          fill="#221C16"
        />
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M94.0485 80.6878C92.0632 78.1435 90.0653 75.0601 88.0107 69.5616C85.3694 62.4926 85.2746 58.4983 85.1799 54.5062C85.1237 52.1365 85.0675 49.7678 84.4789 46.756L77.2855 45.7452L75.9313 72.4789C76.0862 72.9344 76.2531 73.403 76.4332 73.8845C79.0744 80.9502 81.6213 84.0248 84.1682 87.0995C86.6737 90.1231 89.1793 93.1469 91.7746 99.9718L94.0485 80.6878Z"
            fill="white"
          />
          <path
            d="M90.0544 53.1938C90.1122 51.3371 90.1685 49.527 89.9491 47.525L97.8281 48.6323L94.869 73.7283C94.014 72.1384 93.1564 70.2611 92.2947 67.9564C89.8025 61.2931 89.9317 57.1372 90.0544 53.1938Z"
            fill="white"
          />
          <path
            d="M72.4407 130.431L74.5208 89.364C76.5318 91.8823 78.5481 94.945 80.5866 100.397C83.2319 107.466 83.2701 111.481 83.3082 115.496C83.3464 119.51 83.3846 123.524 86.0256 130.587C86.2696 131.24 86.5182 131.857 86.7707 132.444L72.4407 130.431Z"
            fill="white"
          />
          <path
            d="M68.5537 163.377L77.1209 164.575C77.0335 161.187 76.6539 157.459 74.4518 151.608C73.0456 147.868 71.65 145.269 70.2595 143.197L68.5537 163.377Z"
            fill="white"
          />
          <path
            d="M88.8214 145.193L87.5295 160.453C87.1815 158.025 86.5025 155.198 85.1251 151.533C83.9534 148.415 82.8004 146.085 81.6577 144.191L88.8214 145.193Z"
            fill="white"
          />
        </g>
      </motion.g>
      <motion.g variants={container} initial="small" animate="large">
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.6"
          d="M52.9632 63.6133C46.9699 63.6133 46.9609 72.6133 52.9632 72.6133C58.9654 72.6133 58.9654 63.6133 52.9632 63.6133Z"
          fill={hexColor}
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.4"
          d="M208.16 116.473C202.169 116.473 202.16 125.473 208.16 125.473C214.16 125.473 214.169 116.473 208.16 116.473Z"
          fill={hexColor}
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M15.3478 171.254C5.35508 171.254 5.34535 186.254 15.3478 186.254C25.3502 186.254 25.3502 171.254 15.3478 171.254Z"
          fill={hexColor}
        />
      </motion.g>
    </StyledSvg>
  );
};
