import { IconType } from "react-icons";
import { Box, BoxProps } from "../box/Box";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

export type IconButtonWithLabelProps = Omit<BoxProps, "color"> & {
  label: string;
  icon: IconType;
  onClick(): void;
};

const StyledBox = styled(Box)`
  @media (hover: hover) {
    &:hover {
      color: ${themeGet("colors.blacks.black600")};
    }
  }
`;

export const IconButtonWithLabel: React.FunctionComponent<
  IconButtonWithLabelProps
> = ({ label, icon: Icon, onClick, ...props }) => {
  return (
    <StyledBox
      as="button"
      display="flex"
      flexDirection="column"
      alignItems="center"
      border="none"
      backgroundColor="transparent"
      cursor="pointer"
      minWidth="4rem"
      py={2}
      onClick={onClick}
      {...props}
    >
      <Icon size="1.5rem" />
      <Box
        as="span"
        fontSize="0.625rem"
        mt="0.125rem"
        fontWeight="600"
        color="currentcolor"
      >
        {label}
      </Box>
    </StyledBox>
  );
};
