import { ObjectDotNotation } from "../utils/types";
import { colorThemes } from "./color-themes";
import { ThemeColors } from "./color-themes/color-theme";
import { ThemeId } from "./theme-id";

type ThemeScale<Type, Aliases extends string> = Array<Type> &
  Record<Aliases, Type>;

type FontSizeAlias = "body";

export type BreakpointAliases = "sm";

export const breakpoints = ["62rem"] as ThemeScale<string, BreakpointAliases>;

const theme = {
  fonts: {
    body: "'Inter', sans-serif",
    heading: "'GT Flexa', serif",
  },
  breakpoints,
  mediaQueries: {
    sm: `@media screen and (min-width: ${breakpoints[0]})`,
  },
  shadows: {
    card: "0px 4px 8px 3px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.15);",
  },
  fontSizes: [
    "0.75rem",
    "0.875rem",
    "1rem",
    "1.25rem",
    "1.5rem",
    "1.875rem",
    "2rem",
    "2.25rem",
    "2.5rem",
    "3.5rem",
    "4rem",
    "6rem",
  ] as ThemeScale<string, FontSizeAlias>,
};

// aliases
theme.breakpoints.sm = breakpoints[0];

export function getTheme(themeId: ThemeId) {
  return {
    themeId,
    colors: colorThemes[themeId],
    ...theme,
  };
}

// types
export type ThemeColor = ObjectDotNotation<ThemeColors>;
