import { useThemeColor } from 'react-components';
import Head from 'next/head';
import React from 'react';

export interface HtmlHeadProps {}

export const HtmlHead: React.FunctionComponent = () => {
  const bg = useThemeColor('blacks.black100');

  return (
    <Head>
      <style>{`
      html {
        background-color: ${bg};
    }
    `}</style>
      <meta charSet="utf-8" />
      <title>pier</title>
      <link rel="canonical" href="https://pierwallet.com" />
      <meta
        name="description"
        content="pier is a DeFi platform that allows users trade crypto assets natively on several chains through a non-custodial smart wallet."
      />
      <meta name="description" content="Smart contract crypto wallet as a service" />
      <meta name="description" content="Account abstraction wallet as a service" />
      <meta name="description" content="Smart wallet with multiple recovery features." />
      <meta name="description" content="Send, receive and trade crypto assets." />
      <meta name="description" content="Smart contract web3 wallet as a service" />
      <meta
        name="keyword"
        content="pier smart contract account abstraction wallet waas wallet-as-a-service DeFi decentralized finance"
      />
      <meta charSet="utf-8" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta
        name="viewport"
        content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/favicon-16x16.png`}
      />
      <link
        rel="manifest"
        href={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/site.webmanifest`}
      />
      <link
        rel="mask-icon"
        href={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/safari-pinned-tab.svg`}
        color="#221c16"
      />
      <link
        rel="shortcut icon"
        href={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/favicon.ico`}
      />
      <meta name="msapplication-TileColor" content="#221c16" />
      <meta
        name="msapplication-config"
        content={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/favicons/browserconfig.xml`}
      />
      <meta name="theme-color" content="#221c16" />
    </Head>
  );
};
