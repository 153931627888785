import React, { PropsWithChildren, useState } from "react";
import { useNative } from "../../../hooks/use-native";
import { noop } from "../../../utils/noop";
import {
  NotificationBottomSheet,
  NotificationBottomSheetProps,
} from "./NotificationBottomSheet";

export type PartialBottomSheetProps = Omit<
  NotificationBottomSheetProps,
  "open" | "onDismiss"
>;

export interface NotificationBottomSheetContextType {
  open: (props: PartialBottomSheetProps) => void;
  close: () => void;
}

export const NotificationBottomSheetContext =
  React.createContext<NotificationBottomSheetContextType>({
    open: noop,
    close: noop,
  });

export const NotificationBottomSheetProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [props, setProps] = useState<PartialBottomSheetProps | null>(null);
  const { haptics } = useNative();

  return (
    <NotificationBottomSheetContext.Provider
      value={{
        open: (props) => {
          setProps(props);
          setOpen(true);
          haptics.lightImpact();
        },
        close: () => setOpen(false),
      }}
    >
      {children}
      {props && (
        <NotificationBottomSheet
          {...props}
          open={open}
          onDismiss={() => setOpen(false)}
        />
      )}
    </NotificationBottomSheetContext.Provider>
  );
};
