import React from "react";
import { BodyProps } from "./body-props";
import { BodySvg } from "./BodySvg";

export const Body5: React.FunctionComponent<BodyProps> = ({ bodyColor }) => {
  return (
    <BodySvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4252_48961"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle r={360} transform="matrix(-1 0 0 1 360 360)" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4252_48961)">
        <rect
          x="-2.66998"
          y="3.29715"
          width={745}
          height={211}
          transform="matrix(-0.994522 0.104528 0.104528 0.994522 700.932 521.641)"
          fill={bodyColor}
          stroke="#221C16"
          strokeWidth={6}
        />
        <mask
          id="mask1_4252_48961"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={36}
          y={515}
          width={771}
          height={295}
        >
          <rect
            x="-2.66998"
            y="3.29715"
            width={745}
            height={211}
            transform="matrix(-0.994522 0.104528 0.104528 0.994522 777.648 515.297)"
            fill="#4C75B7"
          />
          <rect
            x="-2.66998"
            y="3.29715"
            width={745}
            height={211}
            transform="matrix(-0.994522 0.104528 0.104528 0.994522 777.648 515.297)"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            x="-2.66998"
            y="3.29715"
            width={745}
            height={211}
            transform="matrix(-0.994522 0.104528 0.104528 0.994522 777.648 515.297)"
            stroke="black"
            strokeWidth={6}
          />
        </mask>
        <g mask="url(#mask1_4252_48961)">
          <rect
            x="-3.29715"
            y="2.66998"
            width={312}
            height={312}
            transform="matrix(-0.994522 -0.104528 -0.104528 0.994522 743.703 628.91)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <rect
            x="-3.29715"
            y="2.66998"
            width={312}
            height={312}
            transform="matrix(-0.994522 -0.104528 -0.104528 0.994522 377.703 339.91)"
            stroke="#221C16"
            strokeWidth={6}
          />
        </g>
      </g>
    </BodySvg>
  );
};
