import React from "react";
import { BodyProps } from "./body-props";
import { BodySvg } from "./BodySvg";

export const Body3: React.FunctionComponent<BodyProps> = ({ bodyColor }) => {
  return (
    <BodySvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4251_46767"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4251_46767)">
        <rect
          x="16.0683"
          y="524.641"
          width={745}
          height={211}
          transform="rotate(6 16.0683 524.641)"
          fill={bodyColor}
          stroke="#221C16"
          strokeWidth={6}
        />
        <mask
          id="mask1_4251_46767"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={-87}
          y={515}
          width={771}
          height={295}
        >
          <rect
            x="-60.6475"
            y="518.297"
            width={745}
            height={211}
            transform="rotate(6 -60.6475 518.297)"
            fill="#4C75B7"
          />
          <rect
            x="-60.6475"
            y="518.297"
            width={745}
            height={211}
            transform="rotate(6 -60.6475 518.297)"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            x="-60.6475"
            y="518.297"
            width={745}
            height={211}
            transform="rotate(6 -60.6475 518.297)"
            stroke="black"
            strokeWidth={6}
          />
        </mask>
        <g mask="url(#mask1_4251_46767)">
          <path
            d="M236.988 577.613L231.85 626.506L204.417 648.339L200 690.365"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path
            d="M348.633 744.932L353.772 696.04L331.479 668.98L335.896 626.954"
            stroke="#221C16"
            strokeWidth={6}
          />
          <circle
            cx="338.19"
            cy="606.179"
            r={19}
            transform="rotate(-174 338.19 606.179)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path
            d="M153.633 724.932L158.772 676.04L136.479 648.98L140.896 606.954"
            stroke="#221C16"
            strokeWidth={6}
          />
          <circle
            cx="143.19"
            cy="586.179"
            r={19}
            transform="rotate(-174 143.19 586.179)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path
            d="M518.583 741.017L523.721 692.124L501.428 665.065L504.449 644.085"
            stroke="#221C16"
            strokeWidth={6}
          />
          <circle
            cx="507.909"
            cy="624.179"
            r={19}
            transform="rotate(-174 507.909 624.179)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path d="M276.512 658L267 748.501" stroke="#221C16" strokeWidth={6} />
          <path d="M423.648 635L411 755.337" stroke="#221C16" strokeWidth={6} />
        </g>
      </g>
    </BodySvg>
  );
};
