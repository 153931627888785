import { motion } from "framer-motion";
import React, { CSSProperties } from "react";
import styled from "styled-components";

export enum FloatingCheckVariant {
  WARNING = "warning",
  INFO = "info",
}

const fillColorMap: Record<FloatingCheckVariant, string> = {
  [FloatingCheckVariant.WARNING]: "#F4BD48",
  [FloatingCheckVariant.INFO]: "#5085DA",
};
export interface FloatingCheckProps {
  width?: string | number;
  height?: string | number;
  objectFit?: CSSProperties["objectFit"];
  variant?: FloatingCheckVariant;
}

const StyledSvg = styled.svg<{ $objectFit?: CSSProperties["objectFit"] }>`
  object-fit: ${({ $objectFit }) => $objectFit ?? "contain"};
`;

const container = {
  small: { opacity: 0 },
  large: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  small: { scale: 0 },
  large: { scale: 1 },
};

const transitionCommon = {
  repeat: Infinity,
  repeatType: "mirror" as const,
  type: "easyInOut",
  duration: 1,
};

export const FloatingCheck: React.FunctionComponent<FloatingCheckProps> = ({
  width,
  height,
  objectFit,
  variant = FloatingCheckVariant.WARNING,
}) => {
  const fillColor = fillColorMap[variant];

  return (
    <StyledSvg
      $objectFit={objectFit}
      width={width}
      height={height}
      viewBox="0 0 160 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1.2, 1, 1.2],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        cx="80.334"
        cy="139.679"
        rx={15}
        ry="2.66667"
        fill="#221C16"
        fillOpacity="0.24"
      />
      <motion.g variants={container} initial="small" animate="large">
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.6"
          d="M34.9754 42.4097C30.9799 42.4097 30.974 48.4097 34.9754 48.4097C38.9769 48.4097 38.9769 42.4097 34.9754 42.4097Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.4"
          d="M145.107 110.983C141.113 110.983 141.107 116.983 145.107 116.983C149.107 116.983 149.113 110.983 145.107 110.983Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M16.5652 87.5039C9.90339 87.5039 9.8969 97.5039 16.5652 97.5039C23.2335 97.5039 23.2335 87.5039 16.5652 87.5039Z"
          fill="white"
        />
      </motion.g>
      <motion.g
        animate={{
          translateY: [5, -5, 5],
          rotate: [5, 0, 5],
          originX: 1,
          originY: 0.5,
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <path
          d="M126.818 48.7949L108.42 32.9883L62.3527 84.9621L43.3338 59.9378L24.6236 73.8106L61.1499 122.37L61.2673 122.057L126.818 48.7949Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.164 35.9954L108.421 32.9873L108.421 32.9885L108.421 32.9883L62.3542 84.962L43.3353 59.9378L24.625 73.8106L61.1513 122.37L61.1514 122.369L73.6135 119.791L135.923 49.7465L119.164 35.9954ZM115.574 59.4301L124.982 48.9155L109.14 35.3059L115.574 59.4301ZM114.802 60.2924L95.4267 50.6046L103.326 73.1183L114.802 60.2924ZM102.372 74.1852L84.3184 62.8038L92.3543 85.381L102.372 74.1852ZM91.7017 86.1104L91.607 86.2162L71.5139 76.5714L83.4951 63.0539L91.7017 86.1104ZM84.5102 61.9087L94.5198 50.6157L102.451 73.2191L84.5102 61.9087ZM95.3952 49.628L114.636 59.2482L108.214 35.1659L95.3952 49.628ZM70.8335 77.339L65.01 83.9092L81.754 97.2283L81.8597 97.1101L70.8335 77.339ZM82.466 96.4326L72.0701 77.7918L91.0103 86.8831L82.466 96.4326ZM81.18 97.8698L65.0435 85.034L65.8429 115.011L81.18 97.8698ZM65.008 115.944L64.1788 84.847L62.2777 86.9919L60.9627 85.2618L56.1165 113.532L61.1634 120.241L65.008 115.944ZM55.4063 112.588L60.0896 85.2684L45.0257 98.7873L55.4063 112.588ZM44.1671 97.6458L53.4644 76.727L33.9966 84.1248L44.1671 97.6458ZM32.9421 82.723L41.8741 62.626L26.4356 74.073L32.9421 82.723ZM43.1632 61.8418L33.6015 83.3555L53.7073 75.7153L43.1632 61.8418ZM54.4312 76.6678L45.1718 97.5014L60.0899 84.1134L54.4312 76.6678Z"
          fill="#221C16"
        />
      </motion.g>
    </StyledSvg>
  );
};
