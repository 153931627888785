export const black = "#221C16";
export const yellow = "#F4BD48";
export const peach = "#F7CC9D";
export const blue = "#4C75B7";
export const cloud = "#E9EEF6";
export const cream = "#FCECCB";
export const aubergine = "#751F53";
export const orange = "#E88E5A";
export const indigo = "#003160";
export const beige = "#FDF2DA";
export const white = "#FFFFFF";
export const danger = "#FF6635";
export const success = "#2FAE5A";
export const friendship = "#B5D1FF";

export const black900 = "#403A35";
export const black800 = "#504A46";
export const black700 = "#635E5A";
export const black600 = "#76716E";
export const black500 = "#8E8A87";
export const black400 = "#A6A4A1";
export const black300 = "#BEBCBA";
export const black200 = "#D5D4D3";
export const black100 = "#EEEDED";
export const black50 = "#FAFAFA";

export const success10 = "#D3E1F6";
export const success20 = "#B9CEF0";
export const success30 = "#A8C2ED";
export const success40 = "#7CA4E3";
export const success50 = "#5085DA";
export const success60 = "#3C64A4";
export const success70 = "#305083";
export const success80 = "#28436D";
export const success90 = "#142137";

export const warning10 = "#FAE5C6";
export const warning20 = "#F7D5A4";
export const warning30 = "#F6CB8E";
export const warning40 = "#F1B155";
export const warning50 = "#EC971C";
export const warning60 = "#BD7916";
export const warning70 = "#8E5B11";
export const warning80 = "#5E3C0B";
export const warning90 = "#2F1E06";

export const danger10 = "#FDDFD2";
export const danger20 = "#FBBFA6";
export const danger30 = "#F89F79";
export const danger40 = "#F67F4D";
export const danger50 = "#F45F20";
export const danger60 = "#C34C1A";
export const danger70 = "#923913";
export const danger80 = "#62260D";
export const danger90 = "#311306";

export const experimental10 = "#DAF0F8";
export const experimental20 = "#B5E2F0";
export const experimental30 = "#91D3E9";
export const experimental40 = "#6CC5E1";
export const experimental50 = "#47B6DA";
export const experimental60 = "#3992AE";
export const experimental70 = "#2B6D83";
export const experimental80 = "#1C4957";
export const experimental90 = "#0E242C";

export const orange10 = "#FFE4CC";
export const orange20 = "#FFC999";
export const orange30 = "#FFAE66";
export const orange40 = "#FF9333";
export const orange50 = "#FF7800";
export const orange60 = "#CC6000";
export const orange70 = "#994800";
export const orange80 = "#663000";
export const orange90 = "#331800";

export const polygon10 = "#E6DAFA";
export const polygon20 = "#CDB5F5";
export const polygon30 = "#B491EF";
export const polygon40 = "#9B6CEA";
export const polygon50 = "#8247E5";
export const polygon60 = "#6839B7";
export const polygon70 = "#4E2B89";
export const polygon80 = "#341C5C";
export const polygon90 = "#1A0E2E";

export const ethereum10 = "#E0E5FB";
export const ethereum20 = "#C0CBF7";
export const ethereum30 = "#A1B2F2";
export const ethereum40 = "#8198EE";
export const ethereum50 = "#627EEA";
export const ethereum60 = "#4E65BB";
export const ethereum70 = "#3B4C8C";
export const ethereum80 = "#27325E";
export const ethereum90 = "#14192F";

export const zksync10 = "#D2E1FF";
export const zksync20 = "#A5C3FF";
export const zksync30 = "#78A5FF";
export const zksync40 = "#4B87FF";
export const zksync50 = "#1E69FF";
export const zksync60 = "#1854CC";
export const zksync70 = "#123F99";
export const zksync80 = "#0C2A66";
export const zksync90 = "#061533";

export const hexapod10 = "#ffd5df";
export const hexapod20 = "#ffaabf";
export const hexapod30 = "#ff809f";
export const hexapod40 = "#ff557f";
export const hexapod50 = "#FF2B5F";
export const hexapod60 = "#cc224c";
export const hexapod70 = "#991a39";
export const hexapod80 = "#661126";
export const hexapod90 = "#330913";

export const blast10 = "#FFE3D2";
export const blast20 = "#FFC7A5";
export const blast30 = "#FFAA79";
export const blast40 = "#FF8E4C";
export const blast50 = "#FF721F";
export const blast60 = "#CC5916";
export const blast70 = "#994010";
export const blast80 = "#66280B";
export const blast90 = "#331405";
