import styled, { CSSProperties } from "styled-components";
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  border,
  BorderProps,
  position,
  PositionProps,
  color,
  ColorProps,
} from "styled-system";
import { object, ObjectProps } from "../../../theme/styled-system-custom";
import { shouldForwardProp } from "../../../utils/should-forward-prop";

export type ImageProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  BorderProps &
  PositionProps &
  ColorProps &
  ObjectProps;

export const Image = styled.img.withConfig({
  shouldForwardProp,
})<ImageProps>`
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${color}
  ${object}
`;
