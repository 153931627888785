import * as colors from "../colors";
import { ThemeColors } from "./color-theme";

export const colorThemeDark: ThemeColors = {
  black: colors.white,
  yellow: colors.yellow,
  peach: colors.peach,
  blue: colors.blue,
  cloud: colors.cloud,
  cream: colors.cream,
  aubergine: colors.aubergine,
  indigo: colors.indigo,
  beige: colors.beige,
  white: colors.black,
  friendship: colors.friendship,

  blacks: {
    black900: colors.black50,
    black800: colors.black100,
    black700: colors.black200,
    black600: colors.black300,
    black500: colors.black400,
    black400: colors.black500,
    black300: colors.black600,
    black200: colors.black700,
    black100: colors.black800,
    black50: colors.black900,
  },

  success: {
    success10: colors.success10,
    success20: colors.success20,
    success30: colors.success30,
    success40: colors.success40,
    success50: colors.success50,
    success60: colors.success60,
    success70: colors.success70,
    success80: colors.success80,
    success90: colors.success90,
  },

  danger: {
    danger10: colors.danger10,
    danger20: colors.danger20,
    danger30: colors.danger30,
    danger40: colors.danger40,
    danger50: colors.danger50,
    danger60: colors.danger60,
    danger70: colors.danger70,
    danger80: colors.danger80,
    danger90: colors.danger90,
  },

  warning: {
    warning10: colors.warning10,
    warning20: colors.warning20,
    warning30: colors.warning30,
    warning40: colors.warning40,
    warning50: colors.warning50,
    warning60: colors.warning60,
    warning70: colors.warning70,
    warning80: colors.warning80,
    warning90: colors.warning90,
  },

  experimental: {
    experimental10: colors.experimental10,
    experimental20: colors.experimental20,
    experimental30: colors.experimental30,
    experimental40: colors.experimental40,
    experimental50: colors.experimental50,
    experimental60: colors.experimental60,
    experimental70: colors.experimental70,
    experimental80: colors.experimental80,
    experimental90: colors.experimental90,
  },

  orange: {
    orange10: colors.orange10,
    orange20: colors.orange20,
    orange30: colors.orange30,
    orange40: colors.orange40,
    orange50: colors.orange50,
    orange60: colors.orange60,
    orange70: colors.orange70,
    orange80: colors.orange80,
    orange90: colors.orange90,
  },

  tokens: {
    harmony: {
      harmony10: colors.success10,
      harmony20: colors.success20,
      harmony25: colors.success20,
      harmony30: colors.success30,
      harmony40: colors.success40,
      harmony50: colors.success50,
      harmony60: colors.success60,
      harmony70: colors.success70,
      harmony80: colors.success80,
      harmony90: colors.success90,
    },

    polygon: {
      polygon10: colors.polygon10,
      polygon20: colors.polygon20,
      polygon25: colors.polygon20,
      polygon30: colors.polygon30,
      polygon40: colors.polygon40,
      polygon50: colors.polygon50,
      polygon60: colors.polygon60,
      polygon70: colors.polygon70,
      polygon80: colors.polygon80,
      polygon90: colors.polygon90,
    },

    ethereum: {
      ethereum10: colors.ethereum10,
      ethereum20: colors.ethereum20,
      ethereum25: colors.ethereum20,
      ethereum30: colors.ethereum30,
      ethereum40: colors.ethereum40,
      ethereum50: colors.ethereum50,
      ethereum60: colors.ethereum60,
      ethereum70: colors.ethereum70,
      ethereum80: colors.ethereum80,
      ethereum90: colors.ethereum90,
    },

    zksync: {
      zksync10: colors.zksync10,
      zksync20: colors.zksync20,
      zksync25: colors.zksync20,
      zksync30: colors.zksync30,
      zksync40: colors.zksync40,
      zksync50: colors.zksync50,
      zksync60: colors.zksync60,
      zksync70: colors.zksync70,
      zksync80: colors.zksync80,
      zksync90: colors.zksync90,
    },

    hexapod: {
      hexapod10: colors.hexapod10,
      hexapod20: colors.hexapod20,
      hexapod25: colors.hexapod20,
      hexapod30: colors.hexapod30,
      hexapod40: colors.hexapod40,
      hexapod50: colors.hexapod50,
      hexapod60: colors.hexapod60,
      hexapod70: colors.hexapod70,
      hexapod80: colors.hexapod80,
      hexapod90: colors.hexapod90,
    },

    blast: {
      blast10: colors.blast10,
      blast20: colors.blast20,
      blast25: colors.blast20,
      blast30: colors.blast30,
      blast40: colors.blast40,
      blast50: colors.blast50,
      blast60: colors.blast60,
      blast70: colors.blast70,
      blast80: colors.blast80,
      blast90: colors.blast90,
    },
  },
};
