import React, { useRef, useEffect } from "react";

export function useCombinedRefs<T>(
  ...refs: (
    | React.MutableRefObject<T>
    | React.RefCallback<T>
    | React.ForwardedRef<T | undefined>
    | undefined
  )[]
) {
  const targetRef = useRef<T | null>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === "function") {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}
