import React, { Fragment } from "react";
import styled from "styled-components";
import { Box } from "../box/Box";
import { themeGet } from "@styled-system/theme-get";

export interface DescriptionListProps {
  items: {
    key: string;
    value: string | React.ReactNode;
  }[];
}

const StyledRow = styled(Box)`
  + div {
    border-top: 1px solid ${themeGet("colors.blacks.black100")};
  }
`;

export const DescriptionList: React.FunctionComponent<DescriptionListProps> = ({
  items,
}) => {
  return (
    <Box
      as="dl"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="blacks.black100"
      borderRadius="0.5rem"
    >
      {items.map(({ key, value }) => (
        <StyledRow key={key} display="flex">
          <Box
            flex="1"
            as="dt"
            p={3}
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={1}
            fontWeight="600"
          >
            {key}
          </Box>
          <Box
            flex="1"
            as="dd"
            p={3}
            textAlign="end"
            m={0}
            overflow="hidden"
            textOverflow="ellipsis"
            fontSize={1}
            color="blacks.black600"
          >
            {value}
          </Box>
        </StyledRow>
      ))}
    </Box>
  );
};
