import { Box } from 'react-components';
import React, { PropsWithChildren } from 'react';
import { useRootStyle } from 'shared/contexts/root-style-context';
import styled from 'styled-components';

export const rootComponentId = 'root-component';

const StyledBox = styled(Box)`
  transition: background 0.2s ease-in-out;
`;

export const RootComponent: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { style } = useRootStyle();

  return (
    <StyledBox
      id={rootComponentId}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      bg="blacks.black100"
      style={style}
    >
      {children}
    </StyledBox>
  );
};
