import { createGlobalStyle } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { modalStyles } from "../components/base/modal/modal-styles";
import { toastStyles } from "../components/notification/toast/toast-styles";

export const GlobalStyles = createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-underline-offset: 0.25em;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }

    html {
        background-color: ${themeGet("colors.white")};
    }

    body {
        /* required to get rid of double scrollbars on Firefox */
        overflow-y: ${({ enableBodyScroll }) =>
          enableBodyScroll ? "visible" : "hidden"};
        background-color: ${themeGet("colors.white")};
        margin: 0;
    }

    html, body, #__next {
        height: 100%;
    }
    
    a {
        text-decoration: none;
        color: ${themeGet("colors.black")};

        &:hover {
            color: ${themeGet("colors.black")};
            text-decoration: underline;
            text-underline-offset: 0.25rem;
        }
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed, 
    figure, figcaption, footer, header, hgroup, 
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
    }

    ol {
        margin-inline-start: 40px;
    }

    ${({ disableTextSelection }) =>
      disableTextSelection &&
      `
      * {
      -webkit-touch-callout: none;
      -webkit-user-select: none; /* Disable selection/copy in UIWebView */
    }

    input,textarea {
        user-select: auto;
    }
      `}

    ${modalStyles}
    ${toastStyles}
`;
