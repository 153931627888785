import React from "react";
import { BodyProps } from "./body-props";
import { BodySvg } from "./BodySvg";

export const Body6: React.FunctionComponent<BodyProps> = ({ bodyColor }) => {
  return (
    <BodySvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4252_48968"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4252_48968)">
        <rect
          x="-2.66998"
          y="3.29715"
          width={745}
          height={211}
          transform="matrix(-0.994522 0.104528 0.104528 0.994522 700.932 521.641)"
          fill={bodyColor}
          stroke="#221C16"
          strokeWidth={6}
        />
        <mask
          id="mask1_4252_48968"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={36}
          y={515}
          width={770}
          height={295}
        >
          <rect
            x="-2.66998"
            y="3.29715"
            width={745}
            height={211}
            transform="matrix(-0.994522 0.104528 0.104528 0.994522 777.647 515.297)"
            fill="#4C75B7"
          />
          <rect
            x="-2.66998"
            y="3.29715"
            width={745}
            height={211}
            transform="matrix(-0.994522 0.104528 0.104528 0.994522 777.647 515.297)"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            x="-2.66998"
            y="3.29715"
            width={745}
            height={211}
            transform="matrix(-0.994522 0.104528 0.104528 0.994522 777.647 515.297)"
            stroke="black"
            strokeWidth={6}
          />
        </mask>
        <g mask="url(#mask1_4252_48968)">
          <path
            d="M483.011 577.613L488.15 626.506L515.583 648.339L520 690.365"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path
            d="M371.366 744.932L366.228 696.04L388.521 668.98L384.104 626.954"
            stroke="#221C16"
            strokeWidth={6}
          />
          <circle
            r={19}
            transform="matrix(0.994522 -0.104528 -0.104528 -0.994522 381.81 606.179)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path
            d="M566.366 724.932L561.227 676.04L583.521 648.98L579.104 606.954"
            stroke="#221C16"
            strokeWidth={6}
          />
          <circle
            r={19}
            transform="matrix(0.994522 -0.104528 -0.104528 -0.994522 576.81 586.179)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path
            d="M201.417 741.017L196.278 692.124L218.572 665.065L215.551 644.085"
            stroke="#221C16"
            strokeWidth={6}
          />
          <circle
            r={19}
            transform="matrix(0.994522 -0.104528 -0.104528 -0.994522 212.091 624.179)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <path d="M443.488 658L453 748.501" stroke="#221C16" strokeWidth={6} />
          <path d="M296.352 635L309 755.337" stroke="#221C16" strokeWidth={6} />
        </g>
      </g>
    </BodySvg>
  );
};
