import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { IoChevronBackSharp, IoCloseSharp, IoMenuSharp } from "react-icons/io5";
import styled from "styled-components";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { Box } from "../../base/box/Box";
import { Button, ButtonVariant } from "../../base/button/Button";
import { IconButton } from "../../base/icon-button/IconButton";
import { ProgressCircle } from "../../base/progress-circle/ProgressCircle";
import { SrOnly } from "../../base/sr-only/SrOnly";
import { useAppHeader } from "./AppHeaderContext";
import { IconType } from "react-icons";

export interface AppHeaderProps {
  visible?: boolean;
  smartWalletAddress?: string;
  backgroundColor?: ThemeColor | Color;
  title?: string | React.ReactNode;
  progress?: {
    value: number;
    total: number;
    name?: string;
  };
  hasBackButton?: boolean;
  onBack?: () => void;
  hasCloseButton?: boolean;
  onClose?: () => void;
  onMenuOpen?: () => void;

  action?: {
    icon: IconType;
    onClick(): void;
  };
}

const StyledMenuButton = styled(Button)`
  width: 2.5rem;
  height: 2.5rem;
`;

export const AppHeader: React.FunctionComponent<AppHeaderProps> = (props) => {
  const { props: propsFromContext } = useAppHeader();

  const {
    smartWalletAddress,
    backgroundColor,
    title,
    progress,
    hasBackButton,
    onBack,
    hasCloseButton,
    onClose,
    onMenuOpen,
    action,
    visible = true,
  } = useMemo(
    () => ({
      ...props,
      ...propsFromContext,
    }),
    [props, propsFromContext]
  );
  const [progressForAnimation, setProgressForAnimation] = useState(
    Math.max((progress?.value ?? 0) - 1, 0)
  );

  useEffect(() => {
    if (progress && progress.value !== progressForAnimation) {
      setProgressForAnimation(progress.value);
    }
  }, [progress, progressForAnimation]);

  if (!visible) {
    return null;
  }

  return (
    <Box
      height="calc(3rem + env(safe-area-inset-top))"
      bg={backgroundColor}
      display="flex"
      alignItems="center"
      p="max(env(safe-area-inset-top, 0.25rem), 0.25rem) 1.5rem 0.25rem 1.5rem"
      overflow="hidden"
      justifyContent="space-between"
      flexShrink={0}
      position="relative"
    >
      {smartWalletAddress && !hasBackButton && (
        <StyledMenuButton
          variant={ButtonVariant.DEFAULT}
          onClick={onMenuOpen}
          p={0}
        >
          <SrOnly>Open user menu</SrOnly>
          <IoMenuSharp size="1.375rem" />
        </StyledMenuButton>
      )}

      {hasBackButton && (
        <IconButton
          onClick={onBack}
          bg={backgroundColor}
          p="0.5rem"
          ml="-0.5rem"
        >
          <SrOnly>back</SrOnly>
          <IoChevronBackSharp size="1.5rem" />
        </IconButton>
      )}

      <AnimatePresence>
        {title && (
          <Box
            flex="1"
            textAlign="center"
            as={motion.div}
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-300%", opacity: 0 }}
            pr={hasBackButton ? "2rem" : smartWalletAddress ? "2.5rem" : 0}
          >
            {title}
          </Box>
        )}
      </AnimatePresence>
      {action && (
        <IconButton onClick={action.onClick}>
          <action.icon size="1.5rem" />
        </IconButton>
      )}
      {progress && (
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          ml={!hasBackButton && !title ? "auto" : null}
        >
          {progress.name && <Box mr="1.5rem">{progress.name}</Box>}
          <ProgressCircle value={progress.value} total={progress.total} />
        </Box>
      )}
      {hasCloseButton && (
        <IconButton
          onClick={onClose}
          bg={backgroundColor}
          p={0}
          position="absolute"
          right="1.5rem"
        >
          <SrOnly>close</SrOnly>
          <IoCloseSharp size="1.25rem" />
        </IconButton>
      )}
    </Box>
  );
};
