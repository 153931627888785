import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, SyntheticEvent } from 'react';
import { NobankThemeProvider, RouterLinkComponent, ThemeId } from 'react-components';
import { TermlyStyles } from 'shared/styles/termly-styles';

const NextjsLink: RouterLinkComponent = React.forwardRef(
  ({ href, children, onClick, onBeforeNavigation, className }, ref) => {
    const customOnClick = (e: SyntheticEvent<HTMLAnchorElement, MouseEvent>) => {
      onBeforeNavigation?.(e);
      onClick?.(e);
    };

    return (
      <Link
        href={href}
        passHref={true}
        shallow={true}
        onClick={onBeforeNavigation ? customOnClick : onClick}
        ref={ref}
        className={className}
      >
        {children}
      </Link>
    );
  }
);

export const ConfiguredNobankThemeProvider: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const router = useRouter();

  return (
    <NobankThemeProvider
      fontPath={`${process.env.NEXT_PUBLIC_BASE_PATH ?? ''}/fonts`}
      theme={ThemeId.LIGHT}
      modalAppElement="#__next"
      linkComponent={NextjsLink}
      router={{
        push: router.push,
      }}
      enableBodyScroll={true}
    >
      <TermlyStyles />
      {children}
    </NobankThemeProvider>
  );
};
