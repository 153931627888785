import { themeGet } from "@styled-system/theme-get";
import styled from "styled-components";
import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  FlexProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  system,
} from "styled-system";
import { hexToRgba } from "../../../utils/color-utils";

export interface IconButtonCustomProps {
  hoverColor?: string;
}

export type IconButtonProps = SpaceProps &
  ColorProps &
  LayoutProps &
  BackgroundProps &
  BorderProps &
  FlexProps &
  PositionProps &
  IconButtonCustomProps;

export const IconButton = styled.button<IconButtonProps>`
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themeGet("colors.black")};

  @media (hover: hover) {
    &:hover:not(&[disabled]):not(:active) {
      color: ${themeGet("colors.blacks.black600")};

      ${system({
        hoverColor: {
          property: "color",
          scale: "colors",
        },
      })}
    }
  }

  :disabled,
  &[disabled] {
    cursor: default;
    box-shadow: none;
    pointer-events: none;
    color: ${(props) =>
      hexToRgba(themeGet("colors.blacks.black600")(props), 0.4)};
  }

  ${color}
  ${layout}
  ${position}
  ${background}
  ${space}
  ${border}
  ${flex}
`;
