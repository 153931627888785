import React from "react";
import { Box, BoxProps } from "../box/Box";
import { Body1 } from "./avatar-parts/bodies/Body1";
import { Body2 } from "./avatar-parts/bodies/Body2";
import { Body3 } from "./avatar-parts/bodies/Body3";
import { Body4 } from "./avatar-parts/bodies/Body4";
import { Body5 } from "./avatar-parts/bodies/Body5";
import { Body6 } from "./avatar-parts/bodies/Body6";
import { Head1 } from "./avatar-parts/heads/Head1";
import { Head2 } from "./avatar-parts/heads/Head2";
import { Head3 } from "./avatar-parts/heads/Head3";
import { Head4 } from "./avatar-parts/heads/Head4";

/**
 *
 * @param input Deterministically returns a positive linearly distributed integer from a string.
 * @returns
 */
function hash(input: string) {
  let hash = 0;
  let i: number;
  let chr: number;
  if (input.length === 0) return hash;
  for (i = 0; i < input.length; i++) {
    chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return Math.abs(hash);
}

function distinct(input: number, set: string[], distinctFrom: string[]) {
  let picked;
  let currentInput = input;
  do {
    picked = set[currentInput % set.length];
    currentInput = hash(`${currentInput}`);
  } while (distinctFrom.includes(picked));

  return picked;
}

const backgroundColors = [
  "#4C75B7",
  "#003160",
  "#F4BD48",
  "#E88E5A",
  "#A84E2B",
  "#751F53",
  "#F7CC9D",
  "#CD8011",
];

const noseColors = ["#CD8011", "#7FACC6", "#A84E2B", "#E9EEF6"];
const skinColors = ["#E9EEF6", "#CD8011", "#7FACC6", "#A84E2B"];
const hairColors = ["#003160", "#751F53", "#F4BD48", "#4C75B7"];

const heads = [Head1, Head2, Head3, Head4];
const bodies = [Body1, Body2, Body3, Body4, Body5, Body6];

export interface AvatarProps extends Omit<BoxProps, "color"> {
  id: string;
  size: string;
}

export const Avatar: React.FunctionComponent<AvatarProps> = ({
  id,
  size,
  ...boxProps
}) => {
  const headHash = hash(id);

  const Head = heads[headHash % heads.length];

  const bodyHash = hash(`${id}${headHash}`);
  const Body = bodies[bodyHash % bodies.length];

  const bgHash = hash(`${id}${bodyHash}`);
  const bg = backgroundColors[bgHash % backgroundColors.length];

  const noseHash = hash(`${id}${bgHash}`);
  const noseColor = distinct(noseHash, noseColors, [bg]);

  const skinHash = hash(`${id}${noseHash}`);
  const skinColor = distinct(skinHash, skinColors, [bg, noseColor]);

  const hairHash = hash(`${id}${skinHash}`);
  const hairColor = distinct(hairHash, hairColors, [bg, skinColor]);

  const bodyColorHash = hash(`${id}${hairHash}`);
  const bodyColor = distinct(bodyColorHash, backgroundColors, [bg]);

  return (
    <Box
      borderRadius="50%"
      bg={bg}
      position="relative"
      width={size}
      height={size}
      overflow="hidden"
      as="span"
      display="inline-block"
      {...boxProps}
    >
      <Head noseColor={noseColor} skinColor={skinColor} hairColor={hairColor} />
      <Body bodyColor={bodyColor} />
    </Box>
  );
};
