import { useRef } from "react";

const defaultLongTapDurationMs = 500;

export function useLongPress(
  callback?: () => void,
  longTapDurationMs = defaultLongTapDurationMs
) {
  const timeoutRef = useRef<null | NodeJS.Timer>();

  const onTouchStart = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    timeoutRef.current = setTimeout(() => callback?.(), longTapDurationMs);
  };

  const onTouchEnd = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  return { onTouchStart, onTouchEnd };
}
