import React, { PropsWithChildren, useMemo } from "react";
import styled from "styled-components";
import { shuffle } from "../../../utils/shuffle";
import { Box, BoxProps } from "../../base/box/Box";
import { Image } from "../../base/image/Image";
import { Marquee } from "../marquee/Marquee";

export interface ParallaxStripsProps extends Omit<BoxProps, "color"> {
  className?: string;
  images: string[];
  imageRotationDegrees?: number;
  imageSize?: string;
  gap?: string;
}

const StyledImage = styled(Image)<{ $rotation: number }>`
  transform: rotate(${({ $rotation }) => $rotation}deg);
`;

export const SingleStrip: React.FunctionComponent<{
  images: string[];
  speed: number;
  up?: boolean;
  imageRotationDegrees: number;
  imageSize: string;
  gap: string;
}> = ({ images, speed, up, imageRotationDegrees, imageSize, gap }) => {
  const shuffledImages = useMemo(() => shuffle(images), [images.join()]);

  return (
    <Marquee speed={speed} direction={up ? "right" : "left"}>
      {shuffledImages.map((image, i) => (
        <StyledImage
          $rotation={imageRotationDegrees}
          key={`${image}${i}`}
          src={image}
          width={imageSize}
          height={imageSize}
          objectFit="cover"
          borderRadius="1.5rem"
          marginLeft="1.5rem"
          marginTop={gap}
        />
      ))}
    </Marquee>
  );
};

export const ParallaxStrips: React.FunctionComponent<
  PropsWithChildren<ParallaxStripsProps>
> = ({
  images,
  className,
  imageRotationDegrees = 0,
  imageSize = "15rem",
  gap = "3rem",
  ...boxProps
}) => {
  return (
    <Box {...boxProps} className={className}>
      <SingleStrip
        imageSize={imageSize}
        imageRotationDegrees={imageRotationDegrees}
        images={images}
        gap={gap}
        speed={9}
      />
      <SingleStrip
        imageSize={imageSize}
        imageRotationDegrees={imageRotationDegrees}
        images={images}
        gap={gap}
        speed={4}
      />
      <SingleStrip
        imageSize={imageSize}
        imageRotationDegrees={imageRotationDegrees}
        images={images}
        gap={gap}
        speed={5}
        up={true}
      />
    </Box>
  );
};
