import { motion } from "framer-motion";
import React, { CSSProperties } from "react";
import styled from "styled-components";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";

export interface FloatingSnitchProps {
  width?: string | number;
  height?: string | number;
  objectFit?: CSSProperties["objectFit"];
  color?: ThemeColor | Color;
}

const StyledSvg = styled.svg<{ $objectFit?: CSSProperties["objectFit"] }>`
  object-fit: ${({ $objectFit }) => $objectFit ?? "contain"};
`;

const container = {
  small: { opacity: 0 },
  large: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  small: { scale: 0 },
  large: { scale: 1 },
};

const transitionCommon = {
  repeat: Infinity,
  repeatType: "mirror" as const,
  type: "easyInOut",
  duration: 1,
};

export const FloatingSnitch: React.FunctionComponent<FloatingSnitchProps> = ({
  width,
  height,
  objectFit,
  color = "success.success50",
}) => {
  const hexColor = useThemeColor(color);

  return (
    <StyledSvg
      $objectFit={objectFit}
      width={width}
      height={height}
      viewBox="0 0 535 434"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.g variants={container} initial="small" animate="large">
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M198.105 345.874C197.877 345.831 197.263 345.71 196.673 345.512C195.653 345.17 195.066 344.872 195.002 344.841C194.58 344.625 194.06 344.352 193.365 343.862C192.922 343.552 192.355 343.088 191.97 342.701C191.482 342.208 190.576 341.301 189.742 339.726C189.482 339.24 189.186 338.557 189.007 338.036C188.829 337.516 188.655 336.787 188.536 336.246C188.418 335.706 188.36 334.941 188.349 334.38C188.337 333.82 188.397 333.04 188.466 332.471C188.511 332.073 188.611 331.557 188.721 331.176C188.882 330.612 189.131 329.874 189.352 329.338C189.641 328.636 190.787 326.2 193.433 324.342C196.411 322.252 199.259 322.187 200.199 322.169C200.699 322.157 202.019 322.18 203.607 322.639C205.251 323.109 206.368 323.816 206.739 324.05C207.447 324.493 208.499 325.278 209.505 326.496C209.859 326.926 210.29 327.53 210.574 328.009C210.859 328.487 211.172 329.154 211.393 329.658C211.615 330.162 211.824 330.878 211.951 331.416C212.078 331.954 212.171 332.706 212.233 333.261C212.232 333.274 212.364 334.527 212.096 336.116C211.552 339.31 209.795 342.091 207.139 343.943C205.364 345.182 203.625 345.648 202.961 345.791C201.259 346.156 199.806 346.186 198.102 345.879C197.856 345.833 197.61 345.773 197.362 345.705C197.607 345.778 197.856 345.833 198.102 345.879"
          fill={hexColor}
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M127.267 304.975C127.038 304.933 126.425 304.811 125.834 304.614C124.815 304.271 124.227 303.973 124.163 303.943C123.741 303.726 123.221 303.454 122.526 302.964C122.083 302.653 121.517 302.189 121.131 301.803C120.643 301.309 119.737 300.403 118.903 298.827C118.643 298.342 118.347 297.658 118.169 297.138C117.99 296.617 117.816 295.888 117.698 295.348C117.579 294.807 117.521 294.042 117.51 293.482C117.498 292.921 117.558 292.142 117.627 291.573C117.672 291.175 117.772 290.658 117.883 290.277C118.044 289.714 118.292 288.975 118.513 288.439C118.802 287.737 119.948 285.301 122.594 283.443C125.572 281.354 128.42 281.288 129.361 281.27C129.86 281.258 131.18 281.282 132.769 281.74C134.412 282.211 135.529 282.917 135.9 283.152C136.608 283.595 137.66 284.38 138.667 285.597C139.02 286.027 139.451 286.631 139.735 287.11C140.02 287.589 140.333 288.255 140.555 288.759C140.776 289.263 140.985 289.979 141.112 290.517C141.239 291.056 141.332 291.807 141.394 292.362C141.394 292.376 141.525 293.628 141.257 295.217C140.713 298.411 138.956 301.193 136.301 303.045C134.525 304.283 132.786 304.75 132.122 304.893C130.42 305.258 128.967 305.287 127.264 304.981C127.017 304.934 126.771 304.874 126.523 304.806C126.768 304.879 127.017 304.934 127.264 304.981"
          fill={hexColor}
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M91.3847 339.229C91.2695 339.204 90.9678 339.146 90.6685 339.048C90.1587 338.877 89.8677 338.73 89.837 338.712C89.6259 338.604 89.3635 338.466 89.0171 338.225C88.793 338.068 88.5149 337.839 88.3196 337.644C88.0768 337.401 87.6241 336.942 87.2015 336.158C87.0704 335.911 86.9235 335.573 86.8328 335.315C86.7422 335.058 86.6527 334.692 86.5973 334.42C86.542 334.149 86.5091 333.768 86.5045 333.491C86.4998 333.215 86.5287 332.822 86.5616 332.54C86.5867 332.342 86.6342 332.082 86.6909 331.889C86.7715 331.608 86.8943 331.241 87.0035 330.969C87.1439 330.619 87.7238 329.401 89.0481 328.47C90.533 327.426 91.9627 327.39 92.4329 327.381C92.6827 327.375 93.3453 327.388 94.1384 327.613C94.9601 327.848 95.5227 328.2 95.7015 328.317C96.0516 328.54 96.5825 328.935 97.0823 329.538C97.2606 329.751 97.4729 330.058 97.6181 330.292C97.7632 330.527 97.9153 330.867 98.0302 331.118C98.1452 331.369 98.2457 331.728 98.3092 331.998C98.3726 332.267 98.4216 332.644 98.4475 332.919C98.4475 332.919 98.5131 333.551 98.3802 334.35C98.1094 335.944 97.2297 337.338 95.9003 338.266C95.0087 338.887 94.143 339.119 93.8069 339.191C92.9547 339.376 92.2296 339.389 91.3788 339.239C91.2555 339.216 91.1352 339.188 91.0126 339.151C91.1352 339.188 91.2555 339.216 91.3788 339.239"
          fill={hexColor}
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M227.432 291.112C227.316 291.086 227.015 291.028 226.715 290.931C226.206 290.759 225.915 290.612 225.884 290.594C225.673 290.486 225.41 290.348 225.064 290.107C224.84 289.95 224.562 289.722 224.366 289.527C224.124 289.284 223.671 288.824 223.248 288.04C223.117 287.793 222.97 287.455 222.88 287.198C222.789 286.94 222.7 286.574 222.644 286.303C222.589 286.032 222.556 285.65 222.551 285.374C222.547 285.098 222.576 284.704 222.608 284.422C222.634 284.225 222.681 283.965 222.738 283.772C222.818 283.49 222.941 283.123 223.05 282.851C223.191 282.501 223.771 281.284 225.095 280.352C226.58 279.308 228.01 279.272 228.48 279.263C228.73 279.257 229.392 279.27 230.185 279.496C231.007 279.731 231.57 280.083 231.748 280.2C232.099 280.422 232.629 280.818 233.129 281.421C233.307 281.633 233.52 281.941 233.665 282.175C233.81 282.409 233.962 282.75 234.077 283.001C234.192 283.252 234.293 283.611 234.356 283.88C234.419 284.149 234.468 284.526 234.494 284.801C234.494 284.801 234.56 285.434 234.427 286.233C234.156 287.827 233.277 289.22 231.947 290.149C231.056 290.769 230.19 291.001 229.854 291.074C229.002 291.259 228.276 291.271 227.426 291.122C227.302 291.099 227.182 291.07 227.059 291.034C227.182 291.07 227.302 291.099 227.426 291.122"
          fill={hexColor}
        />
      </motion.g>
      <motion.path
        animate={{ scale: [0.9, 1.1, 0.9] }}
        transition={{
          repeat: Infinity,
          duration: 2,
        }}
        opacity="0.1"
        d="M365.073 318.375C363.711 318.837 360.086 320.074 356.325 320.844C349.816 322.177 345.789 322.39 345.351 322.408C342.437 322.538 338.812 322.704 333.606 322.23C330.266 321.922 325.83 321.194 322.596 320.317C318.486 319.204 310.84 317.125 301.535 311.321C298.645 309.514 294.973 306.808 292.396 304.575C289.808 302.336 286.663 299.002 284.365 296.461C282.067 293.92 279.408 290.052 277.619 287.085C275.831 284.118 273.746 279.8 272.348 276.555C271.383 274.31 270.317 271.213 269.713 268.843C268.83 265.355 267.877 260.647 267.403 257.081C266.781 252.45 265.401 235.925 273.805 217.838C283.252 197.511 298.278 188.378 303.217 185.376C305.835 183.788 312.978 179.82 322.839 177.362C333.073 174.81 341.217 175.129 343.894 175.23C349.041 175.432 357.084 176.373 366.187 179.743C369.403 180.934 373.566 182.859 376.557 184.523C379.536 186.181 383.268 188.799 386.022 190.801C388.781 192.809 392.098 195.983 394.432 198.477C396.789 200.994 399.578 204.666 401.616 207.449C401.663 207.515 406.23 213.804 409.688 223.121C416.642 241.836 415.807 262.104 407.337 280.185C401.675 292.279 393.833 300.121 390.724 302.928C382.758 310.13 375.088 314.75 365.079 318.375C363.622 318.884 362.13 319.334 360.602 319.713C362.13 319.334 363.622 318.884 365.079 318.375"
        fill="white"
      />
      <motion.g
        animate={{ rotate: [-2, 2, -2] }}
        transition={{
          repeat: Infinity,
          duration: 3.2,
        }}
      >
        <path
          d="M355.976 292.509C355.117 292.8 352.843 293.576 350.486 294.061C346.399 294.896 343.87 295.033 343.592 295.044C341.762 295.127 339.487 295.234 336.218 294.932C334.121 294.736 331.338 294.28 329.306 293.73C326.724 293.031 321.921 291.728 316.081 288.079C314.263 286.948 311.959 285.242 310.342 283.845C308.725 282.447 306.741 280.344 305.296 278.751C303.851 277.158 302.187 274.73 301.061 272.864C299.936 270.998 298.627 268.286 297.751 266.249C297.14 264.839 296.471 262.896 296.098 261.404C295.541 259.212 294.949 256.257 294.647 254.018C294.256 251.11 293.391 240.734 298.669 229.374C304.603 216.611 314.038 210.872 317.135 208.988C318.776 207.987 323.265 205.5 329.46 203.96C335.886 202.361 340.998 202.556 342.68 202.622C345.908 202.746 350.96 203.338 356.681 205.453C358.7 206.199 361.312 207.407 363.196 208.455C365.079 209.504 367.407 211.138 369.142 212.4C370.877 213.661 372.956 215.657 374.425 217.221C375.894 218.784 377.659 221.106 378.938 222.853C378.968 222.895 381.834 226.845 384.008 232.691C388.373 244.441 387.852 257.169 382.533 268.523C378.974 276.116 374.052 281.037 372.097 282.802C367.093 287.321 362.278 290.223 355.994 292.504C355.076 292.823 354.14 293.108 353.181 293.345C354.14 293.108 355.076 292.823 355.994 292.504"
          fill={hexColor}
        />
        <g opacity="0.8">
          <path
            d="M118.046 143.994C130.513 167.311 156.809 191.286 191.38 208.154C225.95 225.022 261.036 230.986 287.084 226.455C274.617 203.137 248.32 179.163 213.75 162.295C179.179 145.427 144.093 139.463 118.046 143.994Z"
            fill={hexColor}
          />
          <path
            d="M256.955 352.215C267.492 349.479 278.602 341.312 286.74 329.176C294.878 317.041 298.218 303.661 296.749 292.876C286.213 295.612 275.102 303.78 266.964 315.915C258.827 328.051 255.486 341.43 256.955 352.215Z"
            fill={hexColor}
          />
          <path
            d="M386.845 282.418C389.581 292.954 397.748 304.065 409.884 312.203C422.019 320.34 435.399 323.681 446.184 322.212C443.447 311.676 435.28 300.565 423.145 292.427C411.009 284.289 397.63 280.949 386.845 282.418Z"
            fill={hexColor}
          />
          <path
            d="M445.71 37.9432C421.67 49.1133 398.642 73.562 384.464 106.202C370.291 138.847 368.153 172.364 376.403 197.553C400.443 186.382 423.47 161.934 437.649 129.294C451.822 96.6485 453.96 63.1322 445.71 37.9432Z"
            fill={hexColor}
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M377.73 237.258C379.039 253.439 360.773 264.568 345.215 252.183C330.669 240.605 332.41 218.134 348.182 214.018C359.091 211.169 376.308 219.674 377.736 237.258"
            fill="white"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M318.255 265.187C298.408 239.975 311.734 199.523 346.553 203.538C346.565 203.538 348.229 203.752 349.289 203.947C350.586 204.202 352.292 204.64 353.577 204.954C354.608 205.292 356 205.706 357.018 206.085L360.288 207.447C361.247 207.933 362.538 208.549 363.48 209.07L366.506 210.918C367.377 211.546 368.562 212.345 369.403 213.009L372.121 215.307C372.885 216.065 373.939 217.042 374.674 217.824L377.025 220.536C377.671 221.413 378.571 222.556 379.181 223.456L381.106 226.554C381.615 227.537 382.332 228.834 382.8 229.841L384.239 233.288C384.594 234.372 385.109 235.805 385.417 236.907L386.3 240.602L386.341 240.827L386.892 244.523C386.957 245.512 387.082 246.786 387.129 247.84C387.011 242.734 386.045 237.629 384.215 232.696C382.042 226.844 379.175 222.894 379.145 222.858C377.866 221.111 376.113 218.801 374.632 217.226C373.163 215.662 371.085 213.666 369.349 212.405C367.62 211.143 365.275 209.503 363.403 208.46C361.525 207.412 358.908 206.204 356.888 205.457C351.173 203.343 346.121 202.751 342.887 202.626C341.205 202.561 336.094 202.36 329.668 203.965C323.473 205.511 318.989 207.998 317.343 208.993C314.245 210.877 304.804 216.616 298.876 229.379C293.599 240.733 294.463 251.109 294.854 254.023C295.156 256.262 295.749 259.223 296.305 261.409C296.684 262.895 297.354 264.844 297.958 266.253C298.834 268.291 300.12 270.968 301.269 272.869C302.394 274.729 304.04 277.145 305.503 278.756C306.948 280.349 308.921 282.44 310.549 283.849C312.166 285.247 314.476 286.953 316.288 288.084C322.134 291.733 326.937 293.035 329.514 293.734C331.545 294.285 334.329 294.741 336.425 294.937C339.695 295.239 341.975 295.132 343.799 295.049C344.071 295.037 346.606 294.901 350.693 294.066C353.056 293.58 355.33 292.81 356.183 292.514C362.467 290.234 367.282 287.338 372.287 282.813C374.241 281.048 379.163 276.126 382.723 268.534C384.606 264.512 385.885 260.325 386.561 256.06C380.691 288.072 340.814 293.894 318.225 265.193L318.255 265.187Z"
            fill="#221C16"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.864 248.739C387.864 274.817 366.724 295.957 340.646 295.957C314.568 295.957 293.428 274.817 293.428 248.739C293.428 222.661 314.568 201.521 340.646 201.521C366.724 201.521 387.864 222.661 387.864 248.739ZM310.894 215.393C304.76 222.412 300.363 232.884 302.37 247.493C306.241 275.682 339.65 296.401 363.96 286.872C376.781 279.017 385.335 264.877 385.335 248.739C385.335 224.058 365.327 204.05 340.646 204.05C329.22 204.05 318.796 208.339 310.894 215.393Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M384.464 106.202C398.19 74.6039 420.209 50.6826 443.411 39.0534C444.176 38.6699 444.942 38.2998 445.71 37.9432C445.973 38.7474 446.226 39.5601 446.468 40.3809C453.811 65.2714 451.369 97.6907 437.649 129.294C423.923 160.892 401.904 184.813 378.702 196.442C377.937 196.826 377.17 197.196 376.403 197.553C376.14 196.748 375.887 195.936 375.645 195.115C368.301 170.224 370.743 137.805 384.464 106.202ZM377.951 193.988C370.954 169.825 373.327 138.206 386.784 107.209C400.247 76.2178 421.728 52.8931 444.162 41.5076C451.158 65.6702 448.786 97.2897 435.329 128.286C421.866 159.278 400.384 182.603 377.951 193.988Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.046 143.994C118.463 144.775 118.897 145.557 119.345 146.34C132.284 168.91 157.968 191.851 191.38 208.154C224.792 224.456 258.685 230.574 284.435 226.875C285.328 226.747 286.211 226.607 287.084 226.455C286.666 225.673 286.233 224.891 285.784 224.109C272.845 201.539 247.162 178.597 213.75 162.295C180.338 145.992 146.444 139.875 120.695 143.574C119.802 143.702 118.919 143.842 118.046 143.994ZM192.489 205.88C159.827 189.944 134.819 167.686 122.039 145.942C147.04 142.627 179.978 148.632 212.64 164.568C245.303 180.505 270.31 202.763 283.09 224.507C258.089 227.822 225.151 221.817 192.489 205.88Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M256.955 352.215C257.771 352.003 258.59 351.759 259.411 351.483C269.194 348.191 279.232 340.372 286.74 329.176C294.248 317.98 297.672 305.725 297.004 295.426C296.948 294.562 296.863 293.711 296.749 292.876C295.934 293.088 295.115 293.332 294.294 293.609C284.511 296.901 274.472 304.719 266.964 315.915C259.457 327.111 256.032 339.366 256.701 349.665C256.757 350.53 256.841 351.38 256.955 352.215ZM284.639 327.767C277.534 338.362 268.173 345.685 259.19 348.882C258.738 339.36 261.96 327.919 269.065 317.324C276.17 306.729 285.532 299.407 294.514 296.209C294.966 305.732 291.744 317.172 284.639 327.767Z"
          fill="#221C16"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.577 284.873C387.301 284.052 387.057 283.233 386.845 282.418C387.68 282.304 388.53 282.219 389.395 282.163C399.694 281.495 411.949 284.919 423.145 292.427C434.341 299.935 442.159 309.973 445.451 319.756C445.728 320.577 445.972 321.396 446.184 322.212C445.349 322.326 444.498 322.41 443.634 322.467C433.334 323.135 421.08 319.71 409.884 312.203C398.688 304.695 390.869 294.656 387.577 284.873ZM390.178 284.653C393.375 293.635 400.698 302.997 411.293 310.102C421.888 317.207 433.328 320.429 442.851 319.977C439.653 310.994 432.331 301.633 421.736 294.528C411.141 287.423 399.7 284.201 390.178 284.653Z"
          fill="#221C16"
        />
      </motion.g>
    </StyledSvg>
  );
};
