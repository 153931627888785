import get from "lodash.get";
import { colorThemeLight } from "../theme/color-themes/color-theme-light";
import { ThemeColor } from "../theme/theme";
import { Color } from "./types";

// https://stackoverflow.com/a/56266358/1293101
export const isCssColor = (color: string): color is Color => {
  const s = new Option().style;
  s.color = color;
  return s.color !== "";
};

export const isThemeColor = (color: string): color is ThemeColor => {
  return !!get(colorThemeLight, color);
};

export function isValidThemeColor(color: string): boolean {
  if (get(colorThemeLight, color)) {
    return true;
  }

  if (isCssColor(color)) {
    return true;
  }

  return false;
}
