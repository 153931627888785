import { format } from "date-fns";
import React from "react";
import styled from "styled-components";
import { formatCurrency, formatNumber } from "../../../utils/number-utils";
import { Box, BoxProps } from "../../base/box/Box";
import { Image } from "../../base/image/Image";

export enum LeanTokenListVariant {
  DEFAULT = "DEFAULT",
  SMALL = "SMALL",
}

export interface LeanTokenListProps extends Omit<BoxProps, "color"> {
  baseCurrency: string;
  boldNames?: boolean;
  tokens: {
    name: string;
    image: string;
    price?: number;
    change?: number;
  }[];
}

const StyledBox = styled(Box)`
  &:last-of-type {
    border-bottom: none;
  }
`;

export const LeanTokenList: React.FunctionComponent<LeanTokenListProps> = ({
  baseCurrency,
  tokens,
  boldNames,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {tokens.map(({ name, image, price, change }) => (
        <StyledBox
          display="flex"
          alignItems="center"
          key={image}
          borderBottomColor="blacks.black200"
          borderBottomStyle="solid"
          borderBottomWidth="1px"
          minHeight="4rem"
        >
          <Image
            src={image}
            alt={`${name} logo`}
            mr={3}
            width="2.5rem"
            height="2.5rem"
          />

          <Box
            flex="1"
            overflow="hidden"
            textOverflow="ellipsis"
            fontWeight={boldNames ? "600" : undefined}
          >
            {name}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            width="max-content"
            my={3}
            gridGap={1}
          >
            {price != null && (
              <Box>
                {baseCurrency} {formatCurrency(price)}
              </Box>
            )}
            {change != null && (
              <Box color={change > 0 ? "success.success50" : "blacks.black600"}>
                {formatNumber(change, 2, false)} %
              </Box>
            )}
          </Box>
        </StyledBox>
      ))}
    </Box>
  );
};
