import React, { PropsWithChildren, useEffect, useMemo } from "react";
import ReactModal from "react-modal";
import { ThemeProvider } from "styled-components";
import { AppHeaderContextProvider } from "../components/navigation/app-header/AppHeaderContext";
import { dummyRouter } from "../components/base/router/dummy-router";
import { DummyRouterLink } from "../components/base/router/DummyRouterLink";
import { RouterLinkComponent } from "../components/base/router/RouterLink";
import { Router } from "../components/base/router/router";
import { RouterProvider } from "../components/base/router/RouterProvider";
import { ToastContainer } from "../components/notification/toast/ToastContainer";
import { Native, NativeProvider } from "../context/native/NativeProvider";
import { useIsDarkMode } from "../hooks/use-is-dark-mode";
import { GlobalStyles } from "./GlobalStyles";
import { HtmlHead } from "./HtmlHead";
import { getTheme } from "./theme";
import { ThemeId } from "./theme-id";
import { NotificationBottomSheetProvider } from "../components/notification/notification-bottom-sheet/NotificationBottomSheetProvider";
import { NotificationOverlayProvider } from "../components/notification/notification-overlay/NotificationOverlayProvider";

interface NobankThemeProviderProps {
  fontPath?: string;
  theme?: ThemeId;
  enableBodyScroll?: boolean;
  modalAppElement?: HTMLElement | string;
  linkComponent?: RouterLinkComponent;
  router?: Router;
  native?: Native;
}

export const NobankThemeProvider: React.FunctionComponent<
  PropsWithChildren<NobankThemeProviderProps>
> = ({
  children,
  fontPath,
  theme: themeSelection,
  enableBodyScroll,
  modalAppElement,
  linkComponent = DummyRouterLink,
  router = dummyRouter,
  native,
}) => {
  const isDarkMode = useIsDarkMode();

  // by default the theme is dark if the user is in dark mode, otherwise in light mode.
  // Can be overridden by explicitly passing a theme id.
  const theme = useMemo(
    () =>
      getTheme(themeSelection ?? (isDarkMode ? ThemeId.DARK : ThemeId.LIGHT)),
    [isDarkMode, themeSelection]
  );

  useEffect(() => {
    ReactModal.setAppElement(modalAppElement ?? "#root");
  }, [modalAppElement]);

  return (
    <ThemeProvider theme={theme}>
      <HtmlHead fontPath={fontPath ?? "/static/fonts"} />
      <GlobalStyles
        enableBodyScroll={enableBodyScroll}
        disableTextSelection={!!native?.isNativePlatform}
      />
      <AppHeaderContextProvider>
        <RouterProvider component={linkComponent} router={router}>
          <NativeProvider native={native}>
            <NotificationBottomSheetProvider>
              <NotificationOverlayProvider>
                {children}
              </NotificationOverlayProvider>
            </NotificationBottomSheetProvider>
            <ToastContainer />
          </NativeProvider>
        </RouterProvider>
      </AppHeaderContextProvider>
    </ThemeProvider>
  );
};
