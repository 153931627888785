import React from "react";
import { HeadProps } from "./head-props";
import { HeadSvg } from "./HeadSvg";

export const Head1: React.FunctionComponent<HeadProps> = ({
  hairColor,
  noseColor,
  skinColor,
}) => {
  return (
    <HeadSvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4234_40391"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4234_40391)">
        <path
          d="M179.93 335.377C179.93 335.377 151.089 312.384 146.45 308.149C141.811 303.913 141.408 298.67 141.811 294.232C142.215 290.4 144.837 282.535 150.484 280.114C155.929 278.097 182.956 265.189 201.713 259.542L179.93 335.377Z"
          fill={noseColor}
        />
        <path
          d="M197.88 259.542C192.233 275.677 180.938 297.863 175.291 304.72C169.644 311.981 146.248 306.536 146.248 306.536L180.535 336.184L201.511 259.542H197.88Z"
          fill="#221C16"
        />
        <path
          d="M203.931 256.92C203.931 256.92 155.728 277.896 150.282 280.114C136.366 285.762 138.181 300.283 146.248 308.149C150.685 312.586 179.728 335.377 179.728 335.377"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M225.31 192.784C207.562 251.475 174.283 349.495 173.275 398.102C172.266 446.91 210.184 462.44 226.924 467.684C238.017 471.113 261.211 475.348 288.035 477.769L244.672 596.966L473.79 608.463C473.79 608.463 486.496 484.223 496.581 436.019C511.304 365.025 555.272 249.256 511.102 192.178C436.679 96.3767 242.655 135.504 225.31 192.784Z"
          fill={skinColor}
        />
        <path
          d="M344.508 482.206C343.903 490.475 330.188 499.753 323.33 503.182C314.053 508.022 299.128 510.241 287.833 520.527C276.741 530.409 270.488 551.183 267.463 560.058C264.438 568.932 250.924 584.664 250.924 584.664L286.825 480.794L344.508 482.206Z"
          fill="#221C16"
        />
        <path
          d="M223.293 195.809C203.528 253.693 172.266 352.521 171.258 401.127C170.249 449.936 208.167 465.466 224.907 470.71C243.26 476.357 295.699 484.425 341.482 482.206"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M286.825 480.996C280.169 500.156 244.672 596.966 244.672 596.966"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M483.471 537.872L473.79 608.261"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M176.501 363.613C191.83 365.832 214.015 360.789 228.133 351.714"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M274.925 318.234C289.629 318.234 301.548 306.315 301.548 291.611C301.548 276.908 289.629 264.988 274.925 264.988C260.222 264.988 248.303 276.908 248.303 291.611C248.303 306.315 260.222 318.234 274.925 318.234Z"
          fill="#221C16"
        />
        <path
          d="M255.563 274.468C255.16 274.468 254.958 274.468 254.555 274.468C250.723 279.106 248.303 284.955 248.303 291.611C248.303 293.225 248.504 294.636 248.706 296.048C250.723 297.46 252.941 298.267 255.563 298.267C262.017 298.267 267.463 293.023 267.463 286.367C267.463 279.711 262.017 274.468 255.563 274.468Z"
          fill="white"
        />
        <path
          d="M221.478 285.762C239.025 272.652 260.807 264.786 284.405 264.786C303.767 264.786 321.918 270.03 337.448 279.106"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M599.441 320.856C600.45 321.864 601.256 322.671 602.063 323.679C609.324 331.545 614.568 341.024 617.391 351.31C620.215 361.596 620.618 372.488 618.4 382.975C616.181 393.463 611.542 403.346 604.685 411.615C598.029 419.884 589.357 426.54 579.474 430.776C579.676 434.406 579.676 438.238 579.474 441.868C578.264 465.466 568.785 487.853 552.851 504.997C536.918 522.342 515.337 533.435 491.941 536.662C487.303 537.267 482.664 537.67 477.823 537.67C476.21 537.67 474.596 537.67 472.983 537.468C451.806 536.258 431.435 528.594 414.695 515.081C398.157 501.77 386.257 483.416 380.61 462.844C374.962 442.272 376.173 420.489 383.635 400.522C391.097 380.555 404.812 363.613 422.561 351.915C405.216 339.612 391.299 323.276 382.223 304.115C372.946 284.955 368.71 263.979 369.92 242.802C370.122 240.785 370.122 238.567 370.324 236.55C359.231 239.777 347.533 241.189 336.037 240.987C320.708 240.18 305.582 235.541 292.472 227.474C279.362 219.406 268.269 208.314 260.404 195.002C258.79 195.607 257.177 196.011 255.563 196.414C244.47 199.439 232.974 199.439 221.881 196.616C210.788 193.792 200.704 188.346 192.233 180.481C183.964 172.615 177.712 162.934 174.081 152.043C170.451 141.151 169.846 129.655 171.863 118.361C174.081 107.066 178.922 96.7801 186.182 87.9058C193.443 79.2332 202.923 72.3758 213.41 68.3421C224.1 64.3083 235.596 62.8965 246.891 64.3083C258.185 65.9218 268.875 70.1573 278.152 77.0146C295.901 61.0813 318.893 52.6104 342.491 53.0138C366.29 53.4171 388.879 62.8965 406.022 79.4349C417.922 71.5691 432.04 67.9387 446.36 68.5437C476.411 70.1573 502.429 95.7716 509.69 124.008C531.271 126.428 551.641 134.496 569.188 147.404C586.735 160.11 600.651 177.254 609.526 197.019C618.4 216.784 622.232 238.365 620.215 259.946C619.005 282.131 611.744 302.905 599.441 320.856Z"
          fill={hairColor}
        />
        <path
          d="M284.606 180.077C282.791 184.716 279.362 193.187 284.001 204.885C287.833 214.969 298.321 228.281 308.204 233.726L308.405 235.34C308.405 235.34 289.85 228.482 275.934 214.767C262.017 201.053 260.807 195.204 260.807 195.204L284.606 180.077Z"
          fill="#221C16"
        />
        <path
          d="M445.351 366.639C435.67 363.412 425.989 369.664 414.493 374.908C404.812 379.345 392.106 390.64 387.265 400.926L385.652 401.127C385.652 401.127 391.299 382.169 404.005 367.446C416.913 352.722 422.561 351.109 422.561 351.109L445.351 366.639Z"
          fill="#221C16"
        />
        <path
          d="M606.097 309.964C597.222 304.922 586.735 298.871 577.86 298.871L599.441 320.653L606.097 309.964Z"
          fill="#221C16"
        />
        <path
          d="M465.722 127.84C480.647 124.21 492.95 110.293 487.504 98.5954C482.26 87.0992 473.184 79.8384 457.453 72.5776C457.453 72.5776 479.033 74.7962 491.538 88.7127C504.043 102.629 507.673 114.529 509.286 121.386L508.883 125.218C508.883 125.218 484.076 123 468.747 129.05L465.722 127.84Z"
          fill="#221C16"
        />
        <path
          d="M599.441 320.856C600.45 321.864 601.256 322.671 602.063 323.679C609.324 331.545 614.568 341.024 617.391 351.31C620.215 361.596 620.618 372.488 618.4 382.975C616.181 393.463 611.542 403.346 604.685 411.615C598.029 419.884 589.357 426.54 579.474 430.776C579.676 434.406 579.676 438.238 579.474 441.868C578.264 465.466 568.785 487.853 552.851 504.997C536.918 522.342 515.337 533.435 491.941 536.662C487.303 537.267 482.664 537.67 477.823 537.67C476.21 537.67 474.596 537.67 472.983 537.468C451.806 536.258 431.435 528.594 414.695 515.081C398.157 501.77 386.257 483.416 380.61 462.844C374.962 442.272 376.173 420.489 383.635 400.522C391.097 380.555 404.812 363.613 422.561 351.915C405.216 339.613 391.299 323.276 382.223 304.115C372.946 284.955 368.71 263.979 369.92 242.802C370.122 240.785 370.122 238.567 370.324 236.55C359.231 239.777 347.533 241.189 336.037 240.987C320.708 240.18 305.582 235.541 292.472 227.474C279.362 219.406 268.269 208.314 260.404 195.002C258.79 195.607 257.177 196.011 255.563 196.414C244.47 199.439 232.974 199.439 221.881 196.616C210.788 193.792 200.704 188.346 192.233 180.481C183.964 172.615 177.712 162.934 174.081 152.043C170.451 141.151 169.846 129.655 171.863 118.361C174.081 107.066 178.922 96.7801 186.182 87.9058C193.443 79.2332 202.923 72.3758 213.41 68.3421C224.1 64.3083 235.596 62.8965 246.891 64.3083C258.185 65.9218 268.875 70.1573 278.152 77.0146C295.901 61.0813 318.893 52.6104 342.491 53.0138C366.29 53.4171 388.879 62.8965 406.022 79.4349C417.922 71.5691 432.04 67.9387 446.36 68.5437C476.411 70.1573 502.429 95.7716 509.69 124.008C531.271 126.428 551.641 134.496 569.188 147.404C586.735 160.11 600.651 177.254 609.526 197.019C618.4 216.784 622.232 238.365 620.215 259.946C619.005 282.131 611.744 302.905 599.441 320.856Z"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
        />
        <path
          d="M256.773 196.615C267.261 194.195 278.757 185.522 283.799 178.867"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M421.149 351.109C427.401 355.748 435.065 360.387 445.553 364.622L421.149 351.109Z"
          fill="#221C16"
        />
        <path
          d="M421.149 351.109C427.401 355.748 435.065 360.387 445.553 364.622"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M604.08 325.897C597.223 317.628 577.054 301.896 577.054 301.896L604.08 325.897Z"
          fill="#221C16"
        />
        <path
          d="M604.08 325.897C597.223 317.628 577.054 301.896 577.054 301.896"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
        <path
          d="M522.598 126.831C504.648 123.201 483.269 123.806 466.932 129.251"
          stroke="#221C16"
          strokeWidth={6}
          strokeMiterlimit={10}
          strokeLinecap="round"
        />
      </g>
    </HeadSvg>
  );
};
