import { motion } from "framer-motion";
import React from "react";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { BaseSvg, BaseSvgProps } from "../base-svg/BaseSvg";

export enum PaperPlaneVariant {
  DEFAULT = "default",
  WARNING = "warning",
}

export interface PaperPlaneProps extends BaseSvgProps {
  variant?: PaperPlaneVariant;
}

const container = {
  small: { opacity: 0 },
  large: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
    },
  },
};

const item = {
  small: { scale: 0 },
  large: { scale: 1 },
};

const transitionCommon = {
  repeat: Infinity,
  repeatType: "mirror" as const,
  type: "easyInOut",
  duration: 1,
};

const colors: Record<PaperPlaneVariant, ThemeColor | Color> = {
  [PaperPlaneVariant.DEFAULT]: "#96B6E9",
  [PaperPlaneVariant.WARNING]: "warning.warning20",
};

export const PaperPlane: React.FunctionComponent<PaperPlaneProps> = ({
  variant = PaperPlaneVariant.DEFAULT,
  ...props
}) => {
  const color = useThemeColor(colors[variant]);

  return (
    <BaseSvg
      {...(props as any)}
      viewBox="0 0 161 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.ellipse
        animate={{
          scale: [1.2, 1, 1.2],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
        opacity="0.4"
        cx="80.8335"
        cy="139.679"
        rx={15}
        ry="2.66667"
        fill="#221C16"
      />
      <motion.g
        animate={{
          translateY: [7, -7, 7],
          rotate: [5, 0, 5],
          originX: 1,
          originY: 0.5,
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
        }}
      >
        <path
          d="M44.3236 83.4757L70.2012 104.307L141.587 65.3404L44.3236 83.4757Z"
          fill={color}
        />
        <path
          d="M36.6813 75.8719L17.8335 58.3054L141.587 65.3403L36.6813 75.8719Z"
          fill={color}
        />
        <path
          d="M44.3237 83.4756L35.1549 104.199L59.0103 95.295L44.3237 83.4756Z"
          fill={color}
        />
        <path
          d="M17.7991 58.6892L16.9379 57.9353L21.2029 53.7589L141.567 65.0142L141.229 65.7455L17.7991 58.6892Z"
          fill={color}
        />
        <g style={{ mixBlendMode: "overlay" }} opacity="0.4">
          <path
            d="M44.2376 83.4003L53.8467 97.2227L35.1549 104.199L44.2376 83.4003Z"
            fill="#221C16"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.4">
          <path
            d="M17.7991 58.6892L16.9379 57.9353L21.2029 53.7589L141.567 65.0142L141.229 65.7455L17.7991 58.6892Z"
            fill="#221C16"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M53.9712 70.8924C56.4068 66.5138 57.4604 63.3343 58.0808 60.5308L34.9572 59.1675C34.8027 59.457 34.642 59.7519 34.4748 60.0524C32.2914 63.9756 30.3413 66.448 28.4811 68.3623L36.7298 75.9886L51.7196 74.4918C52.4599 73.4468 53.2103 72.2602 53.9712 70.8924Z"
            fill="white"
          />
          <path
            d="M73.8271 61.4591L62.417 60.7864C61.7948 64.0664 60.8589 67.6265 57.8433 73.0429C57.6738 73.3474 57.5059 73.6431 57.3393 73.9306L71.2454 72.542C72.3405 69.5802 72.7534 67.2273 73.1663 64.8744L73.1663 64.8741L73.1663 64.8739C73.3623 63.7573 73.5582 62.6408 73.8271 61.4591Z"
            fill="white"
          />
          <path
            d="M39.219 96.091C37.4092 99.3441 36.2858 101.88 35.5164 104.075L51.6441 98.0625C54.0681 93.9824 56.4652 91.9783 58.9613 89.8914C61.7944 87.5227 64.7551 85.0473 68.0279 79.3086L50.7113 82.5674C49.9774 83.2735 49.2542 83.9193 48.531 84.5651L48.5305 84.5655C45.6485 87.1391 42.7675 89.7118 39.219 96.091Z"
            fill="white"
          />
          <path
            d="M83.1986 62.0114L108.412 63.498C107.203 64.995 105.939 66.8276 104.586 69.2126L80.2722 71.6405C80.7352 68.8758 81.3517 65.9776 83.1986 62.0114Z"
            fill="white"
          />
          <path
            d="M64.3492 99.6736C64.8643 99.2089 65.3762 98.7647 65.8881 98.3204C68.8199 95.776 71.7521 93.2313 75.3001 86.8582C77.5363 82.8399 78.6079 79.8317 79.2489 77.1968L102.734 72.7772C100.588 77.3548 100.081 80.5112 99.5745 83.6682C99.301 85.3723 99.0274 87.0766 98.4961 89.0046L70.2497 104.423L64.3492 99.6736Z"
            fill="white"
          />
          <path
            d="M129.755 64.7566L114.109 63.8341C112.926 65.1093 111.699 66.6309 110.388 68.6335L127.333 66.9415C127.618 66.6834 127.903 66.4289 128.188 66.1745L128.191 66.1725C128.711 65.7079 129.231 65.2434 129.755 64.7566Z"
            fill="white"
          />
          <path
            d="M103.552 85.8655C103.528 86.0054 103.503 86.1452 103.478 86.2852L118.732 77.9591C118.78 77.8719 118.828 77.7843 118.877 77.6962C121.42 73.1299 123.619 70.5137 125.721 68.4515L108.544 71.6839C108.523 71.722 108.502 71.7602 108.48 71.7985C108.464 71.8271 108.448 71.8557 108.432 71.8844C104.886 78.2596 104.219 82.0628 103.552 85.8655Z"
            fill="white"
          />
        </g>
        <g style={{ mixBlendMode: "overlay" }} opacity="0.2">
          <path
            d="M34.6751 59.3497L57.7532 60.6412C57.3833 59.451 56.9757 58.3809 56.5573 57.3453L32.9644 55.1182C33.2265 55.7906 33.4956 56.441 33.7646 57.0913C34.0716 57.8332 34.3785 58.5751 34.6751 59.3497Z"
            fill="white"
          />
          <path
            d="M62.3605 60.899L74.6151 61.5847C74.3874 60.6461 74.1357 59.7881 73.8691 58.9794L61.3278 57.7955C61.6919 58.7638 62.0427 59.7762 62.3605 60.899Z"
            fill="white"
          />
          <path
            d="M106.95 63.3944L83.9716 62.1085C83.7005 61.3195 83.4146 60.5773 83.123 59.8531L106.567 62.0661C106.7 62.4922 106.828 62.9337 106.95 63.3944Z"
            fill="white"
          />
          <path
            d="M111.523 63.6501L123.727 64.333C123.681 64.1083 123.635 63.8883 123.587 63.6725L111.228 62.5058C111.33 62.8745 111.429 63.2551 111.523 63.6501Z"
            fill="white"
          />
        </g>
        <path
          d="M70.167 104.694L70.1239 104.044L73.4419 97.8221L141.689 65.6536L70.167 104.694Z"
          fill="#221C16"
        />
        <path
          d="M141.625 65.0039L141.567 65.0142L21.2029 53.7589L16.9379 57.9353L17.353 58.2987L36.7166 76.3532L34.621 104.611L34.5506 104.772L58.9521 95.6642L70.1704 104.695L141.692 65.6548L141.628 65.0053L141.625 65.0039ZM21.3808 54.2646L135.418 64.9276L68.3162 61.0918L17.7211 57.979L17.6505 57.9172L21.3792 54.2647L21.3808 54.2646ZM18.7811 58.7473L136.815 65.4951L36.7938 75.537L18.7795 58.7474L18.7811 58.7473ZM35.7627 103.629L44.3235 84.2789L51.2569 97.8458L35.7627 103.629ZM70.2374 103.92L59.6583 95.4032L59.151 94.994L45.0825 83.6656L139.646 66.0339L70.2375 103.922L70.2374 103.92Z"
          fill="#221C16"
        />
      </motion.g>
      <motion.g variants={container} initial="small" animate="large">
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.6"
          d="M22.142 35.7422C18.1464 35.7422 18.1405 41.7422 22.142 41.7422C26.1434 41.7422 26.1434 35.7422 22.142 35.7422Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          opacity="0.4"
          d="M145.606 84.3151C141.612 84.3151 141.606 90.3151 145.606 90.3151C149.606 90.3151 149.612 84.3151 145.606 84.3151Z"
          fill="white"
        />
        <motion.path
          variants={item}
          transition={{
            ...transitionCommon,
          }}
          d="M10.3982 114.169C3.7364 114.169 3.72991 124.169 10.3982 124.169C17.0665 124.169 17.0665 114.169 10.3982 114.169Z"
          fill="white"
        />
      </motion.g>
    </BaseSvg>
  );
};
