import React from "react";
import { BodyProps } from "./body-props";
import { BodySvg } from "./BodySvg";

export const Body2: React.FunctionComponent<BodyProps> = ({ bodyColor }) => {
  return (
    <BodySvg
      viewBox="0 0 720 720"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4251_46735"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={720}
        height={720}
      >
        <circle cx={360} cy={360} r={360} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4251_46735)">
        <rect
          x="16.0684"
          y="524.641"
          width={745}
          height={211}
          transform="rotate(6 16.0684 524.641)"
          fill={bodyColor}
          stroke="#221C16"
          strokeWidth={6}
        />
        <mask
          id="mask1_4251_46735"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x={-87}
          y={515}
          width={771}
          height={295}
        >
          <rect
            x="-60.6475"
            y="518.297"
            width={745}
            height={211}
            transform="rotate(6 -60.6475 518.297)"
            fill="#4C75B7"
          />
          <rect
            x="-60.6475"
            y="518.297"
            width={745}
            height={211}
            transform="rotate(6 -60.6475 518.297)"
            fill="white"
            fillOpacity="0.3"
          />
          <rect
            x="-60.6475"
            y="518.297"
            width={745}
            height={211}
            transform="rotate(6 -60.6475 518.297)"
            stroke="black"
            strokeWidth={6}
          />
        </mask>
        <g mask="url(#mask1_4251_46735)">
          <rect
            x="-26.7028"
            y="631.91"
            width={312}
            height={312}
            transform="rotate(-6 -26.7028 631.91)"
            stroke="#221C16"
            strokeWidth={6}
          />
          <rect
            x="339.297"
            y="342.91"
            width={312}
            height={312}
            transform="rotate(-6 339.297 342.91)"
            stroke="#221C16"
            strokeWidth={6}
          />
        </g>
      </g>
    </BodySvg>
  );
};
