import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { HarmonyGears } from "../../graphics/harmony-gears/HarmonyGears";
import { ProductCard } from "../product-card/ProductCard";

export interface ProductCardStakeOneProps extends Omit<BoxProps, "color"> {
  apyPercentage: string;
}

const HarmonyGearsContainer = styled(Box)`
  overflow: hidden;

  > svg {
    width: 100%;
    transform: scale(1.05) translate(2rem, 0.5rem);
  }
`;

export const ProductCardStakeOne: React.FunctionComponent<
  PropsWithChildren<ProductCardStakeOneProps>
> = ({ apyPercentage, ...boxProps }) => {
  return (
    <ProductCard
      {...boxProps}
      bg="success.success30"
      title="Stake ONE"
      subtitle="Staking"
      illustration={
        <HarmonyGearsContainer px="-1.5rem" mx="-1.5rem">
          <HarmonyGears withoutAnimation={true} />
        </HarmonyGearsContainer>
      }
      number={`${apyPercentage} %`}
      numberPostfix="APY"
      description="Stake your ONEs and earn rewards while supporting the Harmony consensus protocol."
    />
  );
};
