import React from "react";
import type { IconType } from "react-icons";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { noop } from "../../../utils/noop";
import { RouterLink } from "../../base/router/RouterLink";
import { useRouter } from "../../base/router/use-router";
import { useThemeColor } from "../../../hooks/use-theme-color";
import { hexToRgba } from "../../../utils/color-utils";

interface NavigationBarItem {
  text: string;
  Icon: IconType;
  active?: boolean;
}

interface NavigationBarLinkItem extends NavigationBarItem {
  to: string;
}

interface NavigationBarActionItem extends NavigationBarItem {
  key: string | number;
  onClick: () => void;
}

export interface NavigationBarProps extends Omit<BoxProps, "color"> {
  links: (NavigationBarLinkItem | NavigationBarActionItem)[];
}

const StyledTab = styled(Box)`
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
`;

const StyledList = styled(Box)`
  list-style: none;
`;

const StyledListItem = styled(Box)`
  &:hover {
    opacity: 0.8;
  }
`;

export const NavigationBar: React.FunctionComponent<NavigationBarProps> = ({
  links,
  ...boxProps
}) => {
  const router = useRouter();
  const black100 = useThemeColor("blacks.black100");

  return (
    <Box
      as="nav"
      bg={hexToRgba(black100, 0.9)}
      backdropFilter="blur(4px);"
      onClick={noop}
      {...boxProps}
    >
      <StyledList
        as="ul"
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(0, 1fr))"
        p={0}
        m={0}
      >
        {links.map((item) => {
          const isAction = "onClick" in item;

          return (
            <li
              key={isAction ? item.key : item.to}
              onTouchEnd={() => {
                isAction ? item.onClick() : router.push(item.to);
              }}
            >
              <StyledTab
                as={isAction ? "button" : RouterLink}
                href={isAction ? undefined : item.to}
                display="flex"
                flexDirection="column"
                alignItems="center"
                overflow="hidden"
                p={`0.75rem 0.5rem max(env(safe-area-inset-bottom, 0), 0.75rem) 0.5rem`}
                mt={0}
                onClick={isAction ? item.onClick : undefined}
              >
                <StyledListItem
                  as="span"
                  width="4rem"
                  height="2rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="1.25rem"
                  opacity={item.active ? 1 : 0.6}
                >
                  <item.Icon size="1.5rem" />
                </StyledListItem>
                <Box
                  as="span"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  textAlign="center"
                  fontSize="0.75rem"
                  mt="0.125rem"
                >
                  {item.text}
                </Box>
              </StyledTab>
            </li>
          );
        })}
      </StyledList>
    </Box>
  );
};
