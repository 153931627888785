import { css } from "styled-components";

export const modalStyles = css`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 250ms ease-out;

    > .ReactModal__Content {
      transform: translate(0, -1rem);
      transition: transform 250ms ease-out;
    }
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;

    > .ReactModal__Content {
      transform: translate(0, 0);
    }
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;
