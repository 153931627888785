import React, { PropsWithChildren } from "react";
import type { IconType } from "react-icons";
import { IoCheckmark, IoClose } from "react-icons/io5";
import { ThemeColor } from "../../../theme/theme";
import { Box, BoxProps } from "../box/Box";
import { Spinner } from "../spinner/Spinner";

export enum BadgeVariant {
  ERROR = "error",
  SUCCESS = "success",
  PENDING = "pending",
  WARNING = "warning",
}

export interface BadgeProps extends Omit<BoxProps, "color"> {
  variant: BadgeVariant;
  icon?: IconType;
}

const colorMap: Record<BadgeVariant, ThemeColor> = {
  [BadgeVariant.ERROR]: "danger.danger40",
  [BadgeVariant.SUCCESS]: "success.success30",
  [BadgeVariant.PENDING]: "warning.warning30",
  [BadgeVariant.WARNING]: "warning.warning40",
};

const IconWrapper: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Box mr={2} as="span" display="flex">
      {children}
    </Box>
  );
};

export const Badge: React.FunctionComponent<PropsWithChildren<BadgeProps>> = ({
  variant,
  children,
  icon: Icon,
  ...boxProps
}) => {
  return (
    <Box
      as="span"
      display="inline-flex"
      p="0.5rem 0.75rem 0.5rem 0.75rem"
      alignItems="center"
      borderRadius="0.5rem"
      bg={colorMap[variant]}
      {...boxProps}
    >
      <Box as="span" flex="0 0" display="flex" alignItems="center">
        {!Icon && variant === BadgeVariant.PENDING && (
          <IconWrapper>
            <Spinner size="0.75em" />
          </IconWrapper>
        )}
        {!Icon && variant === BadgeVariant.SUCCESS && (
          <IconWrapper>
            <IoCheckmark size="1em" />
          </IconWrapper>
        )}
        {!Icon && variant === BadgeVariant.ERROR && (
          <IconWrapper>
            <IoClose size="1rem" />
          </IconWrapper>
        )}
        {Icon && (
          <IconWrapper>
            <Icon size="1rem" />
          </IconWrapper>
        )}
      </Box>
      <Box fontSize={0}>{children}</Box>
    </Box>
  );
};
