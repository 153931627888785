import React, { PropsWithChildren, useEffect, useRef } from "react";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { Box } from "../../base/box/Box";
import { Button, ButtonSize, ButtonVariant } from "../../base/button/Button";
import { Overlay } from "../../base/overlay/Overlay";

export interface NotificationOverlayProps {
  open: boolean;
  onDismiss: () => void;
  backgroundColor?: Color | ThemeColor;
  content?: React.ReactNode;
}

const variants = {
  hidden: { opacity: 1, y: "100%" },
  enter: { opacity: 1, y: 0 },
};

const animationDuration = 0.5; // seconds

export const NotificationOverlay: React.FunctionComponent<
  PropsWithChildren<NotificationOverlayProps>
> = ({ open, onDismiss, backgroundColor, content, children }) => {
  const registeredRef = useRef(false);
  useEffect(() => {
    if (open) {
      registeredRef.current = false;

      // the global click listener is registered only once the animation is almost complete.
      // onAnimationComplete handler would be nicer here but I couldn't make it work immediately so
      // I'm using a timeout instead.
      setTimeout(() => {
        if (!registeredRef.current) {
          registeredRef.current = true;
          document.addEventListener("click", onDismiss);
        }
      }, animationDuration * 1000 * 0.8);

      return () => {
        if (registeredRef.current) {
          registeredRef.current = false;
          document.removeEventListener("click", onDismiss);
        }
      };
    }
  }, [open, onDismiss]);

  return (
    <Overlay
      isVisible={open}
      variants={variants}
      initial="hidden"
      animate="enter"
      exit="hidden"
      transition={{
        type: "tween",
        duration: animationDuration,
        ease: "easeInOut",
      }}
      bg={backgroundColor}
      display="flex"
      flexDirection="column"
      boxShadow="card"
    >
      <Box flex="1" display="flex">
        {content}
        {children}
      </Box>
      <Box flex="0 0" display="flex" justifyContent="center" pb={1}>
        <Button variant={ButtonVariant.TEXT} size={ButtonSize.LARGE}>
          Tap anywhere to continue
        </Button>
      </Box>
    </Overlay>
  );
};
