import { themeGet } from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { Image } from "../../base/image/Image";
import { RouterLink } from "../../base/router/RouterLink";

interface SubMenuBaseItemProps {
  text: string;
  icon: string;
  key?: number | string;
}

interface SubMenuLinkItemProps extends SubMenuBaseItemProps {
  href: string;
}

interface SubMenuButtonItemProps extends SubMenuBaseItemProps {
  onClick(): void;
}

export type SubMenuItemProps = SubMenuLinkItemProps | SubMenuButtonItemProps;

const SubMenuItem = styled(Box)`
  outline: none;
  cursor: pointer;
  border: none;
  background: none;
  min-width: 4.375rem;

  > img {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  :hover,
  :focus,
  :active {
    box-shadow: 0 0 0 1px ${themeGet("colors.blacks.black200")};
    text-decoration: none;

    > img {
      filter: brightness(75%);
    }

    > span {
      color: ${themeGet("colors.blacks.black800")};
    }
  }
`;

export interface SubMenuProps extends Omit<BoxProps, "color"> {
  items: SubMenuItemProps[];
}

export const SubMenu: React.FunctionComponent<SubMenuProps> = ({
  items,
  ...boxProps
}) => {
  return (
    <Box
      py="1.25rem"
      px={1}
      display="flex"
      justifyContent="space-around"
      overflowX="auto"
      gridGap={2}
      {...boxProps}
    >
      {items.map((item) => {
        return (
          <SubMenuItem
            key={item.key ?? item.text}
            as={"onClick" in item ? "button" : RouterLink}
            onClick={"onClick" in item ? item.onClick : undefined}
            href={"onClick" in item ? undefined : item.href}
            px={2}
            py="0.75rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
            fontSize={0}
            borderRadius="0.25rem"
          >
            <Image
              src={item.icon}
              alt=""
              width="1.25rem"
              height="1.25rem"
              objectFit="contain"
            />
            <Box as="span" mt={1} color="blacks.black600">
              {item.text}
            </Box>
          </SubMenuItem>
        );
      })}
    </Box>
  );
};
