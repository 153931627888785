import themeGet from "@styled-system/theme-get";
import React from "react";
import styled, { keyframes } from "styled-components";
import { Box } from "../box/Box";
import { Logo } from "../logo/Logo";
import { Image } from "../image/Image";
import { motion } from "framer-motion";
import QRCode from "react-qr-code";

export interface WalletPassProps {
  walletName: string;
  walletAddress: string;
  version: string;
  identifier: string;
  qrCodeData: string;
  animated?: boolean;
}

const StyledLogo = styled(Logo)`
  path {
    fill: ${themeGet("colors.black")};
  }
`;

export const WalletPass = React.forwardRef<HTMLDivElement, WalletPassProps>(
  (
    { qrCodeData, walletName, walletAddress, version, identifier, animated },
    ref
  ) => {
    return (
      <Box
        bg="white"
        as={motion.div}
        width="18.75rem"
        height="30rem"
        borderRadius="0.5rem"
        p="1.5rem"
        display="flex"
        flexDirection="column"
        boxShadow="card"
        ref={ref}
        animate={
          animated
            ? {
                rotate: [15, 15, 15],
                y: [0, 20, 0],
                boxShadow: [
                  "0 25px 15px 0px rgba(0, 0, 0, 0.2)",
                  "0 5px 15px 0px rgba(0, 0, 0, 0.6)",
                  "0 25px 15px 0px rgba(0, 0, 0, 0.2)",
                ],
                scale: [0.75, 0.75, 0.75],
              }
            : undefined
        }
        transition={{ duration: 5, repeat: Infinity }}
      >
        <Box mb={2}>
          <StyledLogo width="6.8125rem" />
        </Box>
        <Box>Wallet key pass</Box>
        <Box
          flex="1"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <QRCode size={230} value={qrCodeData} />
        </Box>
        <Box mb={2} lineHeight="1.5" wordBreak="break-word">
          <strong>{walletName}</strong>
        </Box>
        <Box fontSize={1} wordBreak="break-word" mb={2} lineHeight="1.5">
          {walletAddress}
        </Box>
        <Box fontSize={1} color="blacks.black600" lineHeight="1.5">
          Version {version} / {identifier}
        </Box>
      </Box>
    );
  }
);
