import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ThemeColor } from "../../../theme/theme";
import { Box, BoxProps } from "../box/Box";

export enum StatNumberSize {
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

export interface StatNumberProps extends Omit<BoxProps, "color"> {
  title?: string;
  value: string | number;
  suffix?: string;
  size?: StatNumberSize;
  changeHighlightColor?: ThemeColor;
}

const fontSizeMap: Record<StatNumberSize, string> = {
  [StatNumberSize.SMALL]: "2rem",
  [StatNumberSize.MEDIUM]: "4rem",
  [StatNumberSize.LARGE]: "6rem",
};

const StyledBox = styled(Box)`
  transition: color 0.3s ease-in-out;
`;

const DEFAULT_COLOR: ThemeColor = "black";

export const StatNumber: React.FunctionComponent<
  PropsWithChildren<StatNumberProps>
> = ({
  title,
  value,
  suffix,
  size = StatNumberSize.LARGE,
  changeHighlightColor = "black",
  ...boxProps
}) => {
  const [color, setColor] = useState<ThemeColor>(DEFAULT_COLOR);
  const initialRender = useRef(true);
  const changeHighlightColorRef = useRef(changeHighlightColor);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    setColor(changeHighlightColorRef.current);

    const timeout = setTimeout(() => {
      setColor(DEFAULT_COLOR);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Box {...boxProps}>
      {title && <Box fontSize={1}>{title}</Box>}
      <Box display="flex" verticalAlign="textTop" mt={3}>
        <StyledBox
          fontFamily="heading"
          fontSize={fontSizeMap[size]}
          lineHeight="0.75"
          pb={3}
          color={color}
        >
          {value}
        </StyledBox>
        {suffix && (
          <Box as="span" ml={2} color="blacks.black600" fontSize={1}>
            {" "}
            {suffix}
          </Box>
        )}
      </Box>
    </Box>
  );
};
