import React, { useState } from "react";
import { noop } from "../../../utils/noop";
import {
  formatCurrency,
  formatNumber,
  roundNumber,
} from "../../../utils/number-utils";
import { BoxProps } from "../../base/box/Box";
import { CurrencyCalculator } from "../currency-calculator/CurrencyCalculator";

export interface TokenInputToken {
  text: string;
  imageUrl: string;
  color: string;
  symbol: string;
  decimals: number;
  onClick?: () => void;
}

export interface TokenInputProps extends Omit<BoxProps, "color"> {
  token: TokenInputToken;
  totalTokenAmountAvailable?: string;

  hideDecimalPoint?: boolean;
  hidePercentageButtons?: boolean;

  onAmount(amount: { amount: string; symbol: string }): void;
  onSendPossible?(isSendPossible: boolean): void;

  tokenValue?: {
    baseCurrencyExchangeRate: number;
    baseCurrencySymbol: string;
  };
}

function isSendPossible({
  amount,
  totalSourceTokenAvailable,
}: {
  symbol: string;
  amount: string;
  token: TokenInputToken;
  totalSourceTokenAvailable?: string;
}) {
  if (Number(amount) <= 0) {
    return false;
  }

  if (!totalSourceTokenAvailable) {
    return true;
  }

  return Number(amount) <= Number(totalSourceTokenAvailable);
}

export const TokenInput: React.FunctionComponent<TokenInputProps> = ({
  onAmount,
  onSendPossible,
  token,
  totalTokenAmountAvailable,
  tokenValue,
  hidePercentageButtons = false,
  hideDecimalPoint = false,
  ...boxProps
}) => {
  const [amount, setInternalAmount] = useState("");

  const setAmount = (val: string, emit = true) => {
    const amount = hideDecimalPoint ? Math.trunc(Number(val)).toString() : val;
    setInternalAmount(amount);
    const symbol = token.symbol;

    onSendPossible?.(
      isSendPossible({
        symbol,
        amount,
        token,
        totalSourceTokenAvailable: totalTokenAmountAvailable,
      })
    );

    if (emit) {
      onAmount({
        amount,
        symbol,
      });
    }
  };

  const onSetPercentage = (multiplicator: number) => () => {
    if (!totalTokenAmountAvailable) {
      return;
    }

    const amountInSourceToken =
      Number(totalTokenAmountAvailable) * multiplicator;

    const amount = amountInSourceToken;
    const decimals = token.decimals;

    setAmount(roundNumber(amount, decimals, false, true), false);

    onAmount({
      symbol: token.symbol,
      amount:
        multiplicator === 1
          ? totalTokenAmountAvailable
          : String(amountInSourceToken),
    });
  };

  const amountAvailable = `${formatNumber(
    Number(totalTokenAmountAvailable),
    token.decimals
  )} ${token.symbol}`;

  const calculatedValue = tokenValue
    ? `${tokenValue.baseCurrencySymbol} ${formatCurrency(
        Number(amount) * tokenValue.baseCurrencyExchangeRate
      )}`
    : undefined;

  return (
    <CurrencyCalculator
      {...boxProps}
      onSetPercentage={onSetPercentage}
      onAmount={setAmount}
      amount={amount}
      selectedCurrency={token.symbol}
      onSetCurrency={noop}
      amountAvailable={amountAvailable}
      from={token}
      hideDecimalPoint={hideDecimalPoint || token.decimals === 0}
      hidePercentageButtons={
        hidePercentageButtons || !totalTokenAmountAvailable
      }
      hideAvailable={!totalTokenAmountAvailable}
      calculatedValue={calculatedValue}
    />
  );
};
