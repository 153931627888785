import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../../base/box/Box";
import { Image } from "../../base/image/Image";

export enum TokenListVariant {
  DEFAULT = "DEFAULT",
  SMALL = "SMALL",
}

export interface TokenListProps extends Omit<BoxProps, "color"> {
  tokens: {
    name: string;
    image: string;
    addendum?: string;
  }[];
  variant?: TokenListVariant;
}

const StyledParent = styled(Box)`
  list-style: none;
`;

export const TokenList: React.FunctionComponent<TokenListProps> = ({
  tokens,
  variant = TokenListVariant.DEFAULT,
  ...boxProps
}) => {
  const isDefaultSize = variant === TokenListVariant.DEFAULT;
  const fontSize = isDefaultSize ? 2 : 1;
  const imageSize = isDefaultSize ? "2rem" : "1.5rem";
  const color = isDefaultSize ? "blacks.black600" : "black";

  return (
    <StyledParent
      as="ul"
      display="grid"
      gridGap={3}
      gridTemplateColumns="1fr 1fr"
      pl={0}
      {...boxProps}
    >
      {tokens.map(({ name, image, addendum }) => (
        <Box
          as="li"
          display="flex"
          alignItems="center"
          color={color}
          key={name}
          fontSize={fontSize}
        >
          <Image
            src={image}
            height={imageSize}
            width={imageSize}
            alt=""
            mr={2}
          />
          {name}{" "}
          {addendum && (
            <Box
              as="span"
              ml="auto"
              pl={2}
              color={color}
              whiteSpace="nowrap"
              fontSize={fontSize}
            >
              {addendum}
            </Box>
          )}
        </Box>
      ))}
    </StyledParent>
  );
};
