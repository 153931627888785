import { Disclosure } from "@headlessui/react";
import themeGet from "@styled-system/theme-get";
import React, { PropsWithChildren } from "react";
import { IoAddSharp, IoRemoveSharp } from "react-icons/io5";

import styled from "styled-components";

export interface AccordionProps {
  items: {
    key: number | string;
    title: string;
    content: React.ReactNode;
  }[];
}

const AccordionButton = styled(Disclosure.Button)`
  color: ${themeGet("colors.black")};
  cursor: pointer;
  display: block;
  font-size: 1.5rem;
  line-height: 1.25;
  background: transparent;
  border: none;
  padding: 1.5rem 2rem;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid ${themeGet("colors.blacks.black200")};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  transition: background 0.25s ease-in-out;

  &:last-of-type:not([aria-expanded="true"]) {
    border-bottom: 1px solid ${themeGet("colors.blacks.black200")};
  }

  @media (hover: hover) {
    &:hover {
      background: ${themeGet("colors.blacks.black100")};
    }
  }

  &[aria-expanded="true"] {
    background: ${themeGet("colors.success.success50")};
  }

  > svg {
    flex-shrink: 0;
  }
`;

const AccordionPanel = styled(Disclosure.Panel)`
  padding-left: 2rem;
  padding-right: 2rem;
`;

export const Accordion: React.FunctionComponent<
  PropsWithChildren<AccordionProps>
> = ({ items }) => {
  return (
    <>
      {items.map(({ key, content, title }) => (
        <Disclosure key={key}>
          {({ open }) => (
            <>
              <AccordionButton>
                {title} {open ? <IoRemoveSharp /> : <IoAddSharp />}
              </AccordionButton>
              <AccordionPanel>{content}</AccordionPanel>
            </>
          )}
        </Disclosure>
      ))}
    </>
  );
};
