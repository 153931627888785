import * as React from "react";
import { motion } from "framer-motion";
import { Link } from "../../../base/typography/Typography";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

interface MobileMenuItemProps {
  to: string;
  text: string;
  active?: boolean;
  toggle(): void;
}

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const StyledLink = styled(Link)`
  text-decoration-thickness: 0.125rem;
  &:hover {
    color: ${themeGet("colors.white")};
  }

  :marker {
    display: none;
  }
`;

const StyledListItem = styled(motion.li)`
  margin-top: 0.25rem;
  width: max-content;
  max-width: 100%;
  margin-bottom: 1rem;

  :hover > a {
    text-decoration: underline;
  }
`;

export const MobileMenuItem: React.FunctionComponent<MobileMenuItemProps> = ({
  to,
  text,
  active,
  toggle,
}) => {
  return (
    <StyledListItem
      variants={variants}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
    >
      <StyledLink
        href={to}
        fontSize={9}
        color={active ? "white" : "blacks.black200"}
        fontWeight="300"
        lineHeight={1.25}
        onClick={toggle}
      >
        {text}
      </StyledLink>
    </StyledListItem>
  );
};
