import { themeGet } from "@styled-system/theme-get";
import React from "react";
import type { IconType } from "react-icons";
import { IoChevronForward } from "react-icons/io5";
import styled from "styled-components";
import { hexToRgba } from "../../../utils/color-utils";
import { Box } from "../../base/box/Box";
import { Link } from "../../base/typography/Typography";

export interface NavigationListItemBaseProps {
  text: string;
  Icon?: IconType;
  active?: boolean;
  hideArrow?: boolean;
}

export interface NavigationListLinkProps extends NavigationListItemBaseProps {
  to: string;
}

export interface NavigationListButtonProps extends NavigationListItemBaseProps {
  onClick(): void;
}

export interface NavigationListProps {
  links: (NavigationListButtonProps | NavigationListLinkProps)[];
  onLinkClick?: (to: string) => void;
}

const StyledLink = styled(Link)`
  border-radius: 100vmax;
  background: none;
  border: none;
  text-align: start;

  :focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${themeGet("colors.white")};
  }

  :hover {
    text-decoration: none;
    background-color: ${(props) =>
      hexToRgba(themeGet("colors.white")(props), 0.08)};
  }

  :active,
  :focus {
    background-color: ${(props) =>
      hexToRgba(themeGet("colors.white")(props), 0.12)};
  }
`;

export const NavigationList: React.FunctionComponent<NavigationListProps> = ({
  links,
  onLinkClick,
}) => {
  return (
    <Box as="nav" bg="black">
      <Box as="ul" px={0} mt={0}>
        {links.map((item) => (
          <Box
            as="li"
            height="3.25rem"
            display="flex"
            alignItems="center"
            key={item.text}
          >
            <StyledLink
              as={"to" in item ? Link : "button"}
              px={3}
              height="100%"
              href={"to" in item ? item.to : undefined}
              onClick={"onClick" in item ? item.onClick : undefined}
              display="flex"
              alignItems="center"
              flex="1"
              overflow="hidden"
              onBeforeNavigation={
                "onClick" in item ? undefined : () => onLinkClick?.(item.to)
              }
            >
              {item.Icon && (
                <Box
                  as="span"
                  pr="1rem"
                  height="2rem"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  borderRadius="1.25rem"
                  color={item.active ? "white" : "blacks.black400"}
                >
                  <item.Icon size="1.5rem" />
                </Box>
              )}
              <Box
                as="span"
                overflow="hidden"
                textOverflow="ellipsis"
                fontSize={1}
                color={item.active ? "white" : "blacks.black400"}
                flex="1"
                fontWeight="600"
              >
                {item.text}
              </Box>
              <Box
                as="span"
                color={item.active ? "white" : "blacks.black400"}
                display="flex"
                alignItems="center"
              >
                {!item.hideArrow && <IoChevronForward />}
              </Box>
            </StyledLink>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
