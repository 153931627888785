import React, { PropsWithChildren } from 'react';
import * as prismic from '@prismicio/client';

import { PrismicProvider as UnconfiguredPrismicProvider } from '@prismicio/react';
import Link from 'next/link';

const prismicConfig = {
  apiEndpoint: 'https://nobank.prismic.io/api/v2',
  repositoryName: 'nobank',
  libraries: ['@/slices'],
};

const endpoint = prismic.getEndpoint(prismicConfig.repositoryName);

export const client = prismic.createClient(endpoint, {
  routes: [
    {
      type: 'blog_post',
      path: '/blog/:uid',
    },
  ],
});

function linkResolver(doc: { type: string }) {
  return '/';
}

export const PrismicProvider: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <UnconfiguredPrismicProvider
      client={client}
      linkResolver={linkResolver}
      internalLinkComponent={({ href, children, ...props }) => (
        <Link href={href} {...props}>
          {children}
        </Link>
      )}
    >
      {/* <PrismicPreview repositoryName={repositoryName}>{children}</PrismicPreview> */}
      {children}
    </UnconfiguredPrismicProvider>
  );
};
