import {
  background,
  border,
  buttonStyle,
  color,
  colorStyle,
  compose,
  flexbox,
  grid,
  layout,
  overflow,
  position,
  shadow,
  space,
  textStyle,
  typography,
} from "styled-system";
import {
  whiteSpace,
  textOverflow,
  pointerEvents,
  wordBreak,
  cursor,
  textDecoration,
  object,
  backdropFilter,
} from "../theme/styled-system-custom";

const all = compose(
  space,
  typography,
  color,
  layout,
  flexbox,
  border,
  background,
  position,
  grid,
  shadow,
  buttonStyle,
  textStyle,
  colorStyle,
  whiteSpace,
  overflow,
  textOverflow,
  pointerEvents,
  wordBreak,
  cursor,
  textDecoration,
  object,
  backdropFilter
);

const props = all.propNames;

/**
 * This function determines whether to forward props to the underlying component or not.
 */
export const shouldForwardProp: any = (key: string) =>
  !(props ?? []).includes(key);
