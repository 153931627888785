import styled from "styled-components";

export const SrOnly = styled.span`
  position: absolute;
  left: -20000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;
