import { motion, MotionProps } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { useThemeColor } from "../../../../hooks/use-theme-color";
import { zIndex } from "../../../../theme/globals";

interface MenuToggleProps {
  size: number;
  margin: number;
  toggle(): void;
}

const Path = (props: MotionProps & { d?: string }) => {
  const white = useThemeColor("white");

  return (
    <motion.path
      fill="transparent"
      strokeWidth="3"
      stroke={white}
      strokeLinecap="round"
      {...props}
    />
  );
};

const StyledButton = styled.button<{ $size: number; $margin: number }>`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: fixed;
  top: ${({ $margin }) => `${$margin}px`};
  right: ${({ $margin }) => `${$margin}px`};
  width: ${({ $size }) => `${$size}px`};
  height: ${({ $size }) => `${$size}px`};
  border-radius: 50%;
  background: transparent;
  z-index: ${zIndex.overlay + 5};

  svg {
    position: relative;
    top: 3px;
    left: 1px;
  }
`;

export const MenuToggle: React.FunctionComponent<MenuToggleProps> = ({
  toggle,
  size,
  margin,
}) => (
  <StyledButton
    as={motion.button}
    variants={{
      hidden: {
        y: "-100%",
        transition: {
          delay: 0.5,
        },
      },
      open: {
        y: 0,
        transition: {
          delay: 0.5,
        },
      },
      closed: {
        y: 0,
        transition: {
          delay: 0.5,
        },
      },
    }}
    onClick={toggle}
    $size={size}
    $margin={margin}
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </StyledButton>
);
