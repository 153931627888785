import themeGet from "@styled-system/theme-get";
import React, { useEffect, useMemo, useRef } from "react";
import AutosizeInput from "react-input-autosize";
import styled from "styled-components";
import { Box } from "../../base/box/Box";
import { useBreakpoint } from "../../../hooks/use-breakpoint";
import { isTouchDevice } from "../../../utils/touch-utils";

export interface CurrencyInputProps {
  currency: string;
  value: string;
  onChange: (value: string) => void;
  startScalingFrom?: number;
  stopScalingAt?: number;
  allowEditingOnTouch?: boolean;
}

const StyledAutosizeInput = styled(AutosizeInput)`
  > input {
    background-color: transparent;
    font-family: ${themeGet("fonts.heading")};
    border: none;
    padding: 0;
    min-width: 2.5rem;

    caret-color: ${themeGet("colors.blacks.black600")};

    :focus-visible {
      outline: none;
    }

    :placeholder {
      color: red;
    }

    /* hide arrows */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

const isTouch = isTouchDevice();

const defaultStartScalingFrom = 6;
const defaultStopScalingAt = 22;

export const CurrencyInput: React.FunctionComponent<CurrencyInputProps> = ({
  value,
  onChange,
  currency,
  startScalingFrom = defaultStartScalingFrom,
  stopScalingAt = defaultStopScalingAt,
  allowEditingOnTouch,
}) => {
  /*
    Unfortunately I could not find a sexy solution for creating an autosizing input that also scales the text
    size smoothly to fit on the screen if there is not enough space. The solution I went with here is 
    scaling the font size linearly based on the number of characters of the value, while at the same time
    having a min and max font size, based on the current viewport width. 
  */

  const ref = useRef<HTMLDivElement | null>(null);
  const maxFontSizeSmUp = 96;
  const maxFontSize = 56;
  const minFontSizeSmUp = 16;
  const minFontSize = 20;
  const isSmUp = useBreakpoint("sm");

  const currentMaxFontSize = isSmUp ? maxFontSizeSmUp : maxFontSize;
  const currentMinFontSize = isSmUp ? minFontSizeSmUp : minFontSize;
  const length = value.length;

  // x = lenght
  // y = font size
  // x0 = startScalingFrom
  // x1 = stopScalingAt
  // y0 = currentMaxFontSize
  // y1 = currentMinFontSize

  // https://en.wikipedia.org/wiki/Linear_interpolation
  // font size = (currentMaxFontSize * (stopScalingAt - length)) + (currentMinFontSize * (length - startScalingFrom)) / (stopScalingAt - startScalingFrom)

  // using memo to avoid re-rendering the input if possible
  const inputFontSize = useMemo(() => {
    const fontSize =
      (currentMaxFontSize * (stopScalingAt - length) +
        currentMinFontSize * (length - startScalingFrom)) /
      (stopScalingAt - startScalingFrom);

    const inputFontSize = Math.min(
      currentMaxFontSize,
      Math.max(fontSize, currentMinFontSize)
    );

    return inputFontSize;
  }, [
    currentMaxFontSize,
    currentMinFontSize,
    length,
    stopScalingAt,
    startScalingFrom,
  ]);

  useEffect(() => {
    ref.current?.querySelector("input")?.focus();
  }, [ref, inputFontSize]);

  return (
    <Box display="flex" alignItems="baseline" overflow="hidden" ref={ref}>
      <Box
        as="label"
        htmlFor="currency-input"
        mr={1}
        color={value ? "black" : "blacks.black400"}
      >
        {currency}
      </Box>
      <StyledAutosizeInput
        key={inputFontSize}
        placeholder="0"
        name="currency-input"
        id="currency-input"
        value={value}
        onChange={(e) => {
          if (/^\d*\.?\d*$/.test(e.target.value)) {
            onChange(e.target.value);
          }
        }}
        inputMode="decimal"
        readOnly={isTouch && !allowEditingOnTouch}
        inputStyle={{ fontSize: inputFontSize }}
        minWidth={isSmUp ? 60 : undefined}
      />
    </Box>
  );
};
