import { motion } from "framer-motion";
import React, { Children, PropsWithChildren, ReactElement } from "react";
import { Box, BoxProps } from "../box/Box";
import styled from "styled-components";
import themeGet from "@styled-system/theme-get";

export interface ListItemProps extends Omit<BoxProps, "color"> {
  as?: React.ElementType | string;
}

export const Item: React.FunctionComponent<
  PropsWithChildren<ListItemProps>
> = ({ children, ...boxProps }) => {
  return (
    <Box borderBottom="1px solid" borderColor="blacks.black200" {...boxProps}>
      <Box py={3} as={motion.div} whileHover={{ x: 10 }} overflow="hidden">
        {children}
      </Box>
    </Box>
  );
};

export interface ListRootProps extends Omit<BoxProps, "color"> {
  withTopBorder?: boolean;
  children:
    | ReactElement<ListItemProps>
    | Array<ReactElement<ListItemProps> | false>;
}

const StyledRoot = styled(Box)<{ $withTopBorder?: boolean }>`
  > :first-child {
    border-top: ${({ $withTopBorder }) => ($withTopBorder ? "1px" : "0px")}
      solid ${themeGet("colors.blacks.black200")};
  }
`;

export const Root: React.FunctionComponent<ListRootProps> = ({
  children,
  withTopBorder,
  ...boxProps
}) => {
  Children.forEach(children, (child) => {
    if (!child) {
      return;
    }
    if (child.type !== Item) {
      console.warn("List component should only have List.Item children");
    }
  });

  return (
    <StyledRoot $withTopBorder={withTopBorder} {...boxProps}>
      {children}
    </StyledRoot>
  );
};
