import { motion } from "framer-motion";
import React, { PropsWithChildren } from "react";
import { ColorProps, SpaceProps } from "styled-system";
import { useThemeColors } from "../../../hooks/use-theme-color";
import { ThemeColor } from "../../../theme/theme";
import { Color } from "../../../utils/types";
import { Section } from "../section/Section";

export interface AnimatedSectionProps extends ColorProps, SpaceProps {
  colors: (ThemeColor | Color)[];
  transitionTimeSeconds?: number;
  waitTimeSeconds?: number;
}

const defaultTransitionTimeSeconds = 1;
const defaultWaitTimeSeconds = 5;

export const AnimatedSection: React.FunctionComponent<
  PropsWithChildren<Omit<AnimatedSectionProps, "color">>
> = ({
  children,
  colors,
  transitionTimeSeconds = defaultTransitionTimeSeconds,
  waitTimeSeconds = defaultWaitTimeSeconds,
  ...props
}) => {
  const themeColors = useThemeColors(colors);

  const background = themeColors.flatMap((color, i) => {
    const nextColor = themeColors[(i + 1) % themeColors.length];

    return [
      `linear-gradient(90deg, ${color} 0%, ${color} 100%, ${nextColor} 100%, ${nextColor} 100%)`,
      `linear-gradient(90deg, ${color} 0%, ${color} 0%, ${nextColor} 0%, ${nextColor} 100%)`,
      `linear-gradient(90deg, ${color} 0%, ${color} 0%, ${nextColor} 0%, ${nextColor} 100%)`,
    ];
  });

  const times = themeColors.flatMap((_, i) => {
    const innerFraction = 1 / (transitionTimeSeconds + waitTimeSeconds);
    const transitionFraction = transitionTimeSeconds * innerFraction;
    const fraction = 1 / themeColors.length;
    const start = fraction * i;
    const end = fraction * (i + 1);
    return [start, start + (end - start) * transitionFraction, end];
  });

  return (
    <Section
      as={motion.section}
      animate={{
        background,
        transition: {
          repeat: Infinity,
          duration:
            themeColors.length * waitTimeSeconds * transitionTimeSeconds,
          times,
          delay: waitTimeSeconds / 2,
        },
      }}
      {...props}
    >
      {children}
    </Section>
  );
};
